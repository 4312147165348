<template>
   <v-card>
    <v-card-title>
      <div>
        <v-avatar size="80">
          <img :src="user.image" alt="User Avatar" style="width: 120px;">
        </v-avatar>
        <h3>{{ user.name }}</h3>
      </div>
    </v-card-title>
    <v-card-text style="text-align: center;">
  
        <v-row>
          <v-col cols="12" sm="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email:</v-list-item-title>
                <v-list-item-subtitle>{{ user.email_id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Phone Number:</v-list-item-title>
                <v-list-item-subtitle>{{ user.phone }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="button-area">
          <v-col cols="12" sm="6">
            <button class="btn2" :style="dynmicBgColor" @click="toggleEditForm"> 
              <v-icon>mdi-pencil</v-icon> Edit Personal Information
            </button>
          </v-col>
          <v-col cols="12" sm="6">
            <button class="btn1" @click="toggleChangePassword">
              <v-icon>mdi-lock-reset</v-icon> Change Password
            </button>
          </v-col>
        </v-row>
        <v-expand-transition >
          <div v-if="showEditForm" style="margin-top: 20px; padding:30px">
            <user-edit-info :bgColor="dynmicBgColor"  :vendorStore="useVendorStore" @onload-image="handleLoadImage" @after-success-update="handleUpdateInfo"/>
          </div>
        </v-expand-transition>
        <v-expand-transition >
          <div v-if="showChangePasswordForm" style="margin-top: 20px; padding:30px" >
            <change-password :bgColor="dynmicBgColor" :user="user" :vendorStore="useVendorStore"/>
          </div>
        </v-expand-transition>
    </v-card-text>
  </v-card>
  </template>
  
  <script>
  import eidtInfoComponent from './userInfoEdit.vue';
  import changePassword from './changePassword.vue'
  import { vendorStore } from '@/store/vendor.js' 
  import { useUserStore } from '@/store/user.js' 
  export default {
    setup() {
        const userStore = useUserStore();
        const useVendorStore = vendorStore();  
        return {userStore,useVendorStore};
    },
    components:{
        'user-edit-info':eidtInfoComponent,
        'change-password':changePassword
    },
    data() {
      return {
        showEditForm: false,
        showChangePasswordForm:false,
        user:{},
      };
    },
    mounted(){
        this.afterReload();
      },
    computed:{
        dynmicBgColor() {
        return {
            color: this.useVendorStore.resturant.font_color,
            backgroundColor: this.useVendorStore.resturant.main_color,
        };
      }
    },
    methods:{
      handleUpdateInfo(data){
          let obj = this.userStore.user;
          obj.name = data.name;
          obj.email_id = data.email_id;
          obj.phone = data.phone;
          obj.image = data.image;
          console.log(obj);

          this.userStore.loginUser(obj,this.userStore.payment_setting);
          this.afterReload();
      },
      handleLoadImage(imgpath){
        this.user.image =imgpath;

      },
        afterReload(){
          this.user = this.userStore.user;
          
          
        },
        toggleEditForm() {
            this.showChangePasswordForm=false
          this.showEditForm = !this.showEditForm;
       },
       toggleChangePassword(){
        this.showEditForm =false;
        this.showChangePasswordForm= !this.showChangePasswordForm;
       },
    }
  };
  </script>
  <style scoped>
.v-card-title{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.button-area .btn2{
    width: 100%;
    padding: 10px;
}
.button-area .btn1{
    width: 100%;
    background-color: #d7171c;
    color: #fff;
    padding: 10px;
}
</style>