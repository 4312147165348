<template>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div @click="drawer=false" style="margin-top: 55px;">
        <v-container>
         <v-row>
            <v-col cols="12" sm="4">
                <v-card>
                    <v-card-text >
                        <v-list style="text-align: center;">
                            <v-card class="list-item-content" v-if="useVendorStore.pages_statues.feedback_status">
                                <v-list-item class="list-item-content">
                                   <router-link to="/feedback" class="link-item">
                                       <div style="display: flex;align-items: center;justify-content:space-between">
                                           <div class="list-content"> 
                                               <v-list-item-icon class="start-icon" style="background-color: #D11347;">
                                                   <v-icon>mdi-comment-outline</v-icon>
                                               </v-list-item-icon>
                                       
                                               <v-list-item-content> 
                                                   <v-list-item-title >Feedback</v-list-item-title>
                                               </v-list-item-content>
                                            </div>
                                               <v-icon style="font-size: 30PX; color: #333;" right>mdi-chevron-right</v-icon>
                                         </div>
                                   </router-link> 
                                   </v-list-item>
                            </v-card>
                            <v-card class="list-item-content" v-if="useVendorStore.pages_statues.privacy_page_status">
                                <v-list-item >
                                   <router-link to="/privacy" class="link-item">
                                       <div style="display: flex;align-items: center;justify-content:space-between">
                                           <div class="list-content"> 
                                               <v-list-item-icon class="start-icon" style="background-color: #FEB60D;">
                                                <v-icon>mdi-shield-lock</v-icon>
                                               </v-list-item-icon>
                                       
                                               <v-list-item-content> 
                                                   <v-list-item-title >Privacy</v-list-item-title>
                                               </v-list-item-content>
                                            </div>
                                               <v-icon style="font-size: 30PX; color: #333;" right>mdi-chevron-right</v-icon>
                                         </div>
                                   </router-link> 
                                </v-list-item>
                            </v-card>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <user-profile-card :user="user"/>
            </v-col>
         </v-row>
         <div class="spceing-box"></div>
        </v-container>
        <web-footer/>
        <div class="sm-footer">
             <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
         </div>
    </div>
</template>


<script>
import NavBar from '@/layouts/default/NavBar.vue'
import userProfileCard from '@/components/dashboard/userProfileCard.vue'
import { vendorStore } from '@/store/vendor.js' 
import { useUserStore } from '@/store/user.js' 
import smFooterComponent from '@/components/smFooterComponent.vue'
import webFooterComponent from '@/components/webFooterComponent.vue'
  export default{ 
    created() {
        if (!User.loggedIn()) {
            this.$router.push('/');
        }
    },
    setup() {
        const userStore = useUserStore();
        const useVendorStore = vendorStore();
        return {userStore,useVendorStore};
    }, 
    components:{
        'nav-bar-vue':NavBar,
        'user-profile-card':userProfileCard,
        'small-screen-footer':smFooterComponent,
        'web-footer':webFooterComponent,
    },
    
      data(){
        return{
            drawer:false,
            user:{},
        }
      },
      mounted(){
        this.afterReload();
       
      },
      methods:{
          afterReload(){
          this.user = this.userStore.user;
          VendorHelper.updatePageTitle(this.useVendorStore.resturant.name +'- Ozpos');  
        }
      }
  }
</script>


<style scoped>
.list-item-content{
   /* margin: 10px 0; */
   padding: 30px 0;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.list-content{
   display: flex;
   align-items: center;
}
.list-content .start-icon{
    
    /* padding: 10px; */
    color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 20px;
}
.list-item-content .link-item{
   text-decoration: none;
}
.list-item-content .link-item .v-list-item-title{
    font-size: 25px;
    font-weight:500;
    color: #333;
}
.v-container{
    min-height: 600px;
  }

</style>