<template>
  <v-card>
    <div class="card-header" :style="themColorWithFont">
      <div style="margin-top: 7px">
        <img class="resturent-img" :src="vendorData.image" alt="" srcset="" />
      </div>
      <div class="resturant-info">
        <h3 class="h3-heading">{{ vendorData.name }}</h3>
        <div class="location">
          <v-icon style="color: #fff;font-size:16px">mdi-map-marker</v-icon
          >{{ truncatedString(vendorData.address, 40) }}
        </div>
        <p>Estimate Time :{{ vendorData.avg_delivery_time }} minutes</p>
      </div>
      <v-tooltip activator="parent" location="top">
        <div class="resturant-info">
          <h3 class="h3-heading">{{ vendorData.name }}</h3>
          <div class="location">
            <v-icon style="color: #fff;font-size:16px">mdi-map-marker</v-icon
            >{{ vendorData.address }}
          </div>
        </div>
      </v-tooltip>
    </div>
    <!-- <v-divider></v-divider> -->
    <div class="card-body">
      <div >
        <div class="top-btton-list">
          <!-- :style="activeTopButton === 'delivery' || hoverButton === 'delivery' && vendorData.delivery_status === 1 ? themColorWithFont : {}" -->
          <button
            class="btn1"
            :disabled="vendorData.delivery_status === 0"
            :style="getTopButtonStyle('delivery',vendorData.delivery_status)"
            @mouseover="onHoverMouse('delivery')"
            @mouseleave="onMouseLeave()"
            :class="{
              'disable-btn': vendorData.delivery_status === 0,
            }"
            @click="TopbuttonChangFunc('delivery')"
          >
            Delivery
          </button>
          <button
            :disabled="vendorData.pickup_status === 0"
            :style="getTopButtonStyle('pickup',vendorData.pickup_status)"
            class="btn2"
            @mouseover="onHoverMouse('pickup')"
            @mouseleave="onMouseLeave()"
            :class="{
              'disable-btn': vendorData.pickup_status === 0,
            }"
            @click="TopbuttonChangFunc('pickup')"
          >
            Pickup
          </button>
        </div>
        <div class="top-btton-list bottom-list" v-if="activeTopButton">
          <button
            class="btn1"
            :class="{
              'disable-btn': !isVendorOnOff  ,
            }"
            :style="{
              'background-color':isVendorOnOff && hoverButton === 'Now' || activeBottomButton === 'Now' ? themColor.color:'',
              'color':isVendorOnOff && hoverButton === 'Now' || activeBottomButton === 'Now' ? themColorWithFont.color: themColor.color,
              'border':'1px solid '+ themColor.color
            }"
            @mouseover="onHoverMouse('Now')"
            @mouseleave="onMouseLeave()"
            @click="BottombuttonChangFunc('Now')"
            :disabled="!isVendorOnOff"
          >
            Now
          </button>
          <button
            class="btn2"
             :style="{
              'background-color':hoverButton === 'Later' || activeBottomButton === 'Later' ? themColor.color:'',
              'color':hoverButton === 'Later' || activeBottomButton === 'Later' ? themColorWithFont.color: themColor.color,
              'border':'1px solid '+ themColor.color
            }"
            @mouseover="onHoverMouse('Later')"
            @mouseleave="onMouseLeave()"
            @click="BottombuttonChangFunc('Later')"
          >
            Later
          </button>
          <!-- <button  class="btn2" :class="{ 'btn-active': activeBottomButton === 'Later', 'hoverButtons':true}" @click="BottombuttonChangFunc('Later')" :disabled="vendorData.vendor_status ===0 && vendorData.delivery_status ===0 && vendorData.pickup_status ===0">Later</button> -->
        </div>
        <div class="pickup-addres-card" v-if="activeTopButton === 'pickup'">
          <div style="display: flex; padding-bottom: 20px; cursor: pointer">
            <h3 class="h3-heading">Pickup Address</h3>
            <v-icon :style="themColor">mdi-map-marker</v-icon>
            <v-tooltip activator="parent" location="center">
              <h3 class="h3-heading">{{ vendorData.name }}</h3>
              <div class="location">
                <v-icon style="font-size:16px">mdi-map-marker</v-icon>{{ vendorData.address }}
              </div>
            </v-tooltip>
          </div>
          <div></div>
        </div>
        <div v-if="activeBottomButton === 'Later'">
           <!-- v-if="activeTopButton === 'delivery' && -->
          <!-- vendorData.is_tiffin_service === 1"  -->
          <date-picker 
          :workingDays="workingDays" 
          :currentDay="currentDay" 
          :themColor="themColor"
          :type="activeTopButton"
          @selected-time-date="handleDeliverySelectedDateTime"
          @change-date="handleChangeDatePicker"
          @time-picker-show-hide="handleTimePickerModalOpenClose"
          :vendorData="vendorData"
          :isVendorOnOff="isVendorOnOff"
          />
         <!-- "2024-06-30T06:45" -->
         <!-- "2024-06-27T23:53" -->
          <!-- <v-text-field
            v-else
            v-model="dateTimeValue"
            label="Date and Time"
            type="datetime-local"
            variant="outlined" 
            class="custom-text-field"
            :style="themColor"
            :min="currentDateTime"
            :max="maxDateTime"
            @change="validateTime"
            id="datePicker"
          ></v-text-field> -->
        </div>
       <transition name="fade">
          <v-alert
            @click="invalidTimeAlert = false"
            v-if="invalidTimeAlert && this.vendorData.is_tiffin_service === 0 "
            density="compact"
            type="error"
            :text="invalidTimeErr"
            :value="alert"
            color="pink"
            style="font-size: 12px; margin: 15px 0; cursor: pointer"
          ></v-alert> 
        </transition> 
        <div
          class="top-btton-list mb-3"
          v-if="activeTopButton === 'delivery' && isLogin && activeBottomButton"
        >
          <button
            class="btn1"
             @mouseover="onHoverMouse('oldAddress')"
             @mouseleave="onMouseLeave()"
             :style="{
              'background-color':hoverButton === 'oldAddress' || addressBtnSelect === 'oldAddress' ? themColor.color:'',
              'color':hoverButton === 'oldAddress' || addressBtnSelect === 'oldAddress' ? themColorWithFont.color: themColor.color,
              'border':'1px solid '+ themColor.color
            }"
            @click="ShwoAddressBox('oldAddress')"
          >
            {{ truncatedString(oldAddressBtnText, 14)
            }}<v-icon
              v-if="oldAddressBtnText !== 'Save Address'"
              style="font-size: 16px; position: absolute; right: 7px; top: 10px"
              @click.stop="removeAddress"
              >mdi-close</v-icon
            >
          </button>
          <button
            @mouseover="onHoverMouse('newAddress')"
            @mouseleave="onMouseLeave()"
            class="btn2"
            :style="{
              'background-color':hoverButton === 'newAddress' || addressBtnSelect === 'newAddress' ? themColor.color:'',
              'color':hoverButton === 'newAddress' || addressBtnSelect === 'newAddress' ? themColorWithFont.color: themColor.color,
              'border':'1px solid '+ themColor.color
            }"
            @click="ShwoAddressBox('newAddress')"
          >
            New Address
          </button>
        </div>
        <div
          v-if="
            activeTopButton === 'delivery' &&
            showGoogleMap &&
            vendorData.country !== 'Pakistan'
          "
        >
          <div style="display: flex" class="location-check">
            <vue-google-autocomplete
              ref="autocomplete"
              id="autocomplete"
              :country="vendorLocationsInMap"
              class="locationSeach autocomplete"
              :style="{
                border: '1px solid ' + useVendorStore.resturant.main_color,
              }"
              v-model="textAddress"
              placeholder="Enter your address"
              v-on:placechanged="getAddressData"
              @input="onInputChangeAddress"
            ></vue-google-autocomplete>
            <v-btn
              :disabled="!textAddress || isDisabledCheckAddressBtn"
              :style="themColorWithFont"
              @click="checkAddress"
              :loading="checkLoader"
              >Check</v-btn
            >
          </div>
        </div>
        <transition name="fade">
          <v-alert
            @click="addressAlert = false"
            v-if="addressAlert"
            density="compact"
            type="error"
            text="Apologies, no delivery available.Self-pickup only."
            :value="alert"
            color="pink"
            class="custom-alert"
          ></v-alert>
        </transition>
        <div class="input-field-cus">
          <div class="scrollable-section" id="scroll" >
            <v-divider></v-divider>
            <div class="scrollable-content" v-if="posCartData.length > 0">
              <v-card
                class="item-card"
                v-for="(cartData, index) in posCartData"
                :key="index"
                :loading="CardisLoading[index]" 
                :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
              >
                <div class="item-card-top">
                  <div style="display: flex; width: 70%">
                    <div class="dote-icon" :style="themColor">
                      <v-icon style="font-size: 7px">mdi-circle</v-icon>
                    </div>
                    <div class="item-namePrice">
                      <h6>{{ cartData.selectdItems.name }}</h6>
                      <small v-if="cartData.selectdItems.price">
                        {{
                          formattedPrice(
                            calculateQtyPrice(
                            cartData.selectdItems.qty,
                            cartData.selectdItems.price
                          ))
                          
                        }}
                      </small>
                      <div v-for="(size,i) in cartData.selectdItems.sizes" :key="i">
                        <small
                          v-if="size.selected"
                          style="margin-right: 4px; font-weight: 500"
                        >
                          ({{ size.item_size.name }})
                        </small>
                        <small v-if="size.selected">
                          {{
                            formattedPrice(
                               calculateQtyPrice(
                                cartData.selectdItems.qty,
                                size.price
                             ))
                          }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="qty-section">
                    <v-btn
                      class="qty-btn"
                      :style="themColor"
                      icon
                      @click="decrement(index)"
                      :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <input
                      type="text"
                      readonly
                      class="count-box"
                      v-model="cartData.selectdItems.qty"
                      :style="{
                        border:
                          '1px solid ' + useVendorStore.resturant.main_color,
                      }"
                    />
                    <v-btn
                      class="qty-btn"
                      :style="themColor"
                      icon
                      @click="increment(index)"
                      :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <v-icon class="item-remove" @click="removeItem(index)"
                    >mdi-delete</v-icon
                  >
                </div>
                <div class="item-card-bttom">
                  <div
                    class="item-extra-things"
                    v-for="(addon, ind) in cartData.SelectedSizes.addons"
                    :key="ind"
                    :style="{
                      'border-left':
                        '2px solid ' + this.useVendorStore.resturant.main_color,
                    }"
                  >
                    <div class="line" :style="themColorWithFont"></div>
                    <div class="addon_list">
                      <div>
                        <small>{{ addon.addon.name }}</small>
                        <small style="margin-left: 4px; font-size: 10px">
                          ({{ calculateQtyPrice(addon.qty, addon.price) }})
                        </small>
                      </div>
                      <div class="qty-section ml-3" v-if="addon.price > 0">
                        <v-btn
                          class="qty-btn"
                          :style="themColor"
                          icon
                          @click="addonDecrement(index, ind, 'isSizeAddon')"
                          :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <input
                          style="height: 16px; width: 22px; font-size: 13px"
                          type="text"
                          readonly
                          class="count-box"
                          v-model="addon.qty"
                          :style="{
                            border:
                              '1px solid ' +
                              useVendorStore.resturant.main_color,
                          }"
                        />
                        <v-btn
                          class="qty-btn"
                          :style="themColor"
                          icon
                          @click="addonIncrement(index, ind, 'isSizeAddon')"
                          :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="item-card-bttom"
                  v-if="cartData.selectedItemsAdon.length > 0"
                >
                  <div
                    class="item-extra-things"
                    v-for="(addon, addonIndex) in cartData.selectedItemsAdon"
                    :key="addonIndex"
                    :style="{
                      'border-left':
                        '2px solid ' + this.useVendorStore.resturant.main_color,
                    }"
                  >
                    <div class="line" :style="themColorWithFont"></div>
                    <div class="addon_list">
                      <div>
                        <small>{{ addon.name }}</small>
                        <small style="margin-left: 4px; font-size: 10px">
                          ({{ calculateQtyPrice(addon.qty, addon.price) }})
                        </small>
                      </div>
                      <div class="qty-section ml-3" v-if="addon.price > 0">
                        <v-btn
                          class="qty-btn"
                          :style="themColor"
                          icon
                          @click="
                            addonDecrement(index, addonIndex, 'isGroupAddon')
                          "
                          :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <input
                          style="height: 16px; width: 22px; font-size: 13px"
                          type="text"
                          readonly
                          class="count-box"
                          v-model="addon.qty"
                          :style="{
                            border:
                              '1px solid ' +
                              useVendorStore.resturant.main_color,
                          }"
                        />
                        <v-btn
                          class="qty-btn"
                          :style="themColor"
                          icon
                          @click="
                            addonIncrement(index, addonIndex, 'isGroupAddon')
                          "
                          :class="{'bg-ddd':timePickerTrigger !== 'isClose'}"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
              <div v-else style="padding:30px">
                 <h4>Cart is Empty. Add an Item</h4>
             </div>
          </div>
        
        </div>
        <v-divider></v-divider>
        <div class="payment-area">
          <div
            class="paymentSide"
            v-if="this.deliveryCahrge > 0 || this.taxRate > 0"
          >
          <table>
            <tbody>
              <tr v-if="this.deliveryCahrge > 0">
                <th colspan="2" style="font-weight: 400">Delivery Charge :</th>
                <td colspan="2">{{ formattedPrice(this.deliveryCahrge) }}</td>
              </tr>
              <tr v-if="this.taxRate > 0">
                <th colspan="2" style="font-weight: 400">Item Tax :</th>
                <td colspan="2">{{ formattedPrice(this.taxRate) }}</td>
              </tr>
              <tr v-if="vendorData.online_flat_menu_discount !== null">
                <th colspan="2" style="font-weight: 400">Discounted Price :</th>
                <td colspan="2">{{  formattedPrice(calculationArr.disocountedPrice) }}</td>
              </tr>
              <tr>
                  <th colspan="2" style="font-weight: 400">Item Total :</th>
                  <td colspan="2">{{ formattedPrice(this.ItemtotaPrice) }}</td>
                </tr>
                <tr>
                  <th colspan="2">Total Amount :</th>
                  <td colspan="2">{{ formattedPrice(this.totalPay) }}</td>
                </tr>
            </tbody>
          </table>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="check-out-btn" v-if="checkOutButtonShow">
          <v-btn
            :disabled="!posDeliveryValidtion || posCartData.length < 1"
            @click="checkOutProduct"
            :style="themColorWithFont"
            >Check Out <v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </div>
        <v-alert
          v-if="
            totalPay < parseFloat(useVendorStore.resturant.min_order_amount)
          "
          variant="outlined"
          type="warning"
          prominent
          border="top"
          :color="alertBoxColor"
          style="font-size:14px;font-weight:500"
        >
          Minimum Order Should be
          {{ formattedPrice(useVendorStore.resturant.min_order_amount ) }}
        </v-alert>
      </div>
      <!-- <div v-else>
        <h3>No data in cart.</h3>
      </div> -->
    </div>
  </v-card>
  <v-dialog
    v-model="saveAddressBox"
    :fullscreen="saveAddress.length > 0"
    :width="saveAddress.length > 0 ? undefined : 500"
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="useVendorStore.resturant.main_color">
        <v-btn icon dark @click="closeAddressBox">
          <v-icon :color="useVendorStore.resturant.font_color"
            >mdi-close</v-icon
          >
        </v-btn>
        <!-- <v-toolbar-title>Dialog Title</v-toolbar-title> -->
        <v-spacer></v-spacer>
      </v-toolbar>
      <div>
        <show-save-address-component
          @select-old-address="handleSelectOldAddress"
          @after-address-success="hnadleAddressUpdate"
          :saveAddress="saveAddress"
          :oldAddress="oldAddress"
          :vendorLocationsInMap="vendorLocationsInMap"
          :invalidTimeErr="invalidTimeErr"
          :invalidTimeAlert="invalidTimeAlert"
        />
      </div>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showCityAreas" max-width="500px" persistent>
    <choose-city-areas
      :useVendorStore="useVendorStore"
      :themColorWithFont="themColorWithFont"
      @close="handleCloseChooseCityArea"
      :isLoading="checkLoader"
      @select-area="handleSelectArea"
      :isEditAddress="false"
    />
  </v-dialog>

    <!-- pickup delivery selector box -->
    <v-dialog v-model="pickupDeliveryModal" max-width="600" :height="dialogHeight" :persistent="false" >
      <pickup-delivery-selector 
      :vendorLocationsInMap="vendorLocationsInMap" 
      :mainColor="useVendorStore.resturant.main_color" 
      :themColorWithFont="themColorWithFont"
      @select-area="handleSelectArea"
      :checkLoader="checkLoader"
      :isValidAddress="isValidAddress"
      :addressAlert="addressAlert"
      @clicked-alert="addressAlert=false"
      @after-selection="TopbuttonChangFunc"
      :currentDay="currentDay"
      ref="pickup_delivery_ref"
      :vendorData="vendorData"
      />
    </v-dialog>
  <!-- end pickup delivery selector box -->

</template>

<script>
import { posStore } from "@/store/posStore";
import dateTimePicker from "@/components/DateTimePickerComponent.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { vendorStore } from "@/store/vendor.js";
import { parse, format, getHours, getMinutes } from "date-fns";
import showSaveAddress from "@/components/showOldAddress.vue";
import snackBar from "@/components/snackbarComponent.vue";
import ChooseAreasModal from "@/components/ChooseAreasModal.vue";
import pickupDeliverySelector from "@/components/PickupDeliverySelector.vue";
import DatePicker from "@/components/DateTimePickerComponent";
import { useUserStore } from "@/store/user.js";
export default {
  props: [
    "vendorData",
    "posCartData",
    "checkVendor",
    "openTime",
    "closeTime",
    "themColorWithFont",
    "themColor",
    "alertBoxColor",
    "pageLoader",
    "currentDay",
    "weeklyWorkingDays",
    "timePickerTrigger",
    "formattedPrice"
  ],
  components: {
    VueGoogleAutocomplete,
    address: "",
    "show-save-address-component": showSaveAddress,
    "snack-bar": snackBar,
    "choose-city-areas": ChooseAreasModal,
     "pickup-delivery-selector":pickupDeliverySelector,
     "date-picker":DatePicker
  },
  setup() {
    const useVendorStore = vendorStore();
    const usePosStore = posStore();
    const userStore = useUserStore();
    return { usePosStore, useVendorStore, userStore };
  },
  data() {
    return {
      activeTopButton: "",
      activeBottomButton: "",
      CardisLoading: [],
      incrementAudio: "audio/increment.mp3",
      removeAudio: "audio/remove.mp3",
      address: "",
      lang: "",
      lat: "",
      oldAddress: {},
      textAddress: "",
      checkLoader: false,
      addressAlert: false,
      deliveryChargeData: {},
      deliveryCahrge: 0,
      free_delivery_amount: "",
      dateTimeValue: "",
      checkOutDisabled: true,
      isValidAddress: false,
      invalidTimeErr: "",
      invalidTimeAlert: false,
      isvalidDateTime: false,
      isValidCurentTime: false,
      addressBtnSelect: "",
      saveAddressBox: false,
      saveAddress: [],
      oldAddressBtnText: "Save Address",
      showCityAreas: false,
      isPickupDeliveryBox:true,
      workingDays:[],
      maxDateTime: '',
      delivery_charges_list:[],
      isDisabledCheckAddressBtn:true,
      hoverButton:null,
      isValidCurrentTimeDuringCheckout:true
    };
  },
  created() {
    this.CardisLoading = Array(this.posCartData.length).fill(false); 
    if (User.loggedIn()) {
        // this.addressBtnSelect = "";
        this.loadSaveAddress();
      }
  },
  watch:{
    textAddress(newVal, oldVal) {
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('address') && newVal !== urlParams.get('address')){
        if (newVal !== oldVal) {
          this.isDisabledCheckAddressBtn = false;
        }else{
           this.isDisabledCheckAddressBtn = true;
        }
      }
    },
  
  },
  mounted() {
    if(Object.keys(this.usePosStore.cartData).length > 0){
        this.isPickupDeliveryBox = false;
    }
   
    this.activeTopButton = this.vendorData.country !== 'Pakistan' ? this.usePosStore.delivery_type : 'pickup';
    this.addressBtnSelect = this.vendorData.country !== 'Pakistan' ? 'newAddress' : '';
    // if(this.vendorData.is_tiffin_service === 1){
    //     this.activeTopButton = this.usePosStore.delivery_type;
    // }else{
    //     this.activeTopButton = 'pickup';
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const deliveryType = urlParams.get('delivery_type');
    const address = urlParams.get('address');
    // this.activeBottomButton = 'Now';
    // this.addressBtnSelect = 'newAddress';
    if (deliveryType) {
      this.isPickupDeliveryBox = false;      
      if(deliveryType === 'delivery'){ 
        this.activeTopButton = deliveryType;
        // this.isDisabledCheckAddressBtn = true;
        this.textAddress = address;
        this.loadDefualtAddress();
        this.lang = urlParams.get('lng');
        this.lat = urlParams.get('lat');
        this.free_delivery_amount = urlParams.get('free_delivery_amount');
        this.checkDistance(this.free_delivery_amount, urlParams.get('distance_kilometers'));
        this.isValidAddress = true;
        this.address = {
          latitude: this.lat,
          longitude: this.lang,
          route: address,
          locationDevice: null,
        };
        setTimeout(
          function () {
            const location = document.querySelector(".autocomplete");
            if (location && this.isValidAddress) { 
              location.value = address;    
            }}.bind(this),100);
      }
    }
    this.workingDays = this.useVendorStore.workingDays;   
    this.afterReload();
    this.loadMainFunction();
    this.activeBottomButton = this.isVendorOnOff ?  'Now' :'';
    
  },
  computed: {
 
    checkNowTime(){
      return this.isValidCurentTime && this.activeBottomButton === 'Now'
    },
    //  tomorrowDateTime() {
    //   const tomorrow = new Date();
    //   tomorrow.setDate(tomorrow.getDate() + 1); 
    //   return tomorrow.toISOString().slice(0, 16); 
    // },
    isVendorOnOff() {
      if (this.vendorData.is_tiffin_service === 1) {
        return false;
        }
        return this.checkVendor ? this.isValidCurrentTimeDuringCheckout : false;
    },

    pickupDeliveryModal(){
        return !this.pageLoader && this.isPickupDeliveryBox && !this.isvalidDateTime && 
        this.vendorData.is_tiffin_service === 1 && (this.vendorData.delivery_status === 1 || this.vendorData.pickup_status === 1 );
      // return !this.pageLoader && !this.isPickupDeliveryBox && !this.isvalidDateTime;
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
    },
    checkOutButtonShow() {
      if (
        this.activeTopButton === "pickup" ||
        this.activeBottomButton === "Later" ||
        this.activeBottomButton === "Now"
      ) {
        if (
          this.totalPay >=
          parseFloat(this.useVendorStore.resturant.min_order_amount)
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    isLogin() {
      return User.loggedIn();
    },
    showGoogleMap() {
      if (User.loggedIn()) {
        if (this.addressBtnSelect === "newAddress") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    posDeliveryValidtion() {
      if (
        this.activeTopButton === "delivery" &&
        this.activeBottomButton === "Now"
      ) {
        // return this.isValidAddress && this.isValidCurentTime;
         return this.isValidAddress && this.checkVendor;
      } else if (this.activeTopButton === "delivery" && this.activeBottomButton === "Later"
      ) {
        return this.isValidAddress && this.isvalidDateTime;
        // return true;
      } else if (
        this.activeTopButton === "pickup" &&
        this.activeBottomButton === "Later"
      ) {
        return this.isvalidDateTime;
      } else if (
        this.activeTopButton === "pickup" &&
        this.activeBottomButton === "Now"
      ) {
        // return this.isValidCurentTime;
        return this.checkVendor;
      } else {
        return false;
      }
    },
    currentDateTime() {
      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const year = tomorrow.getFullYear();
      const month = `${tomorrow.getMonth() + 1}`.padStart(2, "0");
      const day = `${tomorrow.getDate()}`.padStart(2, "0");
      const tommrrowTimes = `${year}-${month}-${day}T00:00`;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const currentDay = days[now.getDay()];

      const selectedDayRecord = this.workingDays.find(
        (day) => day.day_index === currentDay
      );

      if (
        selectedDayRecord &&
        selectedDayRecord.status === 1 &&
        this.vendorData.vendor_status === 1
      ) {
        const currentTime = now
          .toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .toLowerCase();
        const lastTimePeriod =
          selectedDayRecord.period_list[
            selectedDayRecord.period_list.length - 1
          ];

        if (
          this.convertTo24HourFormat(lastTimePeriod.end_time) <
          this.convertTo24HourFormat(currentTime)
        ) {
          return tommrrowTimes;
        } else {
          return new Date().toISOString().slice(0, 16);
        }
      } else {
        return tommrrowTimes;
      }
    },

    taxRate() {
      var totalPrice = 0;
      var qty = 0;
      var count = 0;
      this.posCartData.forEach((cartData) => {
        totalPrice += this.SizeAddonTotalPrice(
          cartData,
          cartData.selectdItems.qty
        );
        qty += cartData.selectdItems.qty;
        count++;
      });
      const tax = this.vendorData.tax / 100;
      const totalTax = (totalPrice - totalPrice / (1 + tax)).toFixed(2);
      return totalTax;
    },
    ItemtotaPrice() {
      var totalPrice = 0;
      this.posCartData.forEach((cartData) => {
        totalPrice += this.SizeAddonTotalPrice(
          cartData,
          cartData.selectdItems.qty
        );
      });
      const total = totalPrice - this.taxRate;
      return total.toFixed(2);
    },
    totalPay() {
        return this.calculationArr.finalPrice;
    },
     
    calculationArr(){
      var totalPrice = 0;
      var discountedPrice = 0;
      var applyDiscPrice = 0;
        this.posCartData.forEach((cartData) => {
          totalPrice += this.SizeAddonTotalPrice(
            cartData,
            cartData.selectdItems.qty 
          );
        });
        if (this.vendorData.online_flat_menu_discount !== null) {
           const discountVal = totalPrice * (1 - this.vendorData.online_flat_menu_discount / 100);
           discountedPrice = totalPrice - discountVal;
           totalPrice = parseFloat(discountVal);
        } 
        if(this.activeTopButton === 'delivery'){
          var finalPrice = totalPrice + this.deliveryCahrge;
        }else{
           var finalPrice = totalPrice;
        }
        return {
         'finalPrice':finalPrice.toFixed(2),
         'disocountedPrice':discountedPrice.toFixed(2),
         'totalPrice':totalPrice
        };
    },
    vendorLocationsInMap() {
      if (this.vendorData.country === "Pakistan") {
        return ["pk"];
      } else if (this.vendorData.country === "UAE") {
        return ["ae"];
      } else {
        return ["aus"];
      }
    },
    TotalAMountWithTax() {
      return parseFloat(this.taxRate) + parseFloat(this.ItemtotaPrice);
    },
  },
  methods: {
    onHoverMouse(type){
        this.hoverButton = type;
        // this.loadDefualtAddress();
         setTimeout(
            function () {
                const location = document.querySelector(".autocomplete");
                if (location && this.isValidAddress) {
                    location.value = this.textAddress;
                }
            }.bind(this),
            5
        );
    },
    onMouseLeave(){
      this.hoverButton = null;
        setTimeout(
            function () {
                const location = document.querySelector(".autocomplete");
                if (location && this.isValidAddress) {
                    location.value = this.textAddress;
                }
            }.bind(this),
            5
        );
    },
    getTopButtonStyle(status,buttonStatus){
      if(this.activeTopButton === status || this.hoverButton === status && buttonStatus === 1){
        return {
          'background-color':this.themColor.color,
          'color':this.themColorWithFont.color,
          'border' : '1px solid '+ this.themColor.color
        }
     
      }else{
        return {
          'border' :"1px solid "+this.themColor.color,
          'color':this.themColor.color
        }
      }
        //  <!-- :style="activeTopButton === 'delivery' || hoverButton === 'delivery' && vendorData.delivery_status === 1 ? themColorWithFont : {}" -->
    },
    
    onInputChangeAddress(){
       this.isDisabledCheckAddressBtn = false;
       this.isValidAddress = false;
       this.lang = null;
       this.lat = null;
    },
    handleTimePickerModalOpenClose(type){
      this.$emit('trigger-times-picker',type)
    },
    closeOutSideModal(){
     this.pickupDeliveryModal = true;
    },
   
    loadMainFunction() {
      
      if (Object.keys(this.usePosStore.tempData).length > 0) {
        this.activeTopButton = this.vendorData.country !== 'Pakistan' ? this.usePosStore.tempData.delivery_type : 'pickup';
        this.activeBottomButton = this.usePosStore.tempData.delivery_time;
        this.dateTimeValue = this.usePosStore.tempData.dateTimeValue;
        this.isPickupDeliveryBox = false;
      }
      if (this.activeBottomButton == "Now" && this.workingDays.length > 0) {
        this.filterCurrentTimeInVendorTimes();
      }
      if (this.activeBottomButton === "Later" && this.workingDays.length > 0) {
        this.validateTime();
      }
      if (Object.keys(this.usePosStore.userAddress).length > 0) {
          this.handleSelectArea(this.usePosStore.userAddress);
      }
      this.pickupDeliveryModal = true;
    },
    handleSelectArea(area) {
      this.lang = area.selectedArea.lang;
      this.lat = area.selectedArea.lat;
      this.checkLoader = true;
      this.address = {
        latitude: area.selectedArea.lat,
        longitude: area.selectedArea.lang,
        route: area.fullAddress,
        locationDevice: area.locationDevice,
      };
    this.checkAddress();
      if(this.vendorData.is_tiffin_service === 1){
        this.workingDays = this.useVendorStore.tiffin_delivery_time;
        this.textAddress = area.selectedArea.fullAddress;
        setTimeout(
            function () {
                const location = document.querySelector(".autocomplete");
                if (location && this.isValidAddress) {
                    location.value = this.textAddress;
                }
            }.bind(this),
            2000
        );
      }
      this.isValidAddress = true;
    },
    loadDefualtAddress(){
          setTimeout(
            function () {
                const location = document.querySelector(".autocomplete");
                if (location && this.textAddress && this.isValidAddress) {
                    location.value = this.textAddress;
                }
            }.bind(this),
            100
        );
    },
    handleCloseChooseCityArea(data) {
      this.showCityAreas = false;
      this.addressBtnSelect = "";
      this.activeTopButton = this.isLogin ? this.activeTopButton : "pickup";
      this.TopbuttonChangFunc(this.activeTopButton);
    },
    removeAddress() {
      this.oldAddressBtnText = "Save Address";
      this.deliveryCahrge = 0;
      this.isValidAddress = false;
      this.oldAddress = null;
    },
    handleSelectOldAddress(obj) {
      console.log(obj);
      this.oldAddress = obj;
      (this.lang = obj.lang), (this.lat = obj.lat), this.checkAddress();
      this.saveAddressBox = false;
      this.oldAddressBtnText = obj.type;
      this.addressBtnSelect = "oldAddress";
    },
    hnadleAddressUpdate() {
      this.loadSaveAddress();
    },
    closeAddressBox() {
      this.addressBtnSelect = "";
      this.saveAddressBox = false;
    },
    formatDateTo12HourFormat(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    },
    convertTo24HourFormat(timeStr) {
      const [time, meridiem] = timeStr.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (meridiem === "am" && hours === 12) {
        hours = 0;
      } else if (meridiem === "pm" && hours !== 12) {
        hours += 12;
      }
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
     handleDeliverySelectedDateTime(obj){
      console.log(obj);
      const {date,time,fullDateTime} = obj;
      this.dateTimeValue = fullDateTime
      // const [startTime, endTime] = time.split(' - ');
      // const [hour, minute] = this.convertTo24HourFormat(time);
      console.log(fullDateTime)
      // const newDateTime = `${date}T${hour}:${minute}`;
      // this.dateTimeValue = newDateTime;
      this.validateTime();
    },
    handleChangeDatePicker(){
      this.dateTimeValue = null;
      this.isvalidDateTime = false;
      // this.validateTime();
    },
    validateTime() {
      this.loadDefualtAddress();
      const selectedTime = this.convertTo24HourFormat(
        this.formatDateTo12HourFormat(this.dateTimeValue)
      );
      
      if (
        selectedTime && !VendorHelper.isPastSelectedTime(this.dateTimeValue)
      ) {
        
        const selectedDate = new Date(this.dateTimeValue);
       
        const options = { weekday: "long" };
        const selectedDay = selectedDate.toLocaleDateString(undefined, options);
        console.log(this.dateTimeValue);
        const selectedDayRecord = this.workingDays.filter(
          (day) => day.day_index === selectedDay 
        );
        if (selectedDayRecord[0].status === 1) {
          
          const isTimeWithinPeriod = this.isTimeWithinPeriodList(
            selectedTime,
            selectedDayRecord[0].period_list
          );
          const closestTimePeriod = this.findClosestTimePeriod(
            selectedTime,
            selectedDayRecord[0].period_list
          );
          if (isTimeWithinPeriod == "close") {
            this.invalidTimeErr =
              "Vendor is Offline, Please choose a time slot between " +
              selectedDayRecord[0].period_list[0].start_time +
              " -  " +
              selectedDayRecord[0].period_list[
                selectedDayRecord[0].period_list.length - 1
              ].end_time;
            this.invalidTimeAlert = true;
            this.isvalidDateTime = false;
            if (!this.checkIfMobile) {
              // Notification.tableErr("Please Reselect Timing");
            }
          } else if (isTimeWithinPeriod == "break") {
            this.invalidTimeErr =
              "Vendor is on Break, Please choose a time slot between " +
              closestTimePeriod.start_time +
              " - " +
              closestTimePeriod.end_time;
            this.invalidTimeAlert = true;
            this.isvalidDateTime = false;
            if (!this.checkIfMobile) {
              Notification.tableErr("Please Reselect Timing");
            }
          } else {
            this.invalidTimeAlert = false;
            this.invalidTimeErr = "";
            this.isvalidDateTime = true;
            this.addDataToSession();
          }
        } else {
          if (!this.checkIfMobile) {
            Notification.tableErr("Please Reselect Timing");
          }
          this.invalidTimeErr = "we are closed on " + selectedDay;
          this.invalidTimeAlert = true;
          this.isvalidDateTime = false;
        }
      } else {
        if(this.activeTopButton === 'delivery' && this.activeBottomButton === 'now'){
          this.invalidTimeErr = "Please select a future time";
          this.invalidTimeAlert = true;
          this.isvalidDateTime = false;
        }else{
           this.invalidTimeAlert = false;
          this.isvalidDateTime = true;
        }
      }
    },
    findClosestTimePeriod(selectedTime, timePeriods) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentTimeInMinutes = currentHour * 60 + currentMinutes;

      const selectedTimeInMinutes = this.convertToMinutes(selectedTime);
      let closestTimePeriod = null;
      let minDifference = Infinity;

      for (const period of timePeriods) {
        const startTimeInMinutes = this.convertToMinutes(
          this.convertTo24HourFormat(period.start_time)
        );
        const endTimeInMinutes = this.convertToMinutes(
          this.convertTo24HourFormat(period.end_time)
        );
        if (currentTimeInMinutes > endTimeInMinutes) {
          continue;
        }

        const differenceToStart = Math.abs(
          selectedTimeInMinutes - startTimeInMinutes
        );
        const minDifferenceToPeriod = differenceToStart;

        if (minDifferenceToPeriod < minDifference) {
          minDifference = minDifferenceToPeriod;
          closestTimePeriod = period;
        }
      }
      return closestTimePeriod;
    },

    convertToMinutes(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    },

    isTimeWithinPeriodList(selectedTime, periodList) {
      const openTime = this.convertTo24HourFormat(periodList[0].start_time);

      const closeTime = this.convertTo24HourFormat(
        periodList[periodList.length - 1].end_time
      );
      if (selectedTime < openTime || selectedTime > closeTime) {
        return "close";
      } else {
        for (const period of periodList) {
          const startTime = this.convertTo24HourFormat(period.start_time);
          const endTime = this.convertTo24HourFormat(period.end_time);
          if (selectedTime >= startTime && selectedTime <= endTime) {
            return "open";
            break;
          }
        }
        return "break";
      }
    },

    truncatedString(srting, leng) {
      if (srting) {
        if (srting.length > leng) {
          return srting.slice(0, leng) + "...";
        } else {
          return srting;
        }
      }
    },
    afterReload() {
      this.deliveryCahrge = this.usePosStore.deliveryCahrge;
      this.deliveryChargeData = this.usePosStore.deliveryChargeData;
      this.free_delivery_amount = this.usePosStore.free_delivery_amount;
      this.dateTimeValue = this.usePosStore.dateTimeValue;
      this.delivery_charges_list = this.useVendorStore.delivery_charges;
      // this.activeBottomButton = this.usePosStore.deliveryStatusTime;
      // this.isvalidDateTime = this.usePosStore.isvalidDateTime;
      const posdata = this.usePosStore.cartData;
      
    },
    loadSaveAddress() {
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      const base_url = this.useVendorStore.base_url;
      this.$axios
        .get(base_url + "getUserAddressVuejs", { headers })
        .then((res) => {
          console.log(res);
          this.saveAddress = res.data.data.address;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.userStore.logout();
            this.$router.push("/");
          }
        });
    },
    deliveryCondition() {
      if (this.TotalAMountWithTax >= this.free_delivery_amount) {
        this.totalPay = this.totalPay - this.deliveryCahrge;
        this.deliveryCahrge = 0;
      } else {
        console.log("chargesss",this.deliveryChargeData);
       this.deliveryCahrge = Object.keys(this.deliveryChargeData).length > 0 ? this.deliveryChargeData.charge : 0;
        // this.deliveryCahrge = this.deliveryChargeData.charge;
      }
      var obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        address: this.address,
        oldAddress: this.oldAddress,
        lang: this.lang,
        lat: this.lat,
        discountedPrice:this.calculationArr.disocountedPrice,
        isChangePrice:false,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
    },
    checkOutProduct() {
      this.isVendorOnOff = false;
      this.filterCurrentTimeInVendorTimes();
        const isNow = this.isValidCurentTime && this.activeBottomButton === 'Now';
        const isLater = this.activeBottomButton === 'Later';
        if (isNow || isLater) {
          this.addDataToSession();
          const route = User.loggedIn() ? "/dashboard" : "/resturant-checkout";
          this.$router.push(route);
        }else{
          this.isValidCurrentTimeDuringCheckout = false;
        }
      // this.filterCurrentTimeInVendorTimes();
      // if((this.isValidCurentTime && this.activeBottomButton === 'Now') || this.activeBottomButton === 'Later'){
      //   this.addDataToSession();
      //   if (User.loggedIn()) {
      //     this.$router.push("/dashboard");
      //   } else {
      //     this.$router.push("/resturant-checkout");
      //   }
      // }
    },
    addDataToSession() {
      const obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        oldAddress: this.oldAddress,
        address: this.address,
        lang: this.lang,
        lat: this.lat,
        discountedPrice:this.calculationArr.disocountedPrice,
        isChangePrice:false,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
    },

    SizeAddonTotalPrice(posData, qty) {
      var totalPrice = 0;
      if (posData.selectdItems.price) {
        const itemPrice = parseFloat(posData.selectdItems.price) * qty;
        totalPrice += itemPrice;
      }
      if (posData.SelectedSizes.addons) {
        totalPrice += parseFloat(posData.SelectedSizes.price) * qty;
        posData.SelectedSizes.addons.forEach((addon) => {
          var addonPrice = parseFloat(addon.price) * addon.qty;
          console.log(addonPrice);
          totalPrice += addonPrice;
        });
      } else if (posData.selectedItemsAdon.length > 0) {
        posData.selectedItemsAdon.forEach((item) => {
          totalPrice += parseFloat(item.price) * item.qty;
        });
      }
      return totalPrice;
    },
    onPlaceChanged(place) {
      this.address = place.formatted_address;
    },
    TopbuttonChangFunc(status) {
      this.activeBottomButton = this.isVendorOnOff ?  'Now' :''
      this.isPickupDeliveryBox = false;
      this.dateTimeValue = null;
      this.isvalidDateTime =false;
      if(status === "delivery" && this.vendorData.is_tiffin_service === 1){
        this.workingDays = this.useVendorStore.tiffin_delivery_time;
      }else{
        this.workingDays = this.useVendorStore.workingDays;
      }
      if (
        status === "delivery" &&
        this.vendorData.country === "Pakistan" &&
        !this.isLogin
      ) {
        this.showCityAreas = true;
      }
      this.activeTopButton = status;
      var obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        oldAddress: this.oldAddress,
        address: this.address,
        lang: this.lang,
        lat: this.lat,
         discountedPrice:this.calculationArr.disocountedPrice,
         isChangePrice:false,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
    },
    BottombuttonChangFunc(status) {
      this.loadDefualtAddress();
      this.invalidTimeAlert = false;
      this.isValidCurentTime = true;
      this.invalidTimeErr = null;
      this.activeBottomButton = status;
      this.dateTimeValue = null;
      var obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        oldAddress: this.oldAddress,
        address: this.address,
        lang: this.lang,
        lat: this.lat,
         discountedPrice:this.calculationArr.disocountedPrice,
         isChangePrice:false,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
      if (status == "Now") {
        this.filterCurrentTimeInVendorTimes();
      }

      if(this.activeTopButton === "delivery" && this.vendorData.is_tiffin_service === 1){
        this.workingDays = this.useVendorStore.tiffin_delivery_time;
      }else{
        this.workingDays = this.useVendorStore.workingDays;
      }
    },
    // Wed Sep 04 2024 15:41:14 GMT+0500 (Pakistan Standard Time)

    filterCurrentTimeInVendorTimes() {
      // const selectedDate = new Date(VendorHelper.setTimeZone(this.vendorData.timezone));
      const dateArray = VendorHelper.setTimeZone(this.vendorData.timezone);
      const dateTimeString = `${dateArray[2]} ${dateArray[1]}`;
      const selectedDate = new Date(dateTimeString);
      let hours = selectedDate.getHours();
      let minutes = selectedDate.getMinutes();

      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      const currentTime24h = `${hours}:${minutes}`;
      const options = { weekday: "long" };
      const selectedDay = selectedDate.toLocaleDateString(undefined, options);

      const selectedDayRecord = this.workingDays.filter(
        (day) => day.day_index === selectedDay
      );
     
      const isTimeWithinPeriod = this.isTimeWithinPeriodList(
        currentTime24h,
        selectedDayRecord[0].period_list
      );
      if (isTimeWithinPeriod == "close") {
        this.invalidTimeAlert = true;
        this.isValidCurentTime = false;
        this.invalidTimeErr =
          "Vednor is offline, Please choose a time slot between " +
          selectedDayRecord[0].period_list[0].start_time +
          " - " +
          selectedDayRecord[0].period_list[
            selectedDayRecord[0].period_list.length - 1
          ].end_time;
      } else if (isTimeWithinPeriod == "break") {
        this.invalidTimeAlert = true;
        this.isValidCurentTime = false;
        const closestTimePeriod = this.findClosestTimePeriod(
          currentTime24h,
          selectedDayRecord[0].period_list
        );
        this.invalidTimeErr =
          "Vendor is on Break, Please choose a time slot between " +
          closestTimePeriod.start_time +
          " - " +
          closestTimePeriod.end_time;
      } else {
        this.invalidTimeAlert = false;
        this.isValidCurentTime = true;
        this.invalidTimeErr = null;
      }
    },
    ShwoAddressBox(status) {
      this.addressBtnSelect = status;
      this.oldAddressBtnText = "Save Address";
      this.deliveryCahrge = 0;
      this.isValidAddress = false;
      this.saveAddressBox = status === "oldAddress";
      if (this.vendorData.country === "Pakistan") {
        this.showCityAreas = status === "newAddress";
      }
      this.loadDefualtAddress();
    },
    addonDecrement(itemIndex, addonIndex, status) {
      if (status === "isGroupAddon") {
        if (this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty > 1) {
          this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty--;
        }
      } else {
        if (
          this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty > 1
        ) {
          this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty--;
        }
      }
      // const audio = new Audio(this.incrementAudio);
      //     audio.play();
      this.storeSalesItems();
    },
    addonIncrement(itemIndex, addonIndex, status) {
      if (status === "isGroupAddon") {
        this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty++;
      } else {
        this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty++;
      }
      // const audio = new Audio(this.incrementAudio);
      // audio.play();
      this.storeSalesItems();
    },
    decrement(index) {
      if (this.posCartData[index].selectdItems.qty > 1) {
        // const audio = new Audio(this.incrementAudio);
        // audio.play();
        this.CardisLoading[index] = true;
        this.posCartData[index].selectdItems.qty--;
        this.storeSalesItems();
      } else {
        this.posCartData.splice(index, 1);
        if (this.posCartData.length < 1) {
          localStorage.removeItem("cartData");
          localStorage.removeItem("deliveryCahrge");
          localStorage.removeItem("free_delivery_amount");
          localStorage.removeItem("deliveryChargeData");
          this.deliveryCahrge = 0;
          this.free_delivery_amount = 0;
          this.deliveryChargeData = {};
        }
      }
      // this.usePosStore.checkOutStorage(this.posCartData,obj);
      this.deliveryCondition();
      setTimeout(() => {
        this.CardisLoading[index] = false;
      }, 1000);
    },
    calculateQtyPrice(qty, price) {
      return (qty * price).toFixed(2);
    },
    increment(index) {
      // const audio = new Audio(this.incrementAudio);
      // audio.play();
      this.CardisLoading[index] = true;
      this.posCartData[index].selectdItems.qty++;
      this.deliveryCondition();
      this.storeSalesItems();
      setTimeout(() => {
        this.CardisLoading[index] = false;
      }, 1000);
    },
    storeSalesItems() {
      var obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        oldAddress: this.oldAddress,
        address: this.address,
        lang: this.lang,
        lat: this.lat,
        discountedPrice:this.calculationArr.disocountedPrice,
        isChangePrice:false,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
    },
    removeItem(index) {
      const audio = new Audio(this.removeAudio);
      audio.play();
      this.posCartData.splice(index, 1);

      if (this.posCartData.length < 1) {
        localStorage.removeItem("cartData");
        localStorage.removeItem("posItems");
        localStorage.removeItem("free_delivery_amount");
        localStorage.removeItem("deliveryChargeData");
        localStorage.removeItem("deliveryCahrge");
        localStorage.removeItem("deliveryType");
        localStorage.removeItem("dateTimeValue");
        localStorage.removeItem("isvalidDateTime");
        localStorage.removeItem("deliveryStatusTime");
        this.deliveryCahrge = 0;
        this.free_delivery_amount = 0;
        this.deliveryChargeData = {};
      }
      this.deliveryCondition();
      this.storeSalesItems();
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.lang = this.address.longitude;
      this.lat = this.address.latitude;
      this.textAddress =
        this.address.route + 
        "," +
        this.address.locality +
        "," +
        this.address.country; 
    }, 
  
    checkDistance(free_delivery_amount, distance) {
      this.delivery_charges_list.forEach((charge) => {
        if (distance >= charge.start_range && distance <= charge.end_range) {
          this.deliveryChargeData = charge;
          if (this.totalPay >= free_delivery_amount) {
            this.deliveryCahrge = 0;
          } else {
            this.deliveryCahrge = charge.charge;
          }
        }
      });
      
      var obj = {
        taxRate: this.taxRate,
        ItemtotaPrice: this.ItemtotaPrice,
        totalPay: this.totalPay,
        deliveryCahrge: this.deliveryCahrge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        dateTimeValue: this.dateTimeValue,
        delivery_type: this.activeTopButton,
        delivery_time: this.activeBottomButton,
        // isvalidDateTime:this.isvalidDateTime,
        oldAddress: this.oldAddress,
        address: this.address,
        lang: this.lang,
        lat: this.lat,
      };
      console.log(obj);
      // this.usePosStore.checkOutStorage(this.posCartData, obj);
    },

    checkAddress() {
      this.isDisabledCheckAddressBtn = true;
      this.checkLoader = true;
      var url =
        this.vendorData.country !== "Pakistan"
          ? "address-check"
          : "checkAddressPk";
      this.$axios
        .post(this.useVendorStore.base_url + url, {
          vendor_id: this.vendorData.id,
          lat: this.lat,
          lang: this.lang,
        })
        .then((response) => {
          // this.textAddress = null;
          if(this.vendorData.is_tiffin_service === 0){
              this.textAddress = null;
          }
          this.activeTopButton = "delivery";
          console.log(response);
          console.log("distance in km");
          console.log(response);
          if (response.data.success == true) {
            var apiRespone = response.data.delivery_charge;
            var distance = apiRespone.distance_kilometers;
            this.free_delivery_amount = apiRespone.free_delivery_amount;
            console.log(apiRespone.distance_kilometers);
            if (
              apiRespone.distance_kilometers <= apiRespone.max_delivery_range_km
            ) {
              this.isPickupDeliveryBox = false;
              
              this.addressAlert = false;
              this.isValidAddress = true;
              if (apiRespone.free_delivery === 0) {
                this.checkDistance(apiRespone.free_delivery_amount, distance);
              } else {
                
                if (apiRespone.free_delivery_distance <= distance) {
                  this.checkDistance(apiRespone.free_delivery_amount, distance);
                } else {
                  this.deliveryCahrge = 0;
                }
              }
            } else {
             
              this.addressAlert = true;
              this.isValidAddress = false;
              window.setInterval(() => {
                this.addressAlert = false;
              }, 8000);
            }
            // console.log(apiRespone);
          } else {
            this.addressAlert = true;
            
            this.isValidAddress = false;
          }
          this.deliveryCondition();
          this.checkLoader = false;
          this.showCityAreas = false;
        })
        .catch((error) => {
          this.checkLoader = false;
        });
    },
  },
};
</script>

<style scoped>
.resturent-img {
  width: 72px;
  height: 72px;
  border-top-left-radius: 15px;
}
.resturant-info {
  padding-left: 15px;
}
.resturant-info h3 {
  font-size: 14px;
}
.resturant-info .location,
.resturant-info p {
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
}
.card-header {
  display: flex;
  align-items: center;
  padding: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 72px;
}
.card-body {
  padding: 20px;
}
.card-body .top-btton-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body .top-btton-list button {
  padding: 5px 10px;
  color: #5a616a;
  width: 50%;
  border: 1px solid #5a616a;
}
.hoverButtons:hover {
  background-color: #5a616a;
  color: #fff !important;
}
.card-body .top-btton-list .btn1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  position: relative;
}
.card-body .top-btton-list .btn2 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.v-card {
  border-radius: 15px;
}
.btn-active {
  background-color: #5a616a;
  color: #fff !important;
}
.bottom-list {
  margin: 10px 0; 
}
.h3-heading {
  font-weight: 500;
  font-size: 16px;
}
.pickup-addres-card {
  margin-top: 20px;
}
.pickup-addres-card .resturant-info {
  border: 1px solid #d01148;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.badge-text {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}
.v-badge {
  position: absolute;
  top: 11px;
  right: 60px;
  z-index: -1;
}
/* .input-field-cus{
    padding-top: 20px;
} */

/* .custom-text-field  {
   color: #d7171c !important; 
} */
.scrollable-section {
  max-height: 180px;
  overflow: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #5555;
}



.scrollable-section::-webkit-scrollbar {
  width: 12px; 
}

.scrollable-section::-webkit-scrollbar-track {
  background: #ab1818; 
}

.scrollable-section::-webkit-scrollbar-thumb {
  background-color: #d31717; 
  border-radius: 10px;
  border: 3px solid #f1f1f1; 
}

.scrollable-section::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

/* For Firefox */
.scrollable-section {
  scrollbar-width: thin; 
  /* scrollbar-color: #c73737 #f1f1f1;  */
}


.scrollable-content {
  width: 100%; /* Allow the content to expand horizontally */
}

.scrollable-content > * {
  margin-right: 8px; /* Add spacing between the items */
}
.scrollable-content .item-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  margin: 5px 0;
  widows: 100%;
  padding: 20px 10px;
  position: relative;
}
.scrollable-content .item-card .dote-icon {
  width: 15px;
  border: 1px solid;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.scrollable-content .item-namePrice {
  padding: 0 10px;
  display: grid;
}
.item-card-top {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 94%;
}
.qty-section {
  width: 31%;
}
.qty-section .qty-btn {
  width: 15px;
  height: 15px;
  font-size: 10px;
}
.qty-section .count-box {
  width: 50px;
  width: 33px;
  margin: 0 5px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
}
.item-remove {
  color: #d7171c;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 10px;
}

.scrollable-content .item-namePrice small {
  font-size: 11px;
}
.item-extra-things {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
.item-extra-things .line {
  width: 15px;
  height: 2px;
  margin-right: 3px;
}
.check-out-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.check-out-btn .v-btn {
  width: 100%;
  height: 54px;
}
.locationSeach {
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.locationSeach:focus {
  border: 1px solid #d7171c;
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.payment-area {
  padding: 10px;
  /* display: flex; */
}
.payment-area .paymentSide table {
  width: 100% !important;
}
.location-check .v-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 45px;
  color: #fff;
}
.custom-alert {
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.disable-btn {
  background: #f6f7f9 !important;
  opacity: 0.2;
  color: #5a616a !important;
}
.payment-area tr {
  font-size: 13px;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}
.addon_list {
  display: flex;
  justify-content: space-between;
  width: 87%;
}
@media (max-width: 1024px) {
  .card-header {
    justify-content: initial !important;
    /* padding-left: 10px !important; */
  }
  .payment-area .paymentSide {
    width: 100% !important;
  }
  .payment-area {
    display: block;
  }
  .payment-area tr {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 599px) {
  .resturant-info .location {
    font-size: 10px !important;
  }
  .resturant-info {
    padding-left: 5px;
  }
  .qty-section .count-box {
    width: 25px;
  }
}
.locationSearch .pac-container {
  z-index: 999999 !important;
}
.bg-ddd{
  background-color: #dddddd21 !important;
}

@media (max-width: 1024px) and (min-width: 991px) {
   .qty-section{
    width: 50% !important;
   }
}

</style>
