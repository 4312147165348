<template>
  <v-sheet class="mx-auto">
    <v-slide-group show-arrows>
      <v-slide-group-item v-for="(cat, n) in allCategory" :key="cat" >
        <v-btn
          class="ma-2 lg-screen"
          rounded
          :style="getDynamicColor(n)"
          @click="SlectCategory(cat, n)"
          v-if="cat.frontend_status == 1 && cat.status == 1 "
        >
          {{ cat.name }}
        </v-btn>
        <!-- :class="{ 'selected-cateogry': n === activeCat }" -->
        <button
          @click="SlectCategory(cat, n)"
          v-if="cat.frontend_status == 1 && cat.status == 1 "
          class="cateogry-item sm-screenCat"
          :style="{'color': n === activeCat ? useVendorStore.resturant.main_color :'#000'}"
          :class="{ 'selected-cateogry': n === activeCat }"
        >
          {{ cat.name }}
          <span :style="{'background-color':useVendorStore.resturant.main_color}" :class="{ 'selected-cateogry-line': n === activeCat}"></span>
        </button>
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
import { vendorStore } from "@/store/vendor.js";
export default {
  props: ["allCategory", "activeCat",'timePickerTrigger'],
  setup() {
    const useVendorStore = vendorStore();
    return { useVendorStore };
  },
  data() {
    return {
      single_menu: [],
    };
  },
  mounted() {
    // this.allCategory = this.useVendorStore.cateogries;
    // console.log(this.useVendorStore.cateogries)
  },

  methods: {
    getDynamicColor(index){
      return {
        backgroundColor: this.activeCat === index ? this.useVendorStore.resturant.main_color : '#fff',
        color: this.activeCat === index ? this.useVendorStore.resturant.font_color : '#000',
      };
    },
    SlectCategory(data, n) {
      const element = document.querySelector("#cat" + data.id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
      this.single_menu = data;
      this.$emit("category-menu-selected", data, n);
      // console.log(data);
      // this.$emit();
    },
  },
};
</script>
<style>
/* .category-bar{

    border-radius: 10px;

    position: sticky;
    top: 0;
    z-index: 100;
} */

.active-btn {
  background-color: #d7171c !important;
  color: #ffffff !important;
}
.cateogry-item {
  margin: 0 10px;
  cursor: pointer;
  font-weight: 500;
  color: #555;
  position:relative;
}

.selected-cateogry-line{
  position:absolute;
  content:"";
  left:0;
  bottom:0;
  width:100%;
  height: 2px;
}
/* .selected-cateogry::after {
  content: "";
  position: absolute;
  height: 3px;
  background-color: #d7171c;
  bottom: -1px;
  left: 0;
  width: 100%;
} */
.sm-screenCat {
  display: none;
}
@media (max-width: 768px) {
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 15px !important;
  }
  .lg-screen {
    display: none;
  }
  .sm-screenCat {
    display: block !important;
  }
}
</style>
