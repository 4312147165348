<template>
    <v-card rounded>
        <v-card-text style="border-radius:20px;padding:30px" :style="{
            'border':'4px solid '+useVendorStore.resturant.main_color,
        }">
            <v-btn icon @click="$emit('close')" class="cros-btn">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <div style="display:flex;justify-content: center">
                <div>
                    <div style="display:flex;justify-content:center;margin-bottom:15px">
                        <img :src="useVendorStore.resturant.vendor_logo" alt="Your Alt Text" :width="70" :height="70"/>
                    </div>
                    <h4 class="text-center">Please select your location</h4>
                    <v-btn class="location-find-btn" :disabled="checkLoader" @click="getLocation" rounded block style="margin-top:10px;" :style="themColorWithFont">
                        <template v-if="checkLoader" >
                            <span>Loading...</span>
                            <v-progress-circular indeterminate size="24" color="white" class="ml-2"></v-progress-circular>
                        </template>
                        <template v-else>
                            <v-icon> mdi-map-marker</v-icon> Use Current Location
                        </template> 
                    </v-btn>
                </div>  
            </div> 
            <div class="mt-5">
                <div>
                    <v-autocomplete  class="custom-combobox" clearable chips variant="outlined" label="Select City" :items="allCities" :item-props="itemProps" v-model="selectedCity" rounded></v-autocomplete>

                    <v-autocomplete  rounded class="rounded-autocomplete" clearable chips variant="outlined" label="Select Area" :items="allAreas" :item-props="areaProps" v-model="selectedArea"></v-autocomplete>
                    <v-text-field  rounded variant="outlined" v-model="fullAddress" label="Full Address"></v-text-field>
                    <v-text-field v-if="isEditAddress" rounded variant="outlined" v-model="addressType" label="Address Type"></v-text-field>

                    <v-btn @click="selecteArea" :loading="isLoading" rounded block class="mb-3" :style="themColorWithFont" :disabled="isDisabled">{{ isEditAddress ? 'Save' : 'Select' }}</v-btn>
                </div>
                <span v-if="selectedArea !== null">lat :- {{this.selectedArea.lat}}</span><br>
                <span v-if="selectedArea !== null">lang:- {{this.selectedArea.lang}}</span>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { vendorStore } from '@/store/vendor.js'; 
import { posStore } from '@/store/posStore'  
  export default{
    props:['useVendorStore','themColorWithFont','isEditAddress','selectedAddress','isLoading'],
    setup() {
        const useVendorStore = vendorStore();
         const usePosStore = posStore();
          return { usePosStore,useVendorStore};
    },
    data(){
        return{
            checkLoader:false,
            allCities:[],
            selectedCity:null,
            selectedArea:null,
            fullAddress:null,
            addressType:null,
            locationDevice:'dektop',
        }
    },
    computed:{
        allAreas(){
            return this.selectedCity ? this.selectedCity.areas : [];
        },
        isDisabled(){
            if(!this.isEditAddress){
                return this.fullAddress === null || this.selectedArea ==='' || this.selectedArea === null;
            }else{
                return this.fullAddress === null || this.addressType === null || this.selectedArea ==='' || this.selectedArea === null;
            }
        },
       
    },
    mounted(){
        if(Object.keys(this.usePosStore.userAddress).length > 0){
            this.fullAddress = this.usePosStore.userAddress.fullAddress;
            this.selectedCity = this.usePosStore.userAddress.selectedCity;
            this.selectedArea = this.usePosStore.userAddress.selectedArea;
            this.locationDevice = this.usePosStore.userAddress.locationDevice;
        }
        if(this.isEditAddress){
            this.addressType = this.selectedAddress.addressType;
            this.fullAddress = this.selectedAddress.fullAddress;
            // this.locationDevice = this.selectedAddress.locationDevice;
            this.selectedCity = null;
            this.selectedArea = null;
        }
        this.$axios
            .get(this.useVendorStore.base_url + 'locationData/'+this.useVendorStore.vender_id)
            .then((response)=>{
                    console.log(response.data.data);
                    this.allCities = response.data.data;
            }).catch((error)=>{})
    },
    methods:{
        selecteArea(){
            const obj ={
                selectedCity:this.selectedCity, 
                selectedArea:this.selectedArea, 
                fullAddress:this.fullAddress,
                addressType:this.addressType, 
                locationDevice:this.locationDevice
            }
            this.usePosStore.pkUserAddress(obj);
            this.$emit("select-area",obj) 
        },
        areaProps(item){ 
            return {
               title: item.area,
            }
        },
        itemProps (item) {
            return {
               title: item.city,
            }
       },
        // GPS location enable code   /
        getLocation() {
            this.checkLoader = true;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                this.showPosition,
                this.showError,
                { enableHighAccuracy: true }
                );
            } else {
                this.checkLoader = false;
                alert("Geolocation is not supported by this browser.");
            }
        },
        showPosition(position) {
           
            this.getNearestLocation(position.coords.latitude,position.coords.longitude);
            this.getFullAddress(position.coords.latitude, position.coords.longitude);
            this.selectedArea ={
                area: this.getFullAddress(position.coords.latitude, position.coords.longitude),
                lat:position.coords.latitude,
                lang:position.coords.longitude,
            }
            this.locationDevice = 'mobile';
        },
        getNearestLocation(latitude,longitude){
            this.$axios
                .post(this.useVendorStore.base_url + 'getNearestArea',{
                    lat: latitude,
                    lang: longitude,
                    vendor_id:this.useVendorStore.vender_id
                })
                .then((response)=>{
                    console.log('areaObj')
                    console.log(response.data.status)
                    this.checkLoader = false;
                    this.showCityAreas = true;
                    if(response.data.status === 402){
                        Notification.customeTextError(response.data.message);
                    }else{
                        const filterCity =  this.filteredCities('Rawalpindi')
                        this.selectedCity = filterCity[0];
                        this.allAreas = filterCity[0].areas;
                        this.selectedArea = response.data.nearest_area;
                        const areaObj = this.filteredAreas(response.data.nearest_area);
                        this.selectedArea =  areaObj[0];
                        this.selectedArea.lat = latitude;
                        this.selectedArea.lang =longitude ;
                    }
                   
                }).catch((error)=>{
                    this.checkLoader = false;
                })
        },
        filteredCities(city) {
            return this.allCities.filter((cityObj) => {
              return cityObj.city.toLowerCase().includes(city.toLowerCase());
          });
        },
        filteredAreas(area){
          return this.selectedCity.areas.filter((areaObj) => {
              return areaObj.area.toLowerCase().includes(area.toLowerCase());
          });
        },

        showError(error) {
            this.checkLoader = false;
            console.log(error);
            switch (error.code) {
                case error.PERMISSION_DENIED:
                const userWantsToEnable = confirm("Location access is denied. Do you want to enable it? Click OK to go to browser settings.");
                if (userWantsToEnable) {
                    alert("Please go to your browser settings and enable location access for this site.");
                } else {
                    alert("Without location access, some features of this site may be limited.");
                }
                break;
                case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
                case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
                case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            }
        },
        getFullAddress(lat, lng) {
        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`)
            .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
            })
            .then(data => {
            const fullAddress = data.display_name;
            this.addressText = fullAddress;
            })
            .catch(error => {
            console.error('Error fetching address:', error);
            });
        }
        // end GPS location enable code   /
    }
  }
</script>
<style scoped>
.v-card{
    padding:2px;
    border-radius:20px !important;
}
.v-input--density-default{
    --v-input-control-height: 0px !important;
    --v-input-padding-top: 10px !important;
    --v-input-chips-margin-bottom: -3px
}
.cros-btn{
    position:absolute;
    top:10px;
    right:10px;
    height:35px;
    width:35px;
    font-size:12px;
}
.location-find-btn{
    display:none;
}
@media(max-width:768px){
    .location-find-btn{
        display:block !important;
    }
}
</style>