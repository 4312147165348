<template>
     <page-loader v-if="pageLoader"/>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div @click="drawer=false">
        <v-container style="margin-top: 70px;" >
          <v-row>
              <v-col cols="12" sm="12" md="8">
                  <login-signup ref="scrollTarget"/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                  <cart-component :vendorData="vendorData" :formattedPrice="formattedPrice"/> 
              </v-col>
          </v-row>
          <div class="spceing-box"></div>
        </v-container>
        <div class="web-footer"> <web-footer/></div>
       
        <div class="sm-footer">
        <small-screen-footer @open-pos-dilog-box="posDilogBox=true" :totalItems="cartData.length"/>
        <v-dialog
          v-model="posDilogBox"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
        <v-card>
            <v-toolbar dark color="#d7171c" height="40">
                <v-btn icon dark @click="posDilogBox = false">
                <v-icon style="color: #fff;">mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer> 
                <v-toolbar-items style="margin:10px 20px;color: #fff ;">
                    <v-icon >mdi-cart</v-icon>
                    Cart
                </v-toolbar-items>
            </v-toolbar>
            <div style="margin: 8px 10px;">
                <cart-component :vendorData="vendorData" :formattedPrice="formattedPrice"/>
            </div>
        </v-card>
    <!-- Dialog content -->
    <!-- ... -->
  </v-dialog>
    </div>
    </div>
</template>
<script>
import NavBar from '@/layouts/default/NavBar.vue'
import { posStore } from '@/store/posStore' 
import { vendorStore } from '@/store/vendor.js' 
import logInOrSignUp from '@/components/restaurantCheckOut/loginOrSiginComponent.vue'
import checkoutCart from '@/components/restaurantCheckOut/cartComponent.vue'
import PageLoader from '@/components/PageLoader.vue'
import webFooterComponent from '@/components/webFooterComponent.vue'
import smFooterComponent from '@/components/smFooterComponent.vue'
export default{
 
    components:{
        'page-loader':PageLoader,
        'login-signup':logInOrSignUp,
        'cart-component':checkoutCart,
        'nav-bar-vue':NavBar,
        'small-screen-footer':smFooterComponent,
        'web-footer':webFooterComponent,
    },
    setup() {
        const useVendorStore = vendorStore();
         const usePosStore = posStore();
          return { usePosStore,useVendorStore};
    },
    data(){
        return{
            drawer:false,
            pageLoader:true,
            cartData:"",
            ItemtotaPrice:"",
            taxRate:"",
            totalPay:"",
            vendorData:{},
            deliveryCharge:"",
            posDilogBox:false,
        }
    },
    created(){
        // alert(Object.keys(this.useVendorStore.resturant).length);
         if(Object.keys(this.useVendorStore.resturant).length === 0){
           this.$router.push('/')
         }
        this.scrollToComponent() 
    },
    mounted(){
      this.afterReload();
      this.scrollToComponent();
      VendorHelper.updatePageTitle(this.useVendorStore.resturant.name +'- Ozpos');

    },
   
    methods:{
        formattedPrice(price){
           return VendorHelper.formatCurrency(price);
        },
        scrollToComponent(){ 
            if (this.$refs.scrollTarget) {
                this.$refs.scrollTarget.$el.scrollIntoView({
                behavior: 'smooth', // Use 'auto' for instant scroll
                block: 'center',    // Scroll to the vertical center of the component
                inline: 'center',   // Scroll to the horizontal center of the component
             });
            }
        },
        afterReload(){
        this.cartData = this.usePosStore.cartData.posCartData || [];
        this.ItemtotaPrice = this.usePosStore.cartData.totalCulculate.ItemtotaPrice;
        this.taxRate = this.usePosStore.cartData.totalCulculate.taxRate;
        this.totalPay = this.usePosStore.cartData.totalCulculate.totalPay;
        this.vendorData = this.useVendorStore.resturant;
        this.pageLoader=false;
    },
    }

}
</script>

<style scoped>

.sm-footer{
    display: none;
}

@media(max-width:959.99px){
    .sm-footer{
        display: block !important;
   }
   .spceing-box{
    display: none !important;
   }
   .spceing-box{
        display: block;
        height: 100px;
    }
}

</style>