<template>
  <page-loader v-if="pageLoader" />
  <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer" />
  <div style="margin-top: 70px" @click="drawer = false">
    <v-container>
      <v-row>
        <v-col md="8" sm="12" cols="12" ref="scrollTarget">
          <div>
            <v-card>
              <v-card-title
                @click="toggleCard"
                class="card-header"
                :style="dynmicFontColor"
              >
                <v-row>
                  <v-col cols="10" sm="11" class="cardType">
                    <v-icon>mdi-credit-card</v-icon> 
                    <span> Payment Type</span>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <!-- <v-icon>mdi-chevron-down</v-icon> -->
                  </v-col>  
                </v-row> 
              </v-card-title>
              <v-divider></v-divider>
              <v-expand-transition>
                <v-card-text v-if="isCardExpanded">
                  <div class="expanded-button">
                    <button
                     @mouseover="hoverButton = 'STRIPE'"
                     @mouseleave="hoverButton = null"
                      :style="{
                        'background-color':cardForm.isActive === 'STRIPE' || hoverButton === 'STRIPE' && paymentSetting.stripe === 1 ? useVendorStore.resturant.main_color : '',
                        'color':cardForm.isActive === 'STRIPE' || hoverButton === 'STRIPE' && paymentSetting.stripe === 1 ? useVendorStore.resturant.font_color: useVendorStore.resturant.main_color,
                        'border':'1px solid '+useVendorStore.resturant.main_color
                      }"
                      class="login"
                      @click="changeAreaBtn('STRIPE')"
                      :disabled="paymentSetting.stripe === 0"
                      :class="{
                        active: cardForm.isActive === 'STRIPE',
                        'disable-btn': paymentSetting.stripe === 0,
                      }"
                    >
                      Credit/Debit Card
                    </button>
                    <button
                     @mouseover="hoverButton = 'COD'"
                     @mouseleave="hoverButton = null"
                    :style="{
                        'background-color':cardForm.isActive === 'COD' || hoverButton=== 'COD' && paymentSetting.cod === 1 ? useVendorStore.resturant.main_color : '',
                        'color':cardForm.isActive === 'COD' || hoverButton=== 'COD' && paymentSetting.cod === 1 ? useVendorStore.resturant.font_color: useVendorStore.resturant.main_color,
                        'border':'1px solid '+useVendorStore.resturant.main_color
                      }"
                      class="siginup"
                      @click="changeAreaBtn('COD')"
                      :disabled="paymentSetting.cod === 0"
                      :class="{
                        active: cardForm.isActive === 'COD',
                        'disable-btn': paymentSetting.cod === 0,
                      }"
                    >
                      Cash on Delivery
                    </button>
                  </div>
                  <div class="form-field" v-if="cardForm.isActive === 'STRIPE'">
                    <v-card>
                      <v-card-text class="osahan-card-body border-top p-5">
                        <h6 class="m-0">Add new card</h6>
                        <p class="small" style="margin-bottom: 20px">
                          WE ACCEPT
                          <span class="osahan-card ml-2 font-weight-bold"
                            >(Master Card / Visa Card / Rupay)</span
                          >
                        </p>
                        <!-- stripe form -->
                        <div
                          id="example2-card-number"
                          :style="{
                            border:
                              '1px solid ' +
                              this.useVendorStore.resturant.main_color,
                          }"
                          class="input empty"
                        ></div>
                        <div
                          id="example2-card-expiry"
                          :style="{
                            border:
                              '1px solid ' +
                              this.useVendorStore.resturant.main_color,
                          }"
                          class="input empty"
                        ></div>
                        <div
                          id="example2-card-cvc"
                          :style="{
                            border:
                              '1px solid ' +
                              this.useVendorStore.resturant.main_color,
                          }"
                          class="input empty"
                        ></div>
                        <!-- end stripe form -->
                        <!-- <div id="card-element"></div> -->
                      </v-card-text>
                    </v-card>
                  </div>
                  <div
                    class="form-field"
                    v-if="cardForm.isActive === 'credit'"
                  ></div>
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </div>
        </v-col>
        <v-col md="4" sm="12" cols="12">
          <cart-component
            :vendorData="vendorData"
            :posCartData="cartData"
            :totalPay="totalPay"
            :taxRate="taxRate"
            :ItemtotaPrice="ItemtotaPrice"
            :cardForm="cardForm"
            @new-action-perofrom="handleNewAction"
            :stripe="stripe"
            :cardElement="cardElement"
            :card="card"
            :stripePercentage="paymentSetting.stripePercentage"
            :formattedPrice="formattedPrice"
          />
        </v-col>
      </v-row>
      <div class="spceing-box"></div>
    </v-container>
    <div class="web-footer"><web-footer /></div>
    
    <div class="sm-footer">
      <small-screen-footer
        @open-pos-dilog-box="posDilogBox = true"
        :totalItems="cartData.length"
      />
      <v-dialog
        v-model="posDilogBox"
        fullscreen
        height="1000"
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#d7171c" height="40">
            <v-btn icon dark @click="posDilogBox = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-items style="margin: 10px 20px; color: #fff">
              <v-icon>mdi-cart</v-icon>Cart
            </v-toolbar-items>
          </v-toolbar>
          <div style="margin: 8px 10px">
            <cart-component
              :vendorData="vendorData"
              :posCartData="cartData"
              :totalPay="totalPay"
              :taxRate="taxRate"
              :ItemtotaPrice="ItemtotaPrice"
              :cardForm="cardForm"
              @new-action-perofrom="handleNewAction"
              :stripe="stripe"
              :cardElement="cardElement"
              :card="card"
              :stripePercentage="paymentSetting.stripePercentage"
              :formattedPrice="formattedPrice"
            />
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import NavBar from "@/layouts/default/NavBar.vue";
import PageLoader from "@/components/PageLoader.vue";
import { posStore } from "@/store/posStore";
import { vendorStore } from "@/store/vendor.js";
import { useUserStore } from "@/store/user.js";
import checkoutCart from "@/components/restaurantCheckOut/cartComponent.vue";
import smFooterComponent from "@/components/smFooterComponent.vue";
import webFooterComponent from "@/components/webFooterComponent.vue";

export default {
  components: {
    "cart-component": checkoutCart,
    "page-loader": PageLoader,
    "nav-bar-vue": NavBar,
    "small-screen-footer": smFooterComponent,
    "web-footer": webFooterComponent,
  },

  created() {
    if (!User.loggedIn()) {
      this.$router.push("/");
    }
    // if(this.cartData.length === 0){
    //     this.$router.push('/');
    // }
  },
  setup() {
    const useVendorStore = vendorStore();
    const usePosStore = posStore();
    const userStore = useUserStore();
    return { usePosStore, useVendorStore, userStore };
  },
  data() {
    return {
      drawer: false,
      cartData: [],
      ItemtotaPrice: "",
      taxRate: "",
      totalPay: "",
      vendorData: {},
      btnloading: false,
      isCardExpanded: true,

      isCardNumberFocused: false,
      isCardExpiryFocused: false,
      isCardCvcFocused: false,
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",

      cardForm: {
        cardNumber: "434343434",
        month: "12",
        year: "2023",
        cvv: "123",
        isActive: null,
      },
      saveCard: false,
      paymentSetting: {},
      posDilogBox: false,
      pageLoader: true,
      stripe: null,
      cardElement: null,
      card: null,
      hoverButton:null
    };
  },
  created() {
    if (this.usePosStore.posItems.length < 1) {
      this.$router.push("/");
    } else {
      this.afterReload();
    }
  },
  async mounted() {
    this.scrollToComponent();
  },
  computed: {
    dynmicFontColor() {
      return {
        color: this.useVendorStore.resturant.main_color,
      };
    },
  },
  methods: {
    formattedPrice(price){
        return VendorHelper.formatCurrency(price);
    },
    scrollToComponent() {
      if (this.$refs.scrollTarget) {
        this.$refs.scrollTarget.$el.scrollIntoView({
          behavior: "smooth", // Use 'auto' for instant scroll
          block: "center", // Scroll to the vertical center of the component
          inline: "center", // Scroll to the horizontal center of the component
        });
      }
    },
    afterReload() {
      this.cartData = this.usePosStore.cartData.posCartData;
      this.ItemtotaPrice =
        this.usePosStore.cartData.totalCulculate.ItemtotaPrice;
      this.taxRate = this.usePosStore.cartData.totalCulculate.taxRate;
      this.totalPay = this.usePosStore.cartData.totalCulculate.totalPay;
      this.vendorData = this.useVendorStore.resturant;
      // this.paymentSetting = this.userStore.payment_setting;
      VendorHelper.updatePageTitle(
        this.useVendorStore.resturant.name + "- Ozpos"
      );
      this.fetchPaymentStatus();
    },
    fetchPaymentStatus() {
      this.pageLoader = true;
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };

      this.$axios
        .get(
          this.useVendorStore.base_url +
            "paymentSettingVueJs/" +
            this.useVendorStore.vender_id,
          { headers }
        )
        .then((res) => {
          console.log("payment-setting api ");
          console.log(res);
          if (res.data.success) {
            this.pageLoader = false;
            this.paymentSetting = {
              cod: res.data.payment_setting[0].cod,
              stripe: res.data.payment_setting[0].stripe,
              stripe_publish_key:
                res.data.payment_setting[0].stripe_publish_key,
              stripe_secret_key: res.data.payment_setting[0].stripe_secret_key,
              stripePercentage:
                res.data.payment_setting[0].frontend_card_charges,
            };
          }
        })
        .catch((error) => {
          console.log("error");
          if (error.response.status === 401) {
            this.userStore.logout();
            this.$router.push("/");
          }
          console.log(error.response);
          this.pageLoader = false;
        });
    },
    handleNewAction(data) {
      this.ItemtotaPrice = data.ItemtotaPrice;
      this.taxRate = data.taxRate;
      this.totalPay = data.totalPay;
      this.afterReload();
    },
    toggleCard() {
      // this.isCardExpanded = !this.isCardExpanded;
      // this.changeAreaBtn(this.cardForm.isActive)
    },
    async changeAreaBtn(status) {
      this.cardForm.isActive = status;
      this.cardForm.cardNumber = null;
      this.cardForm.month = null;
      this.cardForm.year = null;
      this.cardForm.cvv = null;
      this.cardForm.saveCard = false;

      if (status === "STRIPE") {
        this.stripe = await loadStripe(this.paymentSetting.stripe_publish_key);

        const elements = this.stripe.elements();
        const elementStyles = {
          // ... your styles
        };

        const elementClasses = {
          // ... your classes
        };

        this.$nextTick(() => {
          const cardNumber = elements.create("cardNumber", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardNumber.mount("#example2-card-number");

          const cardExpiry = elements.create("cardExpiry", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardExpiry.mount("#example2-card-expiry");

          const cardCvc = elements.create("cardCvc", {
            style: elementStyles,
            classes: elementClasses,
          });
          cardCvc.mount("#example2-card-cvc");
          this.cardElement = cardNumber;
        });
      }
    },

    checkEmpty(value) {},
  },
};

</script>

<style scope>
.card-header {
  padding: 20px 10px;
  cursor: pointer;
}
.expanded-button {
  display: flex;
  justify-content: center;
  width: 100%;
}
.expanded-button button {
  width: 100%;
  padding: 5px;
  /* border: 1px solid #5a616a;
  color: #5a616a; */
}
.expanded-button .login {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.expanded-button .siginup {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.active {
  background-color: #5a616a;
  color: #fff !important;
}
.form-field {
  padding: 30px 0;
}
.form-btn {
  background-color: #d7171c;
  color: #fff;
}
.disable-btn {
  background-color: #fff;
  opacity: 0.2;
  color: #5a616a !important;
}
.cardType {
  display: inline-block;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 17px;
}

.spceing-box{
  height: 100px !important;
}
.sm-footer {
  display: none;
}

@media (max-width: 959.99px) {
  .sm-footer {
    display: block !important;
  }
  /* .spceing-box {
    display: block !important;
    height: 100px !important;
  } */
}

#example2-card-cvc,
#example2-card-expiry,
#example2-card-number {
  padding: 10px;
  /* border:1px solid #555; */
  border-radius: 10px;
  margin-bottom: 10px;
}
@media(min-width:959.99px){
    .web-footer{
        position: absolute !important;
        bottom: 0 !important;
        right: 0;
    }

}
@media(max-width:1254px) and (min-width:960px){
  .scrollable-section{
    max-height: 130px !important;
  }
}
</style>
