<template>
    <div class="tracking-wrapper">
      <v-container fluid>
        <v-row justify="center" v-if="deliveryType === 'DELIVERY'" class="deliveryProgressBar" :class="{'OnPickUpStatus':orderStatus === 'PICKUP'}">
           <v-col cols="2.5" class="outer-progress-bar">
            <div>
                <img class="progress-icon-img" src="/icons/1.png" alt="" srcset="">
                <div class="start-progress-bar">
                  <div class="progress-line"></div>
                  <div class="progress-circle" :class="{'border-color':pendingStatus}">
                    <v-icon>mdi-check</v-icon>
                  </div>
                </div>
                <span>Pending</span>
            </div>
           </v-col>
           <v-col cols="2.5" class="outer-progress-bar">
            <div>
                <img class="progress-icon-img" src="/icons/3.png" alt="" srcset="">
                <div class="progress-bar">
                  <div class="progress-line" :class="{'bg-gree':preparingStatus}">
                  </div>
                  <div class="progress-circle" :class="{'border-color':preparingStatus}">
                    <v-icon v-if="preparingStatus">mdi-check</v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                  </div>
                </div>
                <span>Preparing</span>
            </div>
           </v-col>
           <v-col cols="2.5" class="outer-progress-bar">
            <div>
                <img class="progress-icon-img" src="/icons/5.png" alt="" srcset="">
                <div class="progress-bar">
                  <div class="progress-line" :class="{'bg-gree':readyStatus}"></div>
                  <div class="progress-circle" :class="{'border-color':readyStatus}">
                    <v-icon v-if="readyStatus">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                  </div>
                </div>
                <span>Ready</span>
            </div>
           </v-col>
           <v-col cols="2.5" class="outer-progress-bar">
            <div>
                <img class="progress-icon-img" src="/icons/7.png" alt="" srcset="">
                <div class="progress-bar">
                  <div class="progress-line" :class="{'bg-gree':enrouteStatus}"></div>
                  <div class="progress-circle" :class="{'border-color':enrouteStatus}">
                    <v-icon v-if="enrouteStatus">mdi-check</v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                  </div>
                </div>
                <span>Enroute</span>
            </div>
           </v-col>
           <v-col cols="2.5" class="outer-progress-bar">
             <div>
                <img class="progress-icon-img" src="/icons/9.png" alt="" srcset="">
                 <div class="progress-bar">
                   <div class="progress-line" :class="{'bg-gree':deliveredStatus}"></div>
                   <div class="progress-circle" :class="{'border-color':deliveredStatus}">
                    <v-icon v-if="deliveredStatus">mdi-check</v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                   </div>
                 </div>
                 <span>Delivered</span>
             </div>
           </v-col>
           <!-- <v-col cols="2" class="hidden-column"></v-col> -->
        </v-row>
        <v-row justify="center" v-else  class="tackAwayProgressBar">
           <v-col cols="2" sm="2" class="outer-progress-bar take-Away">
            <div>
                <img class="progress-icon-img" src="/icons/tk2.png" alt="" srcset="">
                <div class="start-progress-bar">
                  <div class="progress-line"></div>
                  <div class="progress-circle" :class="{'border-color':pendingStatus}">
                    <v-icon>mdi-check</v-icon>
                  </div>
                </div>
                <span>Pending</span> 
            </div>
           </v-col>
           <v-col cols="3" sm="2" class="outer-progress-bar take-Away">
            <div>
                <img class="progress-icon-img" src="/icons/3.png" alt="" srcset="">
                <div class="progress-bar">
                  <div class="progress-line" :class="{'bg-gree':preparingStatus}"></div>
                  <div class="progress-circle" :class="{'border-color':preparingStatus}">
                    <v-icon v-if="preparingStatus">mdi-check</v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                  </div>
                </div>
                <span>Preparing</span>
            </div>
           </v-col>
           <v-col cols="3" sm="2" class="outer-progress-bar take-Away">
            <div>
                <img class="progress-icon-img" src="/icons/tk3.png" alt="" srcset="">
                <div class="progress-bar">
                  <div class="progress-line" :class="{'bg-gree':readyStatus}"></div>
                  <div class="progress-circle" :class="{'border-color':readyStatus}">
                    <v-icon v-if="readyStatus">mdi-check</v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                  </div>
                </div>
                <span>Ready</span>
            </div>
           </v-col>
           <v-col cols="3" sm="2" class="outer-progress-bar take-Away">
             <div>
                <img class="progress-icon-img" src="/icons/tk1.png" alt="" srcset="">
                 <div class="progress-bar">
                   <div class="progress-line" :class="{'bg-gree':deliveredStatus}"></div>
                   <div class="progress-circle" :class="{'border-color':deliveredStatus}">
                    <v-icon v-if="deliveredStatus">mdi-check</v-icon>
                      <v-icon v-else>mdi-close</v-icon>
                   </div>
                 </div>
                 <span>Delivered</span>
             </div>
           </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    props:['orderStatus','deliveryType'],
    data() {
      return {
        
      };
    },
    computed:{
      pendingStatus(){
        return true;
      },
      preparingStatus(){
        const arr = ['APPROVE', 'PREPARING FOOD', 'READY TO PICKUP','ACCEPT', 'PICKUP', 'DELIVERED','COMPLETE'];
        return arr.includes(this.orderStatus);
      },
      readyStatus(){
        const arr = ['READY TO PICKUP', 'ACCEPT','PICKUP', 'DELIVERED','COMPLETE'];
        return arr.includes(this.orderStatus);
      },
      enrouteStatus(){
        const arr = [ 'PICKUP', 'DELIVERED','COMPLETE'];
        return arr.includes(this.orderStatus);
      },
      deliveredStatus(){
        const arr = ['DELIVERED','COMPLETE'];  
        return arr.includes(this.orderStatus);
      }
      
    },
    methods: {
     
    },
  };
  </script>
  
  <style>
  /* Add your custom styles here if needed */
  .progress-circle{
    width: 30px;
    height: 30px;
    border: 5px solid #d7171c;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #d7171c;
    padding: 5px;
  }
  .progress-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .progress-bar .progress-line{
    height: 5px;
    background-color: #d7171c;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  .start-progress-bar{
    display: flex;
    justify-content:end;
    align-items: center;
    /* height: 5px; */
    padding: 0;
  }
  .outer-progress-bar{
    padding: 0 ;
    text-align: end;
    position: relative;
    /* display: flex; */
    /* justify-content: end; */
  }
  .progress-icon-img{
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 55px;
    right: -7px;
  }
  .outer-progress-bar span{
    margin-right: -9px;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
  }
  .bg-gree{
    background-color: green !important;
    /* border-top:1px solid #d7171c !important; */
    /* border-bottom:1px solid #d7171c !important; */
  }
  .border-color{
    border:5px solid green !important;
    /* background-color: green !important; */
    color:  green !important;
  }
  .progress-circle .mdi{
    font-size: 20px !important;
  } 
 
  @media(min-width:650px){
    .deliveryProgressBar{
    position: absolute;
    left: -46px;
    width: 70%;
    bottom: 10%;
    }
    .OnPickUpStatus{
      bottom: 20% !important;
    }
  }
  @media(min-width:601px){
    .tackAwayProgressBar{
      position: absolute;
      left: -27%;
      right: 19%;
      bottom: 25px;
     }
  }

  @media(max-width:599px){
    .progress-icon-img{
      display: none !important;
    }
    .hidden-column{
      display: none !important;
    }
   .deliveryProgressBar{
    margin-left: -30px;
    width: 100%;
   }
   .tackAwayProgressBar{
    margin-left: -20px;
    width: 100%;
   }
  }
  @media(max-width:451px){
   
    .saleItemList{
      padding: 5px !important;
    }
    .progress-icon-img{
      width: 35px;
      height: 35px;
      position: absolute;
      /* bottom: 85px; */
      right: 0px;

    }
   
    .outer-progress-bar span {
       margin-right: -3px;
       margin-top: 5px;
       font-size: 10px;
    }

  }
  </style>
  