<template>
    <v-card>
        <v-card-title class="text-h5" :style="dynmicFontColor">Order Placed Successfully</v-card-title>
        <v-card-text>
            <div class="center-item">
                <div class="outer">
                    <div class="red-line" :style="dynmicBgColor"></div>
                    <span>Table no.{{ successResp.tableno }}</span>
                </div>
                <div class="outer">
                    <div class="red-line" :style="dynmicBgColor"></div>
                    <span>Order no.{{ successResp.orderid }}</span>
                </div>
                <div class="outer">
                    <div class="red-line" :style="dynmicBgColor"></div>
                    <span>For Mr.{{ successResp.ordername }}</span>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <div>
                <h5 :style="dynmicFontColor">Enjoy your Meal!</h5>
                <v-btn class="closeBtn" @click="$emit('closeDilog')">Close</v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>
<script>
export default{
   props:['successResp','dynmicBgColor','dynmicBgColor','dynmicFontColor']
}
</script>


<style scoped>
.v-card{
    padding: 20px;
    border-radius: 20px !important;
}
.center-item .outer .red-line{
   width: 15px;
   height: 2px;
   margin-right: 5px;
}
.center-item .outer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.center-item .outer span{
    font-size: 14px;
}
.v-card-title{
    font-size: 20px !important;
    color:  #d7171c;
}
.v-card-actions{
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-align: center;
}
.v-card-actions h5{
    color:  #d7171c;
    font-size: 16px;
}
.v-card-actions .closeBtn{
    background-color:#000 !important;
    color: #fff;
    margin-top: 10px;
    border-radius: 20px;
    padding: 0 15px;
    height: 30px;
}
</style>