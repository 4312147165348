<template>
   <page-loader v-if="isTrue"/>
</template>
<script>
import { useUserStore } from '@/store/user.js' 
import { vendorStore } from '@/store/vendor.js' 
import PageLoader from "@/components/PageLoader.vue";
import { ref, onMounted } from 'vue';
export default{
    components:{
        'page-loader':PageLoader
    },
    setup() {
         const userStore = useUserStore(); 
         const useVendorStore = vendorStore(); 
         return {userStore,useVendorStore};
    },
    data(){
        return{
            isTrue:true,
        }
    },
    mounted(){
        const headers = {
             Authorization: User.BearerToken(),
        };
        const base_url = this.useVendorStore.base_url;
        this.$axios
            .post(base_url + 'onlineLogOut',[],{headers})
            .then((res) => {
               this.logout();
            }).catch((error) => {
                this.logout();
                console.log(error);
            });
    },
    methods:{
        logout(){
            Notification.logout();
            this.userStore.logout();
            this.$router.push('/');
            this.isTrue = false;
        }
    }
}
</script>