// Composables
import { createRouter, createWebHistory } from 'vue-router'
import restaurantComponent from '../views/pages/restaurant/index.vue';
import restaurantCheckOutComponent from '@/views/pages/restaurant/checkout.vue';
import userDashboardComponent from '@/views/pages/user/index.vue';
import userProfileComponent from '@/views/pages/user/profile.vue'
import ordersComponent from '@/views/pages/orderHistory/index.vue'
import orderTakeAwayComponent from '@/views/pages/orderHistory/order_track.vue'
import privacyComponent from '@/views/pages/privacy/index.vue'
import aboutUsComponent from '@/views/pages/aboutUs/index.vue'
import termsConditionComponent from '@/views/pages/termsCondition/index.vue'
import companyDetailsComponent from '@/views/pages/companyDetails/index.vue'
import returnRefundsComponent from '@/views/pages/returnsRefunds/index.vue'
import QrCodeComponent from '@/views/pages/restaurants_QR/index.vue'
import qrcodeCheckoutComponent from '@/views/pages/restaurants_QR/checkOut.vue'
import clearQrCodeOrder from '@/views/pages/restaurants_QR/afterOrder.vue'
import loginComponent from '@/views/pages/auth/login.vue'
import registerComponent from '@/views/pages/auth/register.vue'
import feedbackComponent from '@/views/pages/feedback/index.vue'
import orderStatusComponent from '@/views/pages/orderStatus/index.vue'
import logout from '@/views/pages/logout.vue';
import driverSignUpComponent from '@/views/pages/driver/signup.vue'

const routes = [
  {
    path: '/testing',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      // {
      //   path: '',
      //   name: 'Home',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      // },
      {
        path:'/logout',
        name:'logout',
        component:logout
      },
      {
        path:'/',
        name:'resturant',
        component:restaurantComponent

      },
      {
        path:"/resturant-checkout",
        name:"resturant-checkout",
        component:restaurantCheckOutComponent
        
      },
      {
        path:'/dashboard',
        name:'dashboard',
        component:userDashboardComponent
      },
      {
        path:'/profile',
        name:'profile',
        component:userProfileComponent
      },
      {
        path:'/orders',
        name:'orders',
        component:ordersComponent
      },
      {
        path:'/order-tracking/:lat/:lang/:driverId',
        name:'orderTracking',
        component:orderTakeAwayComponent,
      },
      {
        path:'/qr-code/:table_id',
        name:'qrCode',
        component:QrCodeComponent,
      },
      {
        path:'/qr-code/checkout/:table_id',
        name:'qrCodeCheckout',
        component:qrcodeCheckoutComponent,
      },
      {
        path:'/qr-order-success/:tb_id',
        name:'AfterQrOrderSuccess',
        component:clearQrCodeOrder
      },
      {
        path:'/privacy',
        name:'/privacy',
        component:privacyComponent,
      },
      {
        path:'/about-us',
        name:'aboutUs',
        component:aboutUsComponent
      },
      {
        path:'/terms-condition',
        name:'termsCondition',
        component:termsConditionComponent
      },
      {
        path:'/company-details',
        name:'CompanyDetails',
        component:companyDetailsComponent
      },
      {
        path:'/return-refunds',
        name:'returnRefunds',
        component:returnRefundsComponent
      },
      {
        path:'/login',
        name:'Login',
        component:loginComponent
      },
      {
        path:'/register',
        name:'Register',
        component:registerComponent
      },
      {
        path:'/feedback',
        name:'feedback',
        component:feedbackComponent
      },
      {
        path:'/order-status',
        name:'OrderStatus',
        component:orderStatusComponent
      },  
      {
        path:'/driver-signup',
        name:"DriverSignup",
        component:driverSignUpComponent
      } 
    ],
  },
 

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
