// utils.js

export function toggleConsoleOutput(enable) {
    if (enable) {
      if (typeof console.originalLog === 'function') {
        console.log = console.originalLog;
        console.info = console.originalInfo;
        console.warn = console.originalWarn;
        console.error = console.originalError;
      }
    } else {
      if (typeof console.originalLog !== 'function') {
        console.originalLog = console.log;
        console.originalInfo = console.info;
        console.originalWarn = console.warn;
        console.originalError = console.error;
      }
      console.log = console.info = console.warn = console.error = function() {};
      if (typeof console.clear === 'function') {
        console.clear();
      }
    }
  }
  
