import {vendorStore} from "@/store/vendor.js"
class VendorHelper {
  getStore() {
    return vendorStore();  // Lazily access the store when needed
  }
  isPastSelectedTime(selectedDateTime){
    const selectedDate = new Date(selectedDateTime);
    const currentDate = new Date();
    if (
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      return selectedDate < currentDate;
    }
    return false;
  }


  convertTo24HourFormat(time) {
        const [hours, minutes] = time.split(':');
        let hour = parseInt(hours, 10);
        const isPM = time.toLowerCase().includes('pm');

        if (isPM && hour !== 12) {
          hour += 12;
        } else if (!isPM && hour === 12) {
          hour = 0;
        }

        return new Date().setHours(hour, parseInt(minutes, 10), 0, 0);
    }

    isOpen(currentTime,todayTiming){
      if(todayTiming.status === 1){
         const now = this.convertTo24HourFormat(currentTime);
         const open = this.convertTo24HourFormat(todayTiming.period_list[0].start_time);
         const close = this.convertTo24HourFormat(todayTiming.period_list[todayTiming.period_list.length -1 ].end_time);
         const check = now >= open && now <= close;
         if(check){
             return this.isCurrentTimeInRange(todayTiming.period_list,currentTime)
         }else{
          return false;
         } 
       }
      return false;
    }
    isCurrentTimeInRange(timesArray, currentTimeString) {
      const currentTime = this.convertTo24HourFormat(currentTimeString);
      for (const period of timesArray) {
          const startTime = this.convertTo24HourFormat(period.start_time);
          const endTime = this.convertTo24HourFormat(period.end_time);
          if (currentTime >= startTime && currentTime <= endTime) {
              return true;  
          }
      }
      return false;
   }
  
    
 
   parseTimeString(timeString) {
      const [time, meridiem] = timeString.split(' ');
      const [hours, minutes, seconds] = time.split(/[:]/).map(Number);
      const date = new Date();
      let adjustedHours = (meridiem.toLowerCase() === 'pm' && hours !== 12) ? hours + 12 : hours;
      if (meridiem.toLowerCase() === 'am' && hours === 12) {
        adjustedHours = 0;
      }
      date.setHours(adjustedHours);
      date.setMinutes(minutes || 0);
      date.setSeconds(seconds || 0);
      
      return date;
    }
   
    isVenderOnOrOff(todayTiming,isOpen,vendor_status){
    if(todayTiming.status === 0 || !isOpen || vendor_status === 0){
          return false;
         }else{
          return true;
         }
    }

    setTimeZone(timezone) {
        const now = new Date().toLocaleString('en-US', { timeZone: timezone});
        const [date, time] = now.split(', ');
        const currentDate = new Date(date).toLocaleDateString('en-US', { timeZone: timezone });
        const currentDay = new Date(date).toLocaleString('en-US', { weekday: 'long' });
        return [currentDay,time,currentDate]
      }
      updatePageTitle(title) {
        document.title = title; 
      }

  compareDates(providedDateStr){
        const providedDate = new Date(providedDateStr);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (providedDate > today) {
          console.log("Provided date is in the future.");
           return false;
        } else if (providedDate < today) {
          console.log("Provided date is in the past.");
           return false;
        } else {
          console.log("Provided date is today.");
          return true;
        }
  }
  // formatDateTime
  formatDateTime(array) {
    const [dayOfWeek, timeString, dateString] = array;
    const dateTime = new Date(`${dateString} ${timeString}`);
    return dateTime.toString();
 }

  formatCurrency(price) {
    const store = this.getStore();
    const symbol = store.resturant.currency_symbol;
    const position = store.resturant.currency_symbol_position;
    if (symbol === "none") {
      return price;
    }
    switch (position) {
      case "post":
        return `${price}${symbol}`;
      case "pre":
        return `${symbol} ${price}`;
      default:
        return price;
    }
  }
 isCurrentTimeBefore(targetTime) {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const [targetHours, targetMinutes] = targetTime;
    if (currentHours < targetHours) {
      return true; 
    } else if (currentHours === targetHours) {
      return currentMinutes < targetMinutes;  
    } else {
      return false; 
    }
  }
  generateUniqueId() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < 9; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}
 storeAppVersion(){
  const store = this.getStore();
  console.log('store data asdas');
  console.log(store.version);
  localStorage.setItem('app_version',store.version);
 }
      
 getAppVersionFromStorage(){
    const app_version = localStorage.getItem('app_version');
    return app_version ? app_version : '1.0.0';
 }
 removeCatche(){
  localStorage.removeItem('vendor');
  localStorage.removeItem('posItems');
  localStorage.removeItem('cartData');
  localStorage.removeItem('free_delivery_amount');
  localStorage.removeItem('deliveryChargeData');
  localStorage.removeItem('deliveryCahrge');
  localStorage.removeItem('deliveryType');
  localStorage.removeItem('dateTimeValue');
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  localStorage.removeItem('payment_setting');
 }


  convertTo12Hour(time24) {
    let [hours, minutes, seconds] = time24.split(":");
    hours = parseInt(hours);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

}
export default VendorHelper = new VendorHelper();


