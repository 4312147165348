<template>
  <v-form @submit.prevent="submitForm" style="text-align: initial">
    <transition name="fade">
      <v-alert
        v-if="validationErrors"
        density="compact"
        type="error"
        :text="validationErrors"
        :value="alert"
        color="pink"
        class="custom-alert"
      ></v-alert>
    </transition>
    <v-text-field
      v-model="old_password"
      :append-icon-cb="toggleShowOldPassword"
      :rules="oldPasswordRules"
      label="Old Password"
      :type="showOldPassword ? 'text' : 'password'"
      variant="outlined"
      required
      style="margin-bottom: 15px; margin-top: 20px"
    >
      <template v-slot:append-inner>
        <v-icon
          :color="vendorStore.resturant.main_color"
          @click="showOldPassword = !showOldPassword"
          >{{ showOldPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon
        >
      </template>
    </v-text-field>

    <v-text-field
      v-model="password"
      :append-icon-cb="toggleShowPassword"
      :rules="passwordRules"
      label="Password"
      :type="showPassword ? 'text' : 'password'"
      variant="outlined"
      style="margin-bottom: 15px"
      required
    >
      <template v-slot:append-inner>
        <v-icon
          :color="vendorStore.resturant.main_color"
          @click="toggleShowPassword"
          >{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon
        >
      </template>
    </v-text-field>
    <v-text-field
      v-model="confirmPassword"
      :rules="confirmPasswordRules"
      label="Confirm Password"
      variant="outlined"
      :type="showConfirPassword ? 'text' : 'password'"
      style="margin-bottom: 15px"
      required
    >
      <template v-slot:append-inner>
        <v-icon
          :color="vendorStore.resturant.main_color"
          @click="showConfirPassword = !showConfirPassword"
          >{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon
        >
      </template>
    </v-text-field>
    <v-btn type="submit" :style="bgColor" :disabled="btnloading">
      <template v-if="btnloading">
        <span>Loading...</span>
        <v-progress-circular
          indeterminate
          size="24"
          color="white"
          class="ml-2"
        ></v-progress-circular>
      </template>
      <template v-else> Save </template>
    </v-btn>
  </v-form>
</template>

<script>
import User from "@/helper/User";
import { useUserStore } from "@/store/user.js";
export default {
  props: ["user", "vendorStore", "bgColor"],
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      old_password: null,
      password: null,
      confirmPassword: null,
      oldPasswordRules: [(v) => !!v || "Old Password is required"],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must be at least 6 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Confirm Password is required",
        (v) => v === this.password || "Passwords do not match",
      ],
      validationErrors: null,
      confirmPasswordIcon: "mdi-lock-check",
      showOldPassword: false,
      showPassword: false,
      showConfirPassword: false,
      accessToken: localStorage.getItem("access_token"),
      btnloading: false,
    };
  },
  computed: {
    checkValidate() {
      return !this.old_password || !this.password || !this.confirmPassword;
    },
  },
  methods: {
    submitForm() {
      if (!this.checkValidate) {
        this.btnloading = true;
        const obj = {
          id: this.user.id,
          password: this.password,
          old_password: this.old_password,
        };
        const base_url = this.vendorStore.base_url;
        const headers = {
          Authorization: User.BearerToken(),
          Accept: "application/json",
        };

        this.$axios
          .post(base_url + "userchangepassword", obj, { headers })
          .then((res) => {
            if (res.data.success) {
              Notification.success();
            } else {
              console.log(res.data.data);
              this.validationErrors = res.data.data;
              setTimeout(() => {
                this.validationErrors = null;
              }, 2000);
            }
            this.btnloading = false;
          })
          .catch((error) => {
            console.log("error");
            console.log(error);
            this.btnloading = false;
            if (error.response.status === 401) {
              this.userStore.logout();
              this.$router.push("/");
            }
          });
      }
    },
    toggleShowOldPassword() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
