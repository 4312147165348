<template>
 <!-- <div class="background-dark">
      
    </div> -->
  <v-card class="selection-card" :height="fixCardHeight" :style="{'overflow-y':selectedBtn !=='delivery' ? 'hidden' : ''}">
       <h3 style="width:100%;text-align:center" >Select Pickup or Delivery</h3>
      <div class="btn-selection">
           <v-btn :style="{
            'background-color':selectedBtn === 'pickup' || hoverButton === 'pickup' && vendorData.pickup_status ===1 ? mainColor : '#fff',
            'color': selectedBtn === 'pickup' || hoverButton === 'pickup' && vendorData.pickup_status ===1 ? themColorWithFont.color : '#000000'
           }"
            :disabled="vendorData.pickup_status ===0"
            @mouseover="hoverButton = 'pickup'"
            @mouseleave="hoverButton = null"
            class="pickupBtn" @click="selection('pickup')">Pickup</v-btn>
           <v-btn :style="{
            'background-color':selectedBtn === 'delivery' || hoverButton === 'delivery' && vendorData.delivery_status ===1 ? mainColor : '#fff',
            'color': selectedBtn === 'delivery' || hoverButton === 'delivery' && vendorData.delivery_status ===1 ? themColorWithFont.color : '#000000'
           }" 
           :disabled="vendorData.delivery_status ===0"
            @mouseover="hoverButton = 'delivery'"
            @mouseleave="hoverButton = null"
           class="deliveryBtn" @click="selection('delivery')">Delivery</v-btn>
      </div>
        <div v-if="selectedBtn==='delivery'" class="location-check">
          <div style="display: flex;margin-bottom:20px"  >
            <vue-google-autocomplete
              ref="autocomplete"
              id="autocomplete"
              :country="vendorLocationsInMap"
              class="locationSeach autocompleteBox"
              :style="{border: '1px solid ' + mainColor}"
              v-model="textAddress"
              placeholder="Enter your address"
              v-on:placechanged="getAddressData"
              @input="handleInput"
            ></vue-google-autocomplete>
          <v-btn
              :disabled="!textAddress || !isTypeAddress"
              :style="themColorWithFont"
              @click="checkAddress"
              :loading="checkLoader"
              >Check</v-btn
            >
          </div>
        <transition name="fade">
            <v-alert
                @click="$emit('clicked-alert')"
                v-if="addressAlert"
                density="compact"
                type="error"
                text="Apologies, no delivery available.Self-pickup only."
                :value="alert"
                color="pink"
                class="custom-alert"
            ></v-alert>
        </transition>
        </div>
       <!-- <v-card-actions>
          <v-btn :style="themColorWithFont" :disabled="isDisabledSaveBtn" @click="saveSelection">
            Next<v-icon size="24">mdi-arrow-right</v-icon>
           </v-btn>
       </v-card-actions>      -->
  </v-card>
  
</template>

<script>
import { el } from 'date-fns/locale';
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
    props:['vendorLocationsInMap','mainColor','themColorWithFont','checkLoader','isValidAddress','addressAlert','vendorData'],
    components:{
        VueGoogleAutocomplete
    },
  data() {
    return {
       selectedBtn:"",
       textAddress:"Airport Drive, Brisbane Airport QLD, Australia",
       address: "",
       lang: "",
       lat: "",
       picker:null,
       address_txt:null,
       isTypeAddress:true,
       hoverButton:null,

    };
  },
  mounted(){
      this.afterReload();
  },
  computed:{
      fixCardHeight() {
          if (this.selectedBtn === 'delivery') {
            return this.addressAlert ? '300' : '250';
          }
          return '150';
      },
      dialogHeight(){
      // if (this.$refs.pickup_delivery_ref && this.pickupDeliveryModal) {
      //   console.log(this.$refs.pickup_delivery_ref)
      //   return this.$refs.pickup_delivery_ref.selectedBtn == 'delivery' ? '250' : '150';
      // }
      // return '150';
    },
  },
  methods: {
    afterReload(){
      const urlParams = new URLSearchParams(window.location.search);
          const deliveryType = urlParams.get('delivery_type');
          const address = urlParams.get('address');
          if (deliveryType === 'delivery') {
            this.selectedBtn = 'delivery';
            this.lat = urlParams.get('lat');
            this.lang = urlParams.get('lng');
            this.textAddress = address;
            this.address_txt =address;
            setTimeout(
              function () {
                      const location = document.querySelector(".autocompleteBox");
                      if (location) {
                          location.value = address;
                      }
                  }.bind(this),
                  200
              );
          }
    },
      selection(type){
         this.selectedBtn= type;
         if(this.selectedBtn === 'pickup'){
            this.textAddress = null;
            this.address_txt =null;
            this.address="";
            this.lang ="";
            this.lat="";
            this.$emit("after-selection",this.selectedBtn);
         }
      },
      saveSelection(){
          this.$emit("after-address-success");
      },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.lang = this.address.longitude;
      this.lat = this.address.latitude;
      this.isTypeAddress =true;
         setTimeout(
              function () {
                const location = document.querySelector(".autocompleteBox");
                if (!location.value) {
                    location.value = placeResultData.formatted_address;
                }
                }.bind(this),
                  200
              );
      this.address_txt =
        this.address.route +
        "," +
        this.address.locality +
        "," +
        this.address.country;
    },
    checkAddress(){
           const obj ={
                selectedArea:{
                    lang:this.lang,
                    lat:this.lat,
                    fullAddress:this.address_txt,
                    completeRes:this.address
                }, 
                fullAddress:this.address.route,
                locationDevice:null,
            }
           
        this.$emit("select-area",obj);
        
    },
    handleInput(){
      this.isTypeAddress =false;
      // alert("Sfdsdf");
    }
  }
};
</script>

<style scoped>
.selection-card{
  padding: 20px;
  background-color: #5a616a;
  opacity: 0.9;
  color: #fff;
}
.btn-selection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
  .btn-selection .v-btn{
    width: 50% !important;
    border: none;
    border-radius:0 ;
    height: 50px;

   }
   .active{

   }

.location-check{
    padding: 15px 20px;
}
.location-check .v-btn{
      height: 40px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
     
}
.locationSeach {
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.locationSeach:focus {
  border: 1px solid #d7171c;
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pickupBtn{
    border-top-left-radius:15px !important;
    border-bottom-left-radius:15px !important;
}
.deliveryBtn{
    border-top-right-radius:15px !important;
    border-bottom-right-radius:15px !important;
}
.v-card{
    border-radius:20px !important ;
}
.autocompleteBox{
  background-color: #fff;

}
.disable-btn{
  background-color: #fff !important ;
}
/* Scoped styles */
</style>
