import { defineStore } from 'pinia'
export const posStore = defineStore('pos', {
  state: () => ({
     items:null,
     items_sizes_addon:[],
     cartData:JSON.parse(localStorage.getItem('qr_cartData')) ||[],
     posItems:JSON.parse(localStorage.getItem('qr_posItems')) ||[],
  }),
  actions: {
    async posStorage(items,items_sizes_addon) {
         this.items=items;
         this.items_sizes_addon = items_sizes_addon;
        
         
    },
    async PosItemStore(posdata){
      localStorage.setItem('qr_posItems',JSON.stringify(posdata)); 
    },
    async checkOutStorage(posCartData , totalCulculate){
        const obj = {
          posCartData:posCartData,
          totalCulculate:totalCulculate,
        }

        localStorage.setItem('qr_cartData',JSON.stringify(obj));
        localStorage.setItem('qr_posItems',JSON.stringify(posCartData));
        this.cartData = obj;

    },
    async getTbaleId(tb_Id){
       localStorage.setItem('tb_id',tb_Id);
    },
    async fleshOrders(){
      localStorage.removeItem('qr_cartData');
      localStorage.removeItem('qr_posItems');
      this.cartData =[];
      this.posItems =[];
    }
  
  }
});