<template>
    <h1>Select a Date and Time</h1>
  <div class="pickers" :class="{'marginBottom':true}">
    <input type="text" readonly :style="{'border':'1px solid '+themColor.color}" id="datePicker" placeholder="Select a date" ref="datePicker">
    <!-- <input @click="openDatePicker" :style="{'border':'1px solid '+themColor.color}" type="text" id="timePicker" placeholder="Select a time" v-if="type=='pickup'" ref="timePicker" disabled> -->
    <select v-if="type=='delivery' && vendorData.is_tiffin_service === 1" v-model="deliverTime" :style="{'border':'1px solid '+themColor.color}" id="" @change="selectDeliveryTime" >
      <option value="" selected disabled>Please Select a Time</option>
      <option  v-for="time in period_list" :key="time" :value="time.start_time" :disabled="checkValidTime(time)">{{time.start_time+' - '+time.end_time}} </option>
    </select>
     <div  v-else @click="openDatePicker" style="height:0;cursor: pointer;">
      <input
        type="text"
        id="timePicker"
        placeholder="Select a time"
        ref="timePicker"
        disabled
        :style="{'border':'1px solid '+themColor.color, 'display': 'inline-block'}"
      >
   </div>
  </div>
</template>

<script>


export default {
  name: 'DateTimePicker',
  props: ['workingDays', 'themColor','type','vendorData','isVendorOnOff'],
  data() {
    return {
      timePickerDisabled: true,
      date: '',
      showTimeSelect: false,
      selectedTime: '',
      period_list: [],
      inTime: [],
      outTime: [],
      disableTime: [],
      deliverTime:"",
    };
  },
  computed:{
    // Mon Aug 19 2024 21:40:16 GMT+0500 (Pakistan Standard Time)
    currentDateTime(){
        return this.setTimeZone(this.vendorData.timezone);
    }
  },
  mounted() {
    const today = this.currentDateTime;
    this.loadCDNs([
      { src: 'https://code.jquery.com/jquery-3.6.0.min.js', callback: this.loadPickadate },
      { src: 'https://cdn.jsdelivr.net/npm/pikaday/pikaday.js' }
      // { src: 'https://cdn.jsdelivr.net/npm/pikaday/pikaday.js', callback: this.initializePickers.bind(this, today) }
    ]);
    this.getDisablesTimes();
  },
  methods: {
    setTimeZone(timezone) {
      
        const now = new Date().toLocaleString('en-US', { timeZone: timezone});
        const [date, time] = now.split(', ');
        const currentDate = new Date(date).toLocaleDateString('en-US', { timeZone: timezone });
        const currentDay = new Date(date).toLocaleString('en-US', { weekday: 'long' });
        const dateTime = new Date(`${currentDate} ${time}`);
        // return [currentDay,time,currentDate]
        return dateTime;
      },
      checkValidTime(timesPeriod) {
    const date = $(this.$refs.datePicker).val();
    const specificDate = new Date(this.convertDateTimeToISO({ hours: 12, minutes: 0 }, date));

    const currentTime = this.currentDateTime;
    const specificDateWithoutTime = new Date(specificDate.getFullYear(), specificDate.getMonth(), specificDate.getDate());
    const todayWithoutTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());

    if (specificDateWithoutTime.getTime() !== todayWithoutTime.getTime()) {
      return false;
    }

    const parseTime = (timeString) => {
      if (timeString) {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (period.toLowerCase() === 'pm' && hours < 12) hours += 12;
        if (period.toLowerCase() === 'am' && hours === 12) hours = 0;
        return { hours, minutes };
      } else {
        return { hours: 0, minutes: 0 }; 
      }
    };

    const { hours: startHours, minutes: startMinutes } = parseTime(timesPeriod.start_time);
    const { hours: endHours, minutes: endMinutes } = parseTime(timesPeriod.end_time);

    const startTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), startHours, startMinutes);
    const endTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), endHours, endMinutes);
    return currentTime > endTime;
  },
    selectDeliveryTime(){
      // alert(this.deliverTime);
      const obj= {
          'time':this.convertTimeTo24Hr(this.deliverTime),
          'date':$(this.$refs.datePicker).val(),
          'fullDateTime':this.convertDateTimeToISO(this.convertTimeTo24Hr(this.deliverTime),$(this.$refs.datePicker).val())
        }
      // console.log(obj)
      this.$emit('selected-time-date',obj)
    },
    getDayName(dayIndex) {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[dayIndex];
    },
    loadCDN(src, callback) {
      const script = document.createElement('script');
      script.src = src;
      script.onload = callback || (() => {});
      document.head.appendChild(script);
    },
    loadCDNs(scripts) {
      scripts.forEach(({ src, callback }) => this.loadCDN(src, callback));
    },
    loadPickadate() {
       const today = this.currentDateTime;
       
        const nextDay = new Date(today);
        
        if(this.vendorData.is_tiffin_service === 1 ){
           nextDay.setDate(today.getDate() + 1);
        }
        console.log(this.currentDateTime)
        for (let i = 0; i < 6; i++) {
            const dayName = this.getDayName(nextDay.getDay());
            
            const todayPeriodList = this.DayPeriodList(dayName);
            if(this.checkDayStatus(dayName) === 0 || (this.checkTodayTimeIsOver(todayPeriodList) && i === 0)) {
              console.log(this.checkTodayTimeIsOver(todayPeriodList) )
                nextDay.setDate(nextDay.getDate() + 1);
                //  break;
            } else {
                break;
            }
        }
        console.log(nextDay)
      this.loadCDN('https://cdnjs.cloudflare.com/ajax/libs/pickadate.js/3.6.4/picker.js', () => {
        this.loadCDN('https://cdnjs.cloudflare.com/ajax/libs/pickadate.js/3.6.4/picker.time.js', () => {
          this.initializePickers(nextDay);
        });
      });
    },
    initializePickers(today) {
      console.log(today);
      const dayName = this.getDayName(today.getDay());
      const periodList = this.DayPeriodList(dayName);
      this.period_list = periodList;
      const intervelTime = this.getIntervelTime(dayName);
      console.log(intervelTime);
      let defaultTimePickerOptions = this.getTimePickerOptions(today,today.getDay(), periodList,intervelTime);
      
     
      const picker = new Pikaday({
        field: this.$refs.datePicker,
        minDate: today,
        disableDayFn: (date) => {
          console.log(date)
          const dayOfWeek = date.getDay();
          const isFutureDate = date >= today;
          const dayName = this.getDayName(dayOfWeek);
          const workDay = this.workingDays.find(day => day.day_index === dayName);
          const todayName = this.getDayName(today.getDay());
          const todayWorkDay = this.workingDays.find(day => day.day_index === todayName);
          // this.period_list = todayWorkDay.period_list;
          // console.log(todayWorkDay.period_list);
        //  if (this.vendorData.is_tiffin_service === 1 && date.toDateString() === today.toDateString()) {
        //       return true; 
        //   }
        
          if (date.toDateString() === today.toDateString() && todayWorkDay.status === 1) {

            if(this.vendorData.is_tiffin_service === 0 && VendorHelper.compareDates(today)){
              console.log(this.checkTodayTimeIsOver(todayWorkDay.period_list))
              return this.checkTodayTimeIsOver(todayWorkDay.period_list);
            }else{
              return false;
            }
          }
          return !isFutureDate || !workDay || workDay.status === 0;
        },
        onSelect: (date) => {
          this.period_list = [];
          this.$emit('change-date');
          this.selectedTime=  '';
          const dayOfWeek = date.getDay();
          const dayName = this.getDayName(dayOfWeek);
          const periodList = this.DayPeriodList(dayName);
          const intervelTime = this.getIntervelTime(dayName);
      console.log(intervelTime);
          // console.log(dayName);
          this.period_list = periodList;
          if(this.type == 'pickup' || this.vendorData.is_tiffin_service === 0){
            this.$refs.timePicker.disabled = false;
              this.$refs.timePicker.value = '';
              this.selectedTime = null;
              const timePickerOptions = this.getTimePickerOptions(date,dayOfWeek, periodList,intervelTime);
              this.destroyTimePicker();
            setTimeout(() => {
                this.initializeTimePicker(timePickerOptions);
          }, 100);
          }
     
        }
      });

      picker.setDate(today);
      this.initializeTimePicker(defaultTimePickerOptions);
    },
    initializeTimePicker(timePickerOptions) {
      const self = this; 
      const $timePicker = $(this.$refs.timePicker).pickatime({
        ...timePickerOptions,
      
      onClose: () => {
          this.$emit('time-picker-show-hide', 'isClose'); 
        }
      });

      $timePicker.pickatime(timePickerOptions);

      $timePicker.on('change', () => {
        this.$emit('selected-time-date', {
          'time': this.convertTimeTo24Hr($timePicker.val()),
          'date': $(this.$refs.datePicker).val(),
          'fullDateTime': this.convertDateTimeToISO(this.convertTimeTo24Hr($timePicker.val()), $(this.$refs.datePicker).val())
        });
      });
    },

   checkTodayTimeIsOver(period_list){
    
      const now = this.currentDateTime;
      const endTime = period_list[period_list.length - 1];
      const endTime24h = this.convertTimeTo24Hr(endTime.end_time);
      let endHour24 = parseInt(endTime24h.hours, 10);
     const endMinute = parseInt(endTime24h.minutes, 10);

     const endTimeDate = new Date();
     endTimeDate.setHours(endHour24, endMinute, 0, 0);
     console.log(now)
     console.log(endTime)
      if (now.getTime() > endTimeDate.getTime()) {
        console.log("time is over")
        return true;
      } else {
         console.log(endTimeDate)
         console.log(now)
        return false;
      }
   },
    DayPeriodList(day) {
      const dayPeriod = this.workingDays.find(d => d.day_index === day);
      return dayPeriod ? dayPeriod.period_list : [];
    },
    getIntervelTime(day){
       const dayPeriod = this.workingDays.find(d => d.day_index === day);
      return dayPeriod ? dayPeriod.time_interval : 5;
    },
    checkDayStatus(day) {
      const obj = this.workingDays.find(d => d.day_index === day);
      return obj.status;
    },
    convertTimeTo24Hr(timeStr) {
      const [time, period] = timeStr.split(' ');
      const [hoursStr, minutesStr] = time.split(':');
      let hours = parseInt(hoursStr);
      const minutes = parseInt(minutesStr);
      if (period.toLowerCase() === 'pm' && hours < 12) {
        hours += 12;
      } else if (period.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
      return {
        hours: hours,
        minutes: minutes
      };
    },
    getTimePickerOptions(date,dayOfWeek, periodList,intervelTime) {
      console.log(intervelTime)
      const inTime = this.convertTimeTo24Hr(periodList[0].start_time);
      const outTime = this.convertTimeTo24Hr(periodList[periodList.length - 1].end_time);
      const minArr = [inTime.hours, inTime.minutes];
      const maxArr = [outTime.hours, outTime.minutes];
      const daySpecificDisable = this.disableTime;


      // const disable = [{from:[15,30],to:[22,50]}];
      const disable = daySpecificDisable[dayOfWeek] || [];
      
     const options = {
        interval: intervelTime,
        min: minArr,
        max: maxArr,
        disable: disable,
        format: 'h:i A'
      };
      if (this.isToday(date)) {
        const now = this.currentDateTime;
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
        options.disable = [];
        if(disable.length > 0){
          const [targetHours, targetMinutes] = minArr;
          if (currentHours < targetHours) {
            options.min = minArr;
          } else if (currentHours === targetHours) {  
            options.min = [currentHours, currentMinutes]
          } else {
                options.min = [currentHours, currentMinutes]
          }
          options.disable = disable;
           
         }else{
            options.disable.push({
            from: [0, 0],
            to: [currentHours, currentMinutes]
          });
         }
      }else{
        options.disable = [];
        options.interval = intervelTime;
        options.disable = disable;
        options.min = minArr;
        options.max = maxArr;
      }

      return options;
    },
    getOffTimes(times) {
      times.sort((a, b) => this.convertTimeTo24Hr(a.start_time) - this.convertTimeTo24Hr(b.start_time));
      const offTimes = [];
      for (let i = 0; i < times.length - 1; i++) {
        const currentEndTime = times[i].end_time;
        const nextStartTime = times[i + 1].start_time;
        offTimes.push({
          start: this.convertTimeTo24Hr(currentEndTime),
          end: this.convertTimeTo24Hr(nextStartTime)
        });
      }
      return offTimes;
    },
    getDisablesTimes() {
      var daysTimes = [];
      this.workingDays.forEach(day => {
        var disableTimesArr = [];
        const breakTimes = this.getOffTimes(day.period_list);
        breakTimes.forEach(time => {
          const from = [];
          const to = [];
          from[0] = time.start.hours;
          from[1] = time.start.minutes;
          to[0] = time.end.hours;
          to[1] = time.end.minutes;

          disableTimesArr.push({
            from: from,
            to: to
          });
          // console.log();
        });
        // if(disableTimesArr.length > 0){
          daysTimes.push(disableTimesArr);
        // }
     
      

      });
       console.log("breakTimes")
      console.log(daysTimes)
      
      this.disableTime = daysTimes;
      //  console.log(daysTimes);
    },
    destroyTimePicker() {
      const picker = $(this.$refs.timePicker).pickatime('picker');
      if (picker) {
        picker.stop();
        picker.$node.off();
        picker.$root.remove();
        console.log('Time picker destroyed');
      }
    },
    openDatePicker(){
      this.$emit('time-picker-show-hide','isOpen')
    },
  isToday(date) {
    const today = this.currentDateTime;
    return date.getDate() === today.getDate() && 
        date.getMonth() === today.getMonth() && 
        date.getFullYear() === today.getFullYear();
  },
  convertDateTimeToISO(timeObj, dateStr) {
   
      const dateParts = dateStr.split(' ');
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const day = parseInt(dateParts[2], 10);
      const month = monthNames.indexOf(dateParts[1]);
      const year = parseInt(dateParts[3], 10);
      const date = new Date(Date.UTC(year, month, day, timeObj.hours, timeObj.minutes));
      const isoDate = date.toISOString().split('.')[0] + "Z";
      const formattedDate = isoDate.slice(0, 16);

      // console.log(formattedDate);
      return formattedDate;
      }
  }
};
</script>

<style scoped>
@import url('https://cdn.jsdelivr.net/npm/pikaday/css/pikaday.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/pickadate.js/3.6.4/themes/default.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/pickadate.js/3.6.4/themes/default.time.css');

#datePicker, #timePicker {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}
 h1{
   font-size: 15px !important;
}
.picker{
  z-index: 0 !important;
  position: unset !important;
}
.pickers{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.pickers input,.pickers select{
  height: 30px;
  width: 160px !important;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 5px;
}
.marginBottom{
  margin-bottom:10px ;

}
.picker__list-item--disabled{
  /* display: none !important; */
}
</style>
