<template>
   
    <v-card :loading="isLoading">
     <div class="card-header">
             <div class="product-area">
                 <img :src="singItem.img" class="item-img"/>
                 <div class="product-info">
                     <h3 :style="{'color':useVendorStore.resturant.main_color}">{{singItem.name}}</h3>
                     <p>{{singItem.description}}</p>
                     <p v-if="singItem.price" :style="{'color':useVendorStore.resturant.main_color}"><strong>Price :</strong>{{formattedPrice(singItem.dining_price)}}</p>
                 </div>
             </div>
               <v-btn icon @click="$emit('close')" class="crosBtn">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
     </div>
       
 
    <v-card-text  class="addonsListArea" style="padding:15px 20px 0px ">
     <div v-if="singItem.sizes.length > 0">
       <p  class="sizes-label" v-if="!checkSizesSelected && !isProductSizeGram">Please Select a Size</p>
       <v-row >
           <v-col style="padding:6px" v-if="!isProductSizeGram" v-for="(card,index) in singItem.sizes" :key="card.id" cols="4" sm="4" md="2">
             <v-card
               :style ="{
                'backgroundColor':card.selected ? useVendorStore.resturant.main_color: '#fff',
                'color':card.selected ? useVendorStore.resturant.font_color : useVendorStore.resturant.main_color,
                'border' : '1px solid '+useVendorStore.resturant.main_color
               }"
               @click="sizezCardSelection(card,index)"
             >
               <v-card-text class="sizeNamePrice"><strong>{{ card.item_size.name }}</strong> <strong>{{ formattedPrice(card.size_dining_price) }} </strong></v-card-text>
               
             </v-card>
           </v-col>
          </v-row>
     </div>
     <!-- sizes adons -->
      <!-- addon -->
      <div v-if="singItem.sizes.length > 0" v-for="(group,index) in sizes_addons" class="addonGroup" :key="index"> 
           <v-card-text class="addOn-group-name">
             <h3 style="text-align: initial;">{{ group.name }}</h3>
           <p :class="{'textRed':!checkGroupMinMax(index,'sizeGroupAddon','checkAlert')}" style="text-align: initial;">Please choose minimum of {{ group.min }} and maximum of {{group.max }}.</p>
         </v-card-text>
         <v-row >
           <v-col  cols="12" sm="6" md="6" lg="6" xs="12" v-for="(adon,addonIndex) in group.addon" :key="adon.id"  class="checkBoxArea">
             <v-checkbox
             v-model="adon.addon.is_checked"
             :color="useVendorStore.resturant.main_color"
             @click="handleCheckboxClick(index,addonIndex,'sizeGroupAddon',adon.addon.is_checked)"
             >
             <template v-slot:label> 
              <div class="checkBox-lable">
                <div class="addonList">
                  <span class="addon-label">{{ adon.addon.name }}</span> 
                  <span class="price-text">{{ formattedPrice((adon.addon_dining_price * adon.qty).toFixed(2))}}</span>
                </div>
                <div style="margin-left:10px" v-if="adon.addon.is_checked && adon.addon_dining_price > 0">
                  <v-btn :style="dynmicBgColor" class="addon-qty-btn" icon @click="addDecrement(index,addonIndex,'sizeGroupAddon')">
                   <v-icon>mdi-minus</v-icon>
                   </v-btn>
                   <input class="addon-count-box" :value="adon.qty" readonly :disabled="true"/>
                   <v-btn :style="dynmicBgColor" class="addon-qty-btn"  icon @click="addonIncrement(index,addonIndex,'sizeGroupAddon')">
                   <v-icon>mdi-plus</v-icon> 
                   </v-btn>
               </div>
              </div> 
           </template>
           </v-checkbox>
           </v-col>
         </v-row>
         </div>
       <!--end addon -->
     <!--end sizes adons -->
       <!-- addon -->
        <div v-else v-for="(group,index) in singItem.addon" class="addonGroup" :key="group.id">
           <v-card-text class="addOn-group-name">
             <h3 style="text-align: initial;">{{ group.adon_group.addon_category.name }}</h3>
           <p :class="{'textRed':!checkGroupMinMax(index,'isGroupAddone','checkAlert')}" style="text-align: initial;">Please choose minimum of {{ group.adon_group.addon_category.min }} and maximum of {{group.adon_group.addon_category.max }}.</p>
         </v-card-text>
         <v-row >
           <v-col  cols="12" sm="4" md="4" lg="4" xs="12" v-for="(adon,addonIndex) in group.addon" :key="adon.id" class="checkBoxArea">
             <v-checkbox
             v-model="adon.addon.is_checked"
             :color="useVendorStore.resturant.main_color"
             @click="handleCheckboxClick(index,addonIndex,'isGroupAddone',adon.addon.is_checked)"
             >
             <template v-slot:label>  
              <div class="checkBox-lable">
                <div class="addonList">
                  <span class="addon-label">{{ adon.addon.name }}</span> 
                  <span class="price-text">{{ formattedPrice(adon.addon_dining_price * adon.qty )}}</span>
                </div>
                <div style="margin-left:10px" v-if="adon.addon.is_checked && adon.addon_dining_price > 0">
                  <v-btn :style="dynmicBgColor" class="addon-qty-btn" icon @click="addDecrement(index,addonIndex,'isGroupAddone')">
                   <v-icon>mdi-minus</v-icon>
                   </v-btn>
                   <input class="addon-count-box" :value="adon.qty" readonly :disabled="true"/>
                   <v-btn :style="dynmicBgColor" class="addon-qty-btn"  icon @click="addonIncrement(index,addonIndex,'isGroupAddone')">
                   <v-icon>mdi-plus</v-icon> 
                   </v-btn>
               </div>
              </div>
           </template>
           </v-checkbox>
           </v-col>
         </v-row>
         </div>
       <!--end addon -->
       <v-divider class="divider-border"></v-divider>
    <div class="item-qty-section">
       <div class="left-qty" >
        <div v-if="isProductSizeGram">
          <span > Add Grames</span>
          <input :style ="{
                'border' : '1px solid '+useVendorStore.resturant.main_color
               }" type="text" placeholder="Type Gram.." min="0.1" v-model="singItem.grameSize"  class="gram-count-box" name="myInput">
          <span>{{  grameTotalPrice }} AUD</span>
        </div>
        <strong v-else>QUANTITY</strong>
       </div>
       <div class="right-qty">
         <v-btn class="qty-btn" icon @click="decrement" :style="dynmicBgColor">
             <v-icon>mdi-minus</v-icon>
             </v-btn>
             <input type="text" v-model="singItem.qty" readonly class="count-box" name="myInput"  
             :style ="{
                'border' : '1px solid '+useVendorStore.resturant.main_color
               }"
               >
             <v-btn class="qty-btn"  icon @click="increment" :style="dynmicBgColor">
             <v-icon>mdi-plus</v-icon> 
         </v-btn>
       </div>
    </div>
   </v-card-text>
 
   <v-card-actions>
     <v-btn class="modal-btn" @click="$emit('close')" text >Cancel</v-btn>
     <v-btn style="margin-left:0" class="modal-btn" :style="dynmicBgColor" :disabled="!checkAddonSelection" @click="addToCart" :loading="isLoading">Add To Cart</v-btn> 
     <!-- <v-btn class="modal-btn them-bg-color" :disabled="!checkAdonSelected">Add To Cart</v-btn>  -->
   </v-card-actions>
 </v-card>
    
   </template>
   <script>
   import { posStore } from '@/store/qrCodePosStore.js'  
   import { vendorStore } from "@/store/vendor.js";
     export default {
         props:['singItem','dynmicBgColor','vendorData','formattedPrice'],
         setup() {
          const usePOSStore = posStore();    
          const useVendorStore = vendorStore();
           return { usePOSStore,useVendorStore};
           },
       data () {
         return {
           dialogm1: '',
           incrementAudio:"audio/increment.mp3",    
           sizes_addons:"" ,
           isLoading:false,  
           selectedSizeIndex:'',     
         }
       },
       computed:{
        checkAddonSelection(){
          var breakLoop = true;
          if(this.singItem.sizes.length > 0){
            if(this.sizes_addons.length > 0){
              this.sizes_addons.forEach(item =>{
                if(breakLoop){
                  var count = 0;
                  item.addon.forEach(addon => {
                    if(addon.addon.is_checked){
                      count++;
                    }
                  })
                  if(count < item.min){
                    breakLoop = false;
                  }
                }
              })
            }else{
              breakLoop = true;
            }
          }
          else if(this.singItem.addon.length > 0){
            this.singItem.addon.forEach((group,index) =>{
              if(breakLoop){
                var count = 0;
                group.addon.forEach(item =>{
                  if(item.addon.is_checked){
                     count++;
                  }
                });
                const addonsGroup = group.adon_group.addon_category;
                if(count < addonsGroup.min){
                  breakLoop = false;
                }
              }
            })
          }else{
            breakLoop = true;
          }
          return breakLoop;
        },
        checkSizesSelected() {
          if(!this.isProductSizeGram){
            if(this.singItem.sizes.length > 0){
                if(this.singItem.sizes.some(item => item.selected)){
                return true;
              }else{
                return false;
              }
              }else{
                return true;
              }
          }else{
            if(this.singItem.grameSize){
              return true
            }else{
              return false
            }
          }
          },
           isProductSizeGram(){
            return this.singItem.sizes.some(item => item.item_size.name === 'grams');      
          },
          grameTotalPrice(){
              return (this.singItem.grameSize * this.singItem.sizes[0].size_dining_price).toFixed(2);
          }
     
       },
       mounted(){
       if(this.singItem.sizes.length > 0){
         this.sizezCardSelection(this.singItem.sizes[0],0);
         this.singItem.sizes[0].isSelect=true;
       }
      },
       methods:{
        addonIncrement(groupIndex,addonIndex,status){
          if(status == 'isGroupAddone'){
            this.singItem.addon[groupIndex].addon[addonIndex].qty++;
          }else{
            this.sizes_addons[groupIndex].addon[addonIndex];
            this.sizes_addons[groupIndex].addon[addonIndex].qty++;
          }
        },
        addDecrement(groupIndex,addonIndex,status){
          if(status == 'isGroupAddone'){
           if(this.singItem.addon[groupIndex].addon[addonIndex].qty > 1){
            this.singItem.addon[groupIndex].addon[addonIndex].qty--;
           }
          }else{
            if(this.sizes_addons[groupIndex].addon[addonIndex].qty > 1){
              this.sizes_addons[groupIndex].addon[addonIndex].qty--;
            }
          }
        },
        handleCheckboxClick(groupIndex,addonIndex,status,checked){
            if(this.checkGroupMinMax(groupIndex,status,'checkGoroupCondition') && !checked){
              if(status =='isGroupAddone'){
              this.singItem.addon[groupIndex].addon.forEach((item,index) =>{
                if(item.addon.is_checked){
                  this.singItem.addon[groupIndex].addon[index].addon.is_checked = false;
                  this.singItem.addon[groupIndex].addon[index].qty = 1;
                }
              })
            }else{
                  this.sizes_addons[groupIndex].addon.forEach((item,index) =>{
                  this.sizes_addons[groupIndex].addon[index].addon.is_checked = false;
                  this.sizes_addons[groupIndex].addon[index].qty = 1;
              });
            }
          }          
        },
        checkGroupMinMax(groupIndex,status,group) {
          var selectedAddonCount = 0;
          
          if(status == 'isGroupAddone'){
            var groups = this.singItem.addon[groupIndex].adon_group.addon_category;
            selectedAddonCount = this.singItem.addon[groupIndex].addon.filter(item => item.addon.is_checked).length;
          }else{
            var groups = this.sizes_addons[groupIndex];
            selectedAddonCount = this.sizes_addons[groupIndex].addon.filter(item => item.addon.is_checked).length;
          }
          if(group == 'checkGoroupCondition'){
            return selectedAddonCount >= groups.min && selectedAddonCount == groups.max;
          }else{
            return selectedAddonCount >= groups.min;
          }
        },
         addToCart(){
          if(this.isProductSizeGram){
            this.singItem.sizes[0].size_dining_price = this.grameTotalPrice; 
          }
          var addons = [];
          if(this.singItem.addon.length > 0){
            this.singItem.addon.forEach((item,n) =>{
              item.addon.forEach((addon,index) =>{
                console.log(addon)
                if(addon.addon.is_checked){
                  this.singItem.addon[n].addon[index].addon.is_checked = true;
                  let addonObj ={
                    id:addon.addon.id,
                    name:addon.addon.name,
                    price: addon.price,
                    qty:addon.qty,
                    addon_dining_price:addon.addon_dining_price,
                  }
                  addons.push(addonObj);
                }
              })
            })

          }
           this.usePOSStore.posStorage(this.singItem,this.sizes_addons); 
           const arr ={
             items:this.singItem,
             sizes_addons:this.sizes_addons,
             addons: addons,
           }  
           this.isLoading = true;
           setTimeout(() => {
             this.isLoading = false;
             this.$emit("add-to-cart-action",arr)  
          }, 1000);
          
          
         },
         increment(){
          //  const audio = new Audio(this.incrementAudio);
          //  audio.play();
           return this.singItem.qty++
         },
         decrement(){
           if(this.singItem.qty > 1){
            //  const audio = new Audio(this.incrementAudio);
            //  audio.play();
             return this.singItem.qty--
 
           }
         },
         sizezCardSelection(data,index){
          this.selectedSizeIndex = index; 
           this.singItem.sizes.forEach(item=>{
              item.selected=false;
              item.menu_addon.forEach(addon =>{
                   addon.addon.is_checked = false
              })
           });
           this.singItem.sizes[index].selected = true;  
           const menu_addon = data.menu_addon;  
         
           this.sizes_addons=[];
           var sizes_group = [];
           menu_addon.forEach(menu =>{
             // menu.addon.is_checked = false;
                 if (!sizes_group.find(item => item.id === menu.addon.addon_category.id)) {
                       sizes_group.push({
                           id:menu.addon.addon_category.id,
                           name:menu.addon.addon_category.name,
                           max:menu.addon.addon_category.max,
                           min:menu.addon.addon_category.min,
                           addon:[],
                         });
                    }
                    const indx = sizes_group.findIndex(item => item.id === menu.addon.addon_category.id);
                    menu.qty = 1;
                    sizes_group[indx].addon.push(menu);
                    this.sizes_addons = sizes_group;  
           });
 
         }
       }
     }
   </script>
 
 
 <style scoped>
 .header{
     border-bottom: 1px solid #C2C9D0;
     padding: 15px !important;
 }
 .gram-count-box{
  width:90px;
    padding:10 5px;
    border:1px solid #C2C9D0;

    border-radius:5px;
    margin:0 5px;
    text-align: center;
}
 .count-box{
     width:50px;
     padding:0 5px;
     border:1px solid #C2C9D0;
     border-radius:5px;
     margin:0 10px;
     text-align: center;
 }
 .item-qty-section{
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 10px 0;
 }
 .qty-btn{
     width:25px;
     height:25px;
 }
 .addon-qty-btn{
  width:18px;
  height:18px;
  font-size:12px;
}
 .product-area{
     display:flex;
 }
 .card-header{
  padding:0;
 }
 .item-img{
     width:125px;
     height:125px;
     border-bottom-right-radius: 10px;
 }
 .product-area .product-info{
     padding-left:10px;
     padding-top:5px;
     width:70%
 }
 
 .v-card-text{
   display: grid ;
   text-align: center !important;
 }
 .clickable-card{
   border: 1px solid #d7171c;
   color: #d7171c;
 }
 .modal-btn{
   width: 50%;
   border-radius: 0;
 
 }
 .sizes-label{
   text-align:Initial;
   margin-bottom:10px;
   color:#d7171c;
   font-weight: 700;
 }
 .adon-check-label{
   display: grid;
   margin-top: 10px;
 }
 .price-text{
   font-size: 12px;
   text-align:start;
 }
 .addon-label{
   font-size: 14px;
   font-weight: 500;
 }
 
 .custom-color input[type="checkbox"] {
   background-color: #d7171c;
   border-color: #d7171c;
 }
.addonList{
  display: grid;
  margin-top:10px
}
.checkBox-lable{
    display: flex;
    align-items: center;
  }
.checkBoxArea{
  height:60px;
  padding:0;
}
.addOn-group-name{
    padding-left: 0 !important; 
    margin-top:10px;
    padding-bottom:0;
  }
.crosBtn{
    position: absolute;
    top: 5px;
    right: 5px;
  }
.divider-border{
  margin-top:20px;
}
.sizeNamePrice{
    font-size: 12px !important;
    padding:5px;
  }
  @media(max-width:400px){
  .qty-btn{
    width:18px;
    height:18px;
    font-size:13px;
  }
  .count-box{
    width:35px;
  }
  .gram-count-box{
    width:35px !important;
  }
  .product-area .product-info{
    width:55% !important;
  }
}
@media(max-width:768px){
  .checkBoxArea{
    padding: 10px 0 !important;
    /* height: 60px !important; */
  }
  
}
@media(max-width:599.99px){
 
  .item-img{
    width:110px;
    height:110px;
  }
  .product-info h3{
    font-size:15px;
  }
  .product-info p{
    font-size: 12px;
  }
  .crosBtn{
    width:30px;
    height:30px;
  }
  .checkBoxArea{
    height:40px;
  }
  .addonList{
    display:flex;
    margin-top:0
  }
  .addonList .price-text{
    margin-left:7px;
  }
  .addonGroup{
    margin-bottom:25px;
  }
  .checkBox-lable{
    justify-content:space-between;
    width:100%;
    padding-right:15px;
  }
 
  .item-qty-section span{
     font-size:12px !important;
  }
  .item-qty-section span{
     font-size:12px !important;
  }
  .gram-count-box{
    width:45px
  }
  .addonGroup p{
    font-size:11px;
  }
  .divider-border{
    margin-top:5px;
  }
  .product-area .product-info{
    width:60%;
  }
}
.addon-count-box{
  width:30px;
  border-radius:5px;
  text-align: center;
  height:20px;
}
.textRed{
  color:red !important;
}
.addonsListArea{
  padding:10 20px 0px !important;
}
.v-card-actions{
  padding:0 !important;
  min-height:36px !important;
}
.v-card-actions .v-btn{
  padding:0; 
  height:45px;
}
 </style>