<template>
    <page-loader v-if="pageLoader"/>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
   <div style="margin-top: 70px;" @click="drawer=false">
        <v-app-bar app color="#d7171c">
            <v-container style="display:flex;align-items:center">
                <v-btn icon @click="goBack">
                    <v-icon style="color:#fff;font-weight:700">mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title style="color:#fff;font-weight:700">Order Status</v-toolbar-title>
            </v-container>
        </v-app-bar>
       <v-container style="margin-top: 70px;">
            <v-row>
                <v-col cols="4" class="status-preparing">
                    <span>Status</span>
                    <p>Preparing...</p>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4"></v-col>
            </v-row>
            <div class="spceing-box"></div>
       </v-container>
       <div class="sm-footer">
            <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
        </div>
   </div>
</template>

<script>
import NavBar from '@/layouts/default/NavBar.vue'
import { posStore } from '@/store/posStore' 
import { vendorStore } from '@/store/vendor.js' 
import { useUserStore } from '@/store/user.js' 
import PageLoader from '@/components/PageLoader.vue'
import smFooterComponent from '@/components/smFooterComponent.vue'
import firebase from '@/helper/firebaseInit.js'
 export default{
   components:{
       'nav-bar-vue':NavBar,
       'page-loader':PageLoader,
       'small-screen-footer':smFooterComponent,
   },
   setup() { 
        const userStore = useUserStore();  
        const useVendorStore = vendorStore() 
         return {userStore,useVendorStore};
   },
   created() {
       if (!User.loggedIn()) {
           this.$router.push('/');
       }
   },
   data(){
       return{
           drawer:false,
        
       }
   },
   mounted(){ 
    
   },
   computed:{
       
   },
   methods:{
   
      
   }
}
</script>

<style scoped>
.status-preparing span{
    font-size:25px;
    font-weight:500;
}
.status-preparing{}
</style>



<!-- PENDING,APPROVE, -->