<template>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div style="margin-top: 60px;" @click="drawer=false">
         <v-container style="display: flex;justify-content:center;align-items:center">
        
           <v-card class="contact-card" style="padding: 30px;" outlined>
              <div class="card-header" :style="dynmicBgColor">
                <img :src="vendordata.image" alt="" srcset="">
                <div class="outer-vendor-info">
                    <h3>{{ vendordata.name }}</h3>
                    <p>{{ vendordata.address }}</p>
                    <small @click="initiateCall(vendordata.contact)"><v-icon  left>mdi-phone</v-icon>{{ vendordata.contact }}</small>
                    <!-- <div class="email-phone">
                      <small><v-icon left>mdi-email</v-icon>{{ vendordata.email_id }}</small>
                    </div> -->
                    <div>
                      <small><v-icon left>mdi-clock</v-icon> Open: {{vendordata.start_time  }} </small>
                      <small><v-icon left>mdi-clock</v-icon> Close: {{vendordata.close_time  }} </small>
                    </div>
                </div>
              </div>
             <v-card-title style="margin-bottom: 30px;font-size: 30px;">Feedback</v-card-title>
             <transition name="fade">
               <v-alert
               v-if="successAlert"
                  type="success"
                  title="Thanks for your feedback" 
                ></v-alert>
             </transition>
             <v-card-text>
               <form @submit.prevent="submitForm">
                 <v-row>
                   <!-- Full Name -->
                   <v-col cols="12" sm="6">
                     <v-text-field
                       v-model="name"
                       label="Full Name"
                       variant="solo"
                       required
                       :error-messages="textErrors"
                     >
                     <template v-slot:prepend-inner>
                         <v-icon :color="useVendorStore.resturant.main_color">mdi-account</v-icon>
                     </template>
                   </v-text-field>
                   </v-col>

                   <!-- Phone Number -->
                   <v-col cols="12" sm="6">
                     <v-text-field
                       v-model="phoneNumber"
                       label="Phone Number"
                       variant="solo"
                       required
                     >
                     <template v-slot:prepend-inner>
                         <v-icon :color="useVendorStore.resturant.main_color" class="custom-icon">mdi-phone</v-icon>
                     </template>
                   </v-text-field>
                   </v-col>

                   <!-- Rating Star -->
                   

                   <!-- File Upload -->
                   <v-col cols="12" sm="12">
                     <v-file-input
                       v-model="file"
                       label="File Upload"
                       variant="solo"
                       :show-size="true"
                       accept="image/*"
                       @change="onUpload"
                       multiple
                       hide-default-icons
                     >
                     <template v-slot:append-inner>
                        <v-icon :color="useVendorStore.resturant.main_color" class="custom-icon">mdi-image</v-icon>
                    </template>
                  
                   </v-file-input>
                   </v-col>
                  
                   <!-- Comments -->
                   <v-col cols="12">
                     <v-textarea
                       v-model="comment"
                       label="Comments"
                       :rows="4"
                       :counter="200"
                       counter-value-color="teal"
                       placeholder="Type something..."
                       variant="solo"
                       clearable
                       dense
                     >
                     <template v-slot:prepend-inner>
                         <v-icon :color="useVendorStore.resturant.main_color" class="custom-icon">mdi-comment</v-icon>
                     </template>
                   </v-textarea>
                   </v-col>
                   <v-col cols="12" sm="6">
                     <div class="rating" style="display: flex;align-items: center;">
                      <h3>Rating *</h3>
                       <v-rating :color="useVendorStore.resturant.main_color" v-model="rating" :hover="hoverEnabled" :readonly="readonly" dense half-increments></v-rating>
                     </div>
                   </v-col>
                 </v-row>
                 <v-card-actions>
                    <v-btn :loading="btnloading" :style="dynmicBgColor" class="submitBtn" type="submit" text color="primary">Submit</v-btn>
                </v-card-actions>
               </form>
             </v-card-text>
           </v-card>
         </v-container>
         <div class="spceing-box"></div>
         <web-footer/>
        <div class="sm-footer">
             <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
         </div>
    </div>
  </template>
  
  <script>
  import PageLoader from '@/components/PageLoader.vue'
  import NavBar from '@/layouts/default/NavBar.vue'
  import smFooterComponent from '@/components/smFooterComponent.vue'
  import webFooterComponent from '@/components/webFooterComponent.vue'
  import { vendorStore } from '@/store/vendor.js' 
  import { useUserStore } from '@/store/user.js' 

  export default {
    components:{
        'page-loader':PageLoader,
        'small-screen-footer':smFooterComponent,
        'nav-bar-vue':NavBar,
        'web-footer':webFooterComponent
    },
    setup() { 
         const useVendorStore = vendorStore() 
         const userStore = useUserStore();  
          return {useVendorStore,userStore};
    },
    data() {
      return {
        drawer:false,
        btnloading:false,
        successAlert:false,
        hoverEnabled: true,
        rating: 5, 
        name:null,
        comment:null,
        phoneNumber:null,
        vendordata:{},
        images: [],
        file:''
      };
    },
    mounted() {
      this.vendordata= this.useVendorStore.resturant;
    },
    computed:{
      dynmicBgColor(){
        return {
          'backgroundColor':this.useVendorStore.resturant.main_color,
          'color':this.useVendorStore.resturant.font_color +' !important',
        }
      },
    },
    methods: {
      initiateCall(phoneNumber){
            window.location.href = `tel:${phoneNumber}`;
        },
      onUpload(event){
        this.images = event.target.files;
      },
      submitForm(){
        console.log()
        this.btnloading=true;
        if (!User.loggedIn()) {
           var user_id = '';
        }else{
          var user_id =this.userStore.user.id;
        }
        const config = {
              headers: { 'content-type': 'multipart/form-data' }
            }
          let formData = new FormData();
          formData.append('vendor_id', this.useVendorStore.vender_id);
          formData.append('name', this.name);
          formData.append('contact', this.phoneNumber);
          formData.append('rate', this.rating);
          formData.append('comment', this.comment);
          formData.append('user_id', user_id);
          const base_url = this.useVendorStore.base_url;
          for (let i = 0; i < this.images.length; i++) {
                formData.append('image[]', this.images[i]);
          }
          this.$axios
            .post(base_url + 'feedbacksVuejs',formData)
            .then((res) => {
                console.log(res);
                this.btnloading =false;
                Notification.success();
                this.successAlert=true;
                this.name = null;
                this.phoneNumber = null;
                this.rating = null;
                this.comment = null;
                this.images = [];
                this.file = null;
                setTimeout(() => {
                   this.successAlert =false;
              }, 15000);
              })
            .catch((error) => {
                console.log(error);
                // console.error(error);
                this.btnloading =false;
                this.successAlert =false;
            });
      }
    }
  };
  </script>
  
  <style scoped>

  @media(max-width:959.99px){
   
}
.rating{
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding: 10px;
    border-radius: 5px;
    margin-top: -30px;
}
.submitBtn{

  width: 150px;
  height: 40px;
}
.card-header{
  background-color: #d7171c;
  color: #fff !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  border-radius: 10px;
}
.email-phone{
  display: flex;
  justify-content: space-between;
}
.card-header img{
  width: 120px;
  height: 120px;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media(max-width:599px){
  .v-card{
    padding: 0 !important;
  }
  .card-header{
    /* position: absolute; */
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  } 
  .card-header img{
    width: 70px;
    height: 80px;
    border-bottom-left-radius: 0px;
  }
  .card-header h3{
    font-size: 15px;
  }
  .card-header p{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 12px;
  }
  .card-header small{
    font-size: 10px;
  }
  .outer-vendor-info{
    width: 65%;
  }
  .rating{
    display: block !important;
  }
}
.v-input--horizontal .v-input__prepend{
  display: none !important;
}
  </style>
  