<template>
    <v-card>
        <div class="card-header" :style="dynmicBgColor">
            <div>
                <img class="resturent-img" :src="vendorData.image" alt="" srcset="">
            </div>
            <div class="resturant-info">
                <h3 class="h3-heading">{{ vendorData.name }}</h3>
                <div class="location"><v-icon >mdi-map-marker</v-icon>{{ truncatedString(vendorData.address,28) }}</div>
            </div>
            <v-tooltip
              activator="parent"
               location="top"
                >
                <div class="resturant-info">
                <h3 class="h3-heading">{{ vendorData.name }}</h3>
                <div class="location"><v-icon style="font-size:16px">mdi-map-marker</v-icon>{{ vendorData.address}}</div>
            </div>
            </v-tooltip>
        </div>
      <v-divider></v-divider>
      <div class="card-body">
          <div v-if="posCartData.length > 0"> 
             <div class="input-field-cus">
                <div class="scrollable-section" id='scroll'>
                    <v-divider></v-divider>
                    <div class="scrollable-content">
                        <v-card class="item-card" v-for="(cartData,index) in posCartData" :loading="CardisLoading[index]" :key="index">
                            <div class="item-card-top">
                                    <div  style="display: flex;width:80%">
                                        <div class="dote-icon" :style="dynmicFontColor">
                                            <v-icon style="font-size: 7px;">mdi-circle</v-icon>
                                        </div>
                                        <div class="item-namePrice">
                                            <h6>{{ cartData.selectdItems.name }}</h6>
                                            <small v-if="cartData.selectdItems.dining_price">
                                                {{ formattedPrice(calculateQtyPrice(cartData.selectdItems.qty,cartData.selectdItems.dining_price)) }}
                                            </small>
                                            <div v-for="size in cartData.selectdItems.sizes" :key="size.id">
                                                 <small v-if="size.selected" style="margin-right: 4px;font-weight:500">({{ size.item_size.name }})</small>
                                                <small v-if="size.selected">
                                                {{ formattedPrice(calculateQtyPrice(cartData.selectdItems.qty,size.size_dining_price))}}</small>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="qty-section">
                                           <v-btn class="qty-btn" :style="dynmicFontColor" icon @click="decrement(index)">
                                               <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <input type="text" readonly class="count-box" v-model="cartData.selectdItems.qty" :style="{'border':'1px solid '+useVendorStore.resturant.main_color}">
                                            <v-btn class="qty-btn" :style="dynmicFontColor"  icon @click="increment(index)">
                                            <v-icon>mdi-plus</v-icon> 
                                       </v-btn>
                                      </div>  
                                 <v-icon class="item-remove" @click="removeItem(index)">mdi-delete</v-icon>
                            </div>
                            <div class="item-card-bttom">
                                <div class="item-extra-things" v-for="(addon,ind) in cartData.SelectedSizes.addons" :key="ind" :style="{
                                    'border-left':'2px solid '+useVendorStore.resturant.main_color
                                }">
                               
                                     <div class="line" :style="dynmicBgColor"></div>
                                     <div class="addons_list">
                                         <div>
                                            <small >{{ addon.addon.name }}</small>
                                            <small  style="margin-left:4px;font-size:10px ;">
                                                ({{calculateQtyPrice(addon.qty, addon.addon_dining_price )}})
                                            </small>
                                         </div>
                                         <div class="qty-section ml-3" v-if="addon.addon_dining_price > 0">
                                            <v-btn class="qty-btn" :style="themColor" icon @click="addonDecrement(index,ind,'isSizeAddon')">
                                            <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <input style="height:16px;width:22px;font-size:13px" type="text" readonly class="count-box" v-model="addon.qty" :style="{
                                                'border': '1px solid '+useVendorStore.resturant.main_color,
                                            }">
                                            <v-btn class="qty-btn" :style="themColor"  icon @click="addonIncrement(index,ind,'isSizeAddon')">
                                                <v-icon>mdi-plus</v-icon> 
                                            </v-btn>
                                        </div>
                                     </div>
                                </div>
                            </div>
                            <div class="item-card-bttom" v-if="cartData.selectedItemsAdon.length > 0" >
                                <div class="item-extra-things"  v-for="(addon,ind) in cartData.selectedItemsAdon" :key="ind" :style="{
                                    'border-left': '2px solid '+useVendorStore.resturant.main_color
                                }">
                                    <div class="line" :style="dynmicBgColor"></div> 
                                    <div class="addons_list">
                                        <div>
                                            <small >{{ addon.name }}</small>
                                            <small style="margin-left:4px;font-size:10px ;">({{calculateQtyPrice(addon.qty, addon.addon_dining_price )}})</small> 
                                        </div>
                                        <div class="qty-section ml-3" v-if="addon.addon_dining_price > 0">
                                            <v-btn class="qty-btn" :style="themColor" icon @click="addonDecrement(index,ind,'isGroupAddon')">
                                            <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <input style="height:16px;width:22px;font-size:13px" type="text" readonly class="count-box" v-model="addon.qty" :style="{
                                                'border': '1px solid '+useVendorStore.resturant.main_color,
                                            }">
                                            <v-btn class="qty-btn" :style="themColor"  icon @click="addonIncrement(index,ind,'isGroupAddon')">
                                                <v-icon>mdi-plus</v-icon> 
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
             </div>
             <!-- <v-divider v-if="!showProductScreen"></v-divider> -->
             <v-textarea
                v-if="!showProductScreen" 
                v-model="orderNote"
                style="margin-top: 20px;"
                label="Add Note"
                :rows="2"
                :counter="200"
                counter-value-color="teal"
                placeholder="Type something..."
                variant="outlined"
                clearable
                dense
                @input="filterEmojis"
                >
                <template v-slot:prepend-inner>
                     <v-icon :color="useVendorStore.resturant.main_color" class="custom-icon">mdi-message</v-icon>
                </template>
            </v-textarea>
             <v-divider></v-divider>
              <div class="payment-area">
                  <div class="paymentSide" >
                    <table>
                        <tbody>
                            <tr v-if="taxRate > 0">
                                <th colspan='2' style="font-weight: 400;">Item Tax :</th>
                                <td colspan='2'>{{ formattedPrice(this.taxRate) }}</td>
                            </tr>
                            <tr>
                                <th colspan='2' style="font-weight: 400;">Item Total :</th>
                                <td colspan='2'>{{ formattedPrice(this.ItemtotaPrice) }}</td>
                            </tr>
                            <tr>
                                <th colspan='2' >Total Amount :</th>
                                <td colspan='2'>{{ formattedPrice(this.totalPay) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
             <v-divider></v-divider>
             <div class="check-out-btn" >
                <v-btn  v-if="showProductScreen" @click="$emit('change-product-section')" :style="dynmicBgColor">Check Out <v-icon>mdi-arrow-right</v-icon></v-btn>
                <v-btn v-else @click="orderPlace" :loading="placeOrderLoading" :style="dynmicBgColor">Place Order <v-icon>mdi-arrow-right</v-icon></v-btn>
             </div>
          </div>
          <div v-else>
              <h4>Cart is empty. Add an item.</h4> 
          </div>
      </div>
    </v-card>
  </template>

<script>
import { posStore } from '@/store/qrCodePosStore' 
import { vendorStore } from '@/store/vendor.js' 
import User from '@/helper/User';
export default { 
    props:['vendorData','posCartData','checkVendor','showProductScreen','placeOrderLoading','formattedPrice'],

    setup() {
        const useVendorStore = vendorStore();
        const usePosStore = posStore();
          return { usePosStore,useVendorStore};
    },
    
    data(){
        return{
            CardisLoading:[],
            incrementAudio:"audio/increment.mp3",
            removeAudio:"audio/remove.mp3",
            textAddress:null,
            checkLoader:false,
            addressAlert:false, 
            orderNote:null,

        }
    },
    created() {
      this.CardisLoading = Array(this.posCartData.length).fill(false);
    },
    computed:{
        dynmicFontColor(){
            return {'color':this.useVendorStore.resturant.main_color}
        },
        dynmicBgColor(){
            return {
                'backgroundColor': this.useVendorStore.resturant.main_color,
                'color':this.useVendorStore.resturant.font_color 
            }
        },
        taxRate(){
                var totalPrice = 0;
                var qty = 0;
                var count = 0;
                this.posCartData.forEach(cartData => {
                    totalPrice += this.SizeAddonTotalPrice(cartData,cartData.selectdItems.qty);
                    qty += cartData.selectdItems.qty;
                    count++;
                });
                const tax = this.vendorData.tax/100;
                const totalTax = (totalPrice - (totalPrice / (1 + tax))).toFixed(2);
                return  totalTax;

            },
            ItemtotaPrice(){
                var totalPrice = 0;
                this.posCartData.forEach(cartData => {
                    totalPrice += this.SizeAddonTotalPrice(cartData,cartData.selectdItems.qty);
                });
                const total =  totalPrice - this.taxRate;
                return total.toFixed(2);
            },
            totalPay(){
                var totalPrice = 0;
                this.posCartData.forEach(cartData => {
                    totalPrice += this.SizeAddonTotalPrice(cartData,cartData.selectdItems.qty);
                });
                return (totalPrice).toFixed(2);
            },
            vendorLocationsInMap(){
                if( this.vendorData.country === 'Pakistan'){
                    return ['pk']
                }else{
                    return ['aus']
                }
            },
            TotalAMountWithTax(){
                return parseFloat(this.taxRate) + parseFloat(this.ItemtotaPrice);
            }

        },
    methods:{ 
        truncatedString(srting,leng) {
            if(srting){
                if (srting.length > leng) {
                  return srting.slice(0, leng) + "...";
                } else {
                  return srting;
               } 
            }
           },
        calculateQtyPrice(qty,price){
            return (qty * price).toFixed(2);
        },
        filterEmojis(){
            this.orderNote = this.orderNote.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
        },
        orderPlace(){
            var currentDate = VendorHelper.setTimeZone(this.vendorData.timezone);
            var dateObj= new Date(currentDate[2])
            var time = currentDate[1];
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');
            var date  =`${year}-${month}-${day}`;
            let formattedTime = time.replace(" AM", "am").replace(" PM", "pm");
            const obj ={
               time:formattedTime,
               date:date,
               itemsOrder:this.placeOrderObject(),
               taxRate:this.taxRate,
               ItemtotaPrice:this.ItemtotaPrice,
               totalPay:this.totalPay,
               TotalAMountWithTax:this.TotalAMountWithTax,
               orderNote:this.orderNote
           }
           this.$emit('place-order-action',obj)
        },
        placeOrderObject(){
             var object ={
                vendor_id:this.vendorData.id,
                id:null,
                editNo:1,
                cart:[]
             };
             this.posCartData.forEach(item =>{
                var totalAmount = 0;
                var totalPrice = 0;
                var diningPrice = 0;
                if(item.selectdItems.dining_price){
                    totalAmount += parseFloat(item.selectdItems.dining_price) * item.selectdItems.qty;
                    totalPrice += parseFloat(item.selectdItems.price) * item.selectdItems.qty;
                }
                const obj = {
                    category:'SINGLE',
                    total_amount:0,
                    dining_amount:0,
                    menu:[{
                        id:item.selectdItems.id,
                        unique_item_id:VendorHelper.generateUniqueId(),
                        name:item.selectdItems.name, 
                        image:"",
                        total_amount:0, 
                        dining_amount:null,
                        addons:[],
                        modifiers:[],
                        deals_items:null,
                    }],
                    size:null,
                    
                    menu_category:{
                        name:item.selectdItems.cat_name,  //image:item.selectdItems.img,
                        id:item.selectdItems.cat_id,
                    },
                    quantity:item.selectdItems.qty
                }
                item.selectdItems.sizes.forEach(groupSizes =>{
                    console.log(groupSizes)
                    if(groupSizes.selected){
                        totalAmount += parseFloat(groupSizes.size_dining_price) * item.selectdItems.qty;
                        totalPrice += parseFloat(groupSizes.price) * item.selectdItems.qty;
                        obj.size={
                            id:groupSizes.item_size.id,
                            size_name:groupSizes.item_size.name,
                        }
                        groupSizes.menu_addon.forEach(addon => {
                            if(addon.addon.is_checked){
                                const searchAddon = this.getSizesAddonData(item,addon,'isSizesAddons');
                                // totalAmount += parseFloat(searchAddon.addon_dining_price) * searchAddon.qty;
                                // totalPrice += parseFloat(addon.addon_dining_price)* searchAddon.qty 
                                obj.menu[0].addons.push({
                                    id:addon.addon.id,
                                    name:addon.addon.name,
                                    price:parseFloat(addon.price) * searchAddon.qty,
                                    quantity:searchAddon.qty,
                                    dining_price:parseFloat(addon.addon_dining_price) * searchAddon.qty,
                                })
                            }
                        })
                    }
                })
                  item.selectdItems.addon.forEach(group =>{
                    group.addon.forEach(addon =>{
                        if(addon.addon.is_checked){
                            const searchAddon = this.getSizesAddonData(item,addon,'isGroupAddon');
                            // totalAmount += parseFloat(searchAddon.addon_dining_price) * searchAddon.qty;
                            // totalPrice += parseFloat(addon.addon_dining_price)* searchAddon.qty
                            obj.menu[0].addons.push({
                                id:addon.addon.id,
                                name:addon.addon.name,
                                price:parseFloat(searchAddon.price) * searchAddon.qty,
                                quantity:searchAddon.qty,
                                dining_price:parseFloat(searchAddon.addon_dining_price) * searchAddon.qty,
                            })
                        }
                    })
                })
                obj.total_amount = parseFloat(totalPrice);
                obj.menu[0].total_amount = parseFloat(totalPrice);  
                obj.menu[0].dining_amount = parseFloat(totalAmount);  
                obj.dining_amount = totalAmount;
                object.cart.push(obj);
                console.log(obj)
             })
            return object;
           
        },
        getSizesAddonData(items,addon,status){
          var addon_data = null;
        //   console.log(addon.addon_id)
          if(status === 'isSizesAddons'){
            items.selectedItemssizes.forEach(group =>{
              group.addon.forEach(item =>{
                if(item.addon.is_checked){ 
                  if(addon.addon_id == item.addon_id){
                    addon_data =  addon;
                    console.log(item);
                  }
                }
              })
            })
          }else{
            items.selectedItemsAdon.forEach(item =>{
              if(item.id ==addon.addon_id ){
                addon_data = item
              }
            })
            
          }
        // console.log(addon_data);
          return addon_data;
        },
        SizeAddonTotalPrice(posData,qty){
                var totalPrice = 0;
                if(posData.selectdItems.dining_price){
                    totalPrice += parseFloat(posData.selectdItems.dining_price) * qty;
                }
                if(posData.SelectedSizes.addons){
                    totalPrice += parseFloat(posData.SelectedSizes.size_dining_price) *qty
                    posData.SelectedSizes.addons.forEach(addon =>{
                    var addonPrice = parseFloat(addon.addon_dining_price) * addon.qty;
                        totalPrice += addonPrice
                    }) 
                }
                else if(posData.selectdItems.addon.length > 0){
                    posData.selectedItemsAdon.forEach(item =>{
                        console.log(item.addon_dining_price);
                        totalPrice += parseFloat(item.addon_dining_price) * item.qty;
                    })
                }
                 return totalPrice;                
        },

        TopbuttonChangFunc(status){
            this.activeTopButton = status;
            var obj = {
                taxRate:this.taxRate,
                ItemtotaPrice:this.ItemtotaPrice,
                totalPay:this.totalPay,
            };
           this.usePosStore.checkOutStorage(this.posCartData,obj);
        },
        BottombuttonChangFunc(status){
            this.activeBottomButton =status;
              var obj = {
                taxRate:this.taxRate,
                ItemtotaPrice:this.ItemtotaPrice,
                totalPay:this.totalPay,
            };
           this.usePosStore.checkOutStorage(this.posCartData,obj);

        },
        addonDecrement(itemIndex,addonIndex,status){
            if(status === 'isGroupAddon'){
                if(this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty > 1){
                    this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty--;
                }
            }else{
                if(this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty >1){
                    this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty --;
                }
            }
            var obj = {
                taxRate:this.taxRate,
                ItemtotaPrice:this.ItemtotaPrice,
                totalPay:this.totalPay,
            };
            this.usePosStore.checkOutStorage(this.posCartData,obj);
        },
        addonIncrement(itemIndex,addonIndex,status){
            if(status === 'isGroupAddon'){
                this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty++;
            }else{
                this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty++;
            }
            var obj = {
                taxRate:this.taxRate,
                ItemtotaPrice:this.ItemtotaPrice,
                totalPay:this.totalPay,
            };
            this.usePosStore.checkOutStorage(this.posCartData,obj);
        },
        decrement(index){
            if(this.posCartData[index].selectdItems.qty > 1){
                // const audio = new Audio(this.incrementAudio);
                // audio.play();
                this.CardisLoading[index] =true;
                this.posCartData[index].selectdItems.qty--
                var obj = {
                    taxRate:this.taxRate,
                    ItemtotaPrice:this.ItemtotaPrice,
                    totalPay:this.totalPay,
                };
            
            }else{
                this.posCartData.splice(index, 1)
                if(this.posCartData.length < 1){
                 localStorage.removeItem('cartData');
                 this.deliveryCahrge =0;
                 this.free_delivery_amount =0;
                 this.deliveryChargeData ={};
            }
            }
            this.usePosStore.checkOutStorage(this.posCartData,obj);
            setTimeout(() => {
                this.CardisLoading[index] =false;
         }, 1000);
        
        },
        increment(index){
            // const audio = new Audio(this.incrementAudio);
            // audio.play();
            this.CardisLoading[index] =true;
            this.posCartData[index].selectdItems.qty++
            var obj = {
                taxRate:this.taxRate,
                ItemtotaPrice:this.ItemtotaPrice,
                totalPay:this.totalPay,
            };
           this.usePosStore.checkOutStorage(this.posCartData,obj);
            setTimeout(() => {
                this.CardisLoading[index] =false;
         }, 1000);
        },
        removeItem(index){
            // const audio = new Audio(this.removeAudio);
            // audio.play();
            this.posCartData.splice(index, 1)
            if(this.posCartData.length < 1){
                 localStorage.removeItem('cartData');
            }

                var obj = {
                    taxRate:this.taxRate,
                    ItemtotaPrice:this.ItemtotaPrice,
                    totalPay:this.totalPay,
              };
                 this.usePosStore.checkOutStorage(this.posCartData,obj);
            
        },
      
 
        
    },
    
}
</script>

<style scoped>
.resturent-img{
    width: 65px;
    height: 80px;
    border-top-left-radius: 15px;
    /* border-radius: 100%; */
}
.resturant-info{
    padding-left: 10px;
}
.resturant-info h3{
    font-size: 14px;
}
.resturant-info .location{
   font-size: 16px;
   width: 100%;
   display: flex;
   align-items: center;
}
.v-card{
    border-radius: 15px;
}
.card-header{
    display: flex;
    align-items: center;
    padding: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 72px;
}
.card-body{
    padding: 0 20px;
    
}
.card-body .top-btton-list{
   display: flex;
   justify-content: center;
   align-items: center;
}
.card-body .top-btton-list button{
    padding: 5px 10px;
    color: #5A616A;
    width: 50%;
    border: 1px solid #5A616A;
}
.hoverButtons:hover{
    background-color: #5A616A;
    color: #fff !important;

}
.card-body .top-btton-list .btn1{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;

}
.card-body .top-btton-list .btn2{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
.btn-active{
    background-color: #5A616A;
    color: #fff !important;
}
.bottom-list{
    margin: 20px 0;
}
.h3-heading{
    font-weight: 500;
    font-size: 16px;
}
.pickup-addres-card{
    margin-top: 20px;
}
.pickup-addres-card .resturant-info{
    border: 1px solid #d7171c;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
}
.badge-text {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}
.v-badge{
    position: absolute;
    top: 11px;
    right: 60px;
    z-index: -1;
}
/* .input-field-cus{
    padding-top: 20px;
} */

.custom-text-field  {
   color: #d7171c !important; /* Replace with your desired custom color */
}
.scrollable-section{
    max-height: 180px;
    overflow: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid #5555;
}
.scrollable-content {
    width:100% /* Allow the content to expand horizontally */
}

.scrollable-content > * {
  margin-right: 8px; /* Add spacing between the items */
  
}
.scrollable-content .item-card{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    margin: 5px 0;
    widows: 100%;
    padding: 20px 10px;
    position: relative;
}
.scrollable-content .item-card .dote-icon{
    width: 15px;
    border: 1px solid;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
   
}
.scrollable-content  .item-namePrice{
    padding: 0 10px;
    display: grid;
}
.item-card-top{
    display: flex;
    /* align-items: center; */
    justify-content:space-between;
    width:95%;
}
.qty-section{
    width:30%
}
.qty-section .qty-btn{
    width: 15px;
    height: 15px;
    font-size: 10px;
} 
.qty-section .count-box{
    width: 50px;
    width: 33px;
    margin: 0 5px;
    height: 20px;
    border-radius: 5px;
    text-align:center;
}
.item-remove{
    color: #d7171c;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top:24px;
    right:10px;
}

.scrollable-content  .item-namePrice small{
    font-size: 11px;

}
.item-extra-things{
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
}

.item-extra-things .line{
    width: 15px;
    height: 2px;
    margin-right: 3px;
}
.check-out-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.check-out-btn .v-btn{
  width: 100%;
    height: 54px;
}
.locationSeach{
    border: 1px solid #d7171c;
    width: 100%;
    padding: 6px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
 .locationSeach:focus{
    border: 1px solid #d7171c;
    width: 100%;
    padding: 6px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.payment-area{
    padding: 10px;
  
    
}
.payment-area .paymentSide table{
    width: 100%;
}
.location-check .v-btn{
    border: 1px solid #d7171c;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 45px;
    background-color: #d7171c;
    color: #fff;
    
}
.custom-alert {
    font-size: 12px;
    margin-top: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.disable-btn{
    background: #F6F7F9;
    opacity: 0.2;
    color: #5A616A !important;
}
.payment-area tr{
    font-size: 13px;
    text-align: justify;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addons_list{
    display:flex;
    justify-content:space-between;
    width:100%;
}
@media(max-width:1024px){

    .payment-area .paymentSide{
             width: 100% !important;
    }

    .payment-area{
        display: block;
    }
    .payment-area tr{
        display: flex;
        justify-content: space-between;
    }
 
}
@media (max-width: 1024px) and (min-width: 991px) {
   .qty-section{
    width: 50% !important;
   }
}
</style>