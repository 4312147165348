<template>
     <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div style="margin-top: 60px;" @click="drawer=false"> 
        <v-container>
            <v-row justify="center">
            <v-col cols="12" sm="12" md="12">
                <v-card>
                <v-card-title class="text-h5" :style="{'color':useVendorStore.resturant.main_color}">Sign Up</v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="submitForm">
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="First Name"
                                v-model="form.first_name"
                                :rules="nameRules"
                                outlined
                                prepend-icon="mdi-account"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <!-- Last Name -->
                            <v-text-field
                                label="Last Name"
                                v-model="form.last_name"
                                :rules="nameRules"
                                outlined
                                prepend-icon="mdi-account"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Phone"
                                v-model="form.contact"
                                :rules="phoneRules"
                                outlined
                                prepend-icon="mdi-phone"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Email"
                                v-model="form.email_id"
                                :rules="emailRules"
                                outlined
                                prepend-icon="mdi-email"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                v-model="form.password"
                                :append-icon-cb="toggleShowPassword"
                                :rules="passwordRules"
                                label="Password"
                                :type="showPassword ? 'text' : 'password'"
                                prepend-icon="mdi-lock"
                                outlined
                                required
                            >
                                <template v-slot:append-inner>
                                    <v-icon @click="toggleShowPassword">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                </template>
                            
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Home Address"
                                v-model="form.full_address"
                                outlined
                                prepend-icon="mdi-home"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="Postcode"
                                v-model="form.Postcode"
                                outlined
                                prepend-icon="mdi-map-marker"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="City"
                                v-model="form.city"
                                outlined
                                prepend-icon="mdi-city"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                label="State"
                                v-model="form.state"
                                outlined
                                prepend-icon="mdi-flag"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                v-model="vehicle_type"
                                :items="vehicleOptions"
                                label="Select a Vehicle"
                                outlined
                                prepend-icon="mdi-car"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                v-model="preferable_shift"
                                :items="shiftOptions"
                                label="Preferable Shift"
                                prepend-icon="mdi-calendar-clock"
                                outlined
                                required
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                v-model="willing_to_work"
                                :items="resturantOptions"
                                label="Willing to Work"
                                prepend-icon="mdi-food"
                                outlined
                                required
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                v-model="license_type"
                                :items="licenseOptions"
                                label="Driving License"
                                prepend-icon="mdi-car-child-seat"
                                outlined
                                required
                            >
                                <!-- Custom prepend-item slot for icon -->
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                v-model="work_status"
                                :items="statusOptions"
                                label="Select a Work Status"
                                prepend-icon="mdi-briefcase"
                                outlined
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <div style="display:flex;justify-content:center;align-items:center">
                        <v-btn :disabled="btnLoading" :color="useVendorStore.resturant.main_color" class="mt-5" type="submit">
                            <template v-if="btnLoading">
                            <span>Loading...</span>
                            <v-progress-circular
                              indeterminate
                              size="24"
                              color="white"
                              class="ml-2"
                            ></v-progress-circular>
                          </template>
                          <template v-else>
                            Sign Up
                          </template>
                        </v-btn>
                    </div>
                    </v-form>
                </v-card-text>
                </v-card>
            </v-col>
            </v-row>
            <snack-bar :text="text" :snackbar="snackbar" ref="snackBarOpenCall" />
        </v-container>
        <div class="spceing-box"></div>
        <web-footer/>
        <div class="sm-footer">
            <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
        </div>
    </div>
</template>

<script>
  import PageLoader from '@/components/PageLoader.vue' 
  import NavBar from '@/layouts/default/NavBar.vue'
  import smFooterComponent from '@/components/smFooterComponent.vue'
  import webFooterComponent from '@/components/webFooterComponent.vue'
  import snackbarComponent from '@/components/snackbarComponent.vue'
  import { vendorStore } from '@/store/vendor.js' 
  import { useUserStore } from '@/store/user.js' 
  export default {
    components:{
        'page-loader':PageLoader,
        'small-screen-footer':smFooterComponent,
        'nav-bar-vue':NavBar,
        'web-footer':webFooterComponent,
        'snack-bar':snackbarComponent,
    },
    setup() {
            const useVendorStore = vendorStore();    
            return { useVendorStore};
        },
    data() {
        return{
            drawer:false,
            form:{
                first_name: '',
                last_name: '',
                contact: '',
                email_id: '',
                password: '',
                full_address: '',
                Postcode: '',
                city: '',
                state: '',
            },
            work_status: '',
            license_type: '',
            vehicle_type:'',
            preferable_shift:'',
            willing_to_work:'',
            nameRules: [
                v => !!v || 'First name is required',
                v => (v && v.length <= 50) || 'First name must be less than 50 characters',
            ],
            phoneRules: [
                v => !!v || 'Phone number is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 6) || 'Password must be at least 8 characters',
            ],
            selectedVehicle: null, // Holds the selected vehicle option
            vehicleOptions: ['Car', 'Bike', 'Ebike'], 
            shiftOptions: ['Morning', 'Afternoon', 'Evening'],
            showPassword: false,
            resturantOptions: ['Any Restaurant', 'Specific Restaurant'],
            licenseOptions: ['Full', 'Provisional 1', 'Provisional 2', 'P2P'],
            statusOptions: ['Full Working Right', 'Student'],
            text:'testing sdfsdfsdfsdf',
            snackbar:true,
            btnLoading:false,
        }
    },
    mounted(){
     
    },
     computed: {
        formIsInvalid() {
           return !this.form.first_name || !this.form.last_name || !this.form.contact || !this.form.email_id || !this.form.password
          },
        isEmailValid() {
            return this.emailRules.every(rule => rule(this.form.email_id) === true);
        },
        isPasswordValid() {
           return this.passwordRules.every(rule => rule(this.form.password) === true);
        },
        isPhoneValid() {
           return this.phoneRules.every(rule => rule(this.form.contact) === true);
        },
        isNameValid() {
           return this.nameRules.every(rule => rule(this.form.first_name) === true);
        },
    
    },

    methods:{
        toggleShowPassword() {
              this.showPassword = !this.showPassword;
           },
        keyValuePair(option){
            return option.replace(/ /g, '_').toLowerCase();
        },
        submitForm(){
            if(!this.formIsInvalid && this.isEmailValid && this.isPasswordValid && this.isPhoneValid && this.isNameValid){
                this.btnLoading = true
                this.form.work_status = this.keyValuePair(this.work_status);
                this.form.license_type = this.keyValuePair(this.license_type);
                this.form.vehicle_type = this.keyValuePair(this.vehicle_type);
                this.form.preferable_shift = this.keyValuePair(this.preferable_shift);
                this.form.willing_to_work = this.keyValuePair(this.willing_to_work);
                this.form.vendor_id = this.useVendorStore.vender_id;
                console.log(this.form);
                
                this.$axios
                    .post(this.useVendorStore.base_url+'driversignup',this.form)
                    .then(res =>{
                        console.log(res)
                        this.text = res.data[0];
                        Notification.success();
                        this.$refs.snackBarOpenCall.openSnackBar();
                        setTimeout(() => {
                            this.btnLoading = false;
                            this.$router.push('/');
                        },5000);   
                    }).catch(error =>{
                       console.log(error)
                })
                
            }
        }
    }
  }
</script>

<style scoped>
    .v-card{
        padding:30px !important;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }
    .v-card-title{
        margin-bottom:20px !important;
        text-align:center;
    }
</style>