class User {
    hasToken(){
        const storeToken = localStorage.getItem('access_token');
        if(storeToken){
            return true;
        }
        return false 
    }
    loggedIn(){
        return this.hasToken() 
    }
    BearerToken(){
        const storeToken = localStorage.getItem('access_token');
        return `Bearer ${storeToken}`
    }

   hasTbaleId(){
     const storeTbToken = localStorage.getItem('tb_id');
     if(storeTbToken){
        return true;
     } 
      return false
   }

   getTableId(){
    const storeTbToken = localStorage.getItem('tb_id');
    if(storeTbToken){
        return storeTbToken;
     }
   }
}
export default User = new User();