<template>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        multi-line
      >
        {{ text }}
  
        <template v-slot:actions>
          <v-btn
            color="red"
            variant="text"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  <script>
  export default {
    props:['text'],
    data: () => ({
      snackbar: false,
    }),
    methods:{
      openSnackBar(){
        this.snackbar =true;
      }
    }
  }
</script>