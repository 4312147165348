import { defineStore } from 'pinia'
export const useUserStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    access_token: localStorage.getItem('access_token')|| null,
    payment_setting: JSON.parse(localStorage.getItem('payment_setting'))|| null,
  }),
  actions: {
    async loginUser(data,payment_setting){
      this.user = data
      this.access_token = data.token;
      this.payment_setting = payment_setting;
      const localStore = JSON.stringify(data);
      localStorage.setItem('user', localStore);
      localStorage.setItem('access_token', data.token);
      localStorage.setItem('payment_setting',JSON.stringify(payment_setting));  
  
    },
    logout(){
      this.user = {};
      this.access_token = null;
      this.payment_setting = {};
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('payment_setting');
    },
    
  },
   
 
});
