<template>
  <v-form @submit.prevent="submitForm">
    <v-row style="text-align: initial">
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.name"
          label="Name"
          :rules="nameRules"
          variant="outlined"
          required
        >
          <template v-slot:prepend-inner>
            <v-icon
              :color="vendorStore.resturant.main_color"
              class="custom-icon"
              >mdi-account</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-file-input
          label="Image"
          :rules="imageRules"
          :show-size="true"
          accept="image/*"
          variant="outlined"
          required
          @change="onUpload"
        >
          <template v-slot:append-inner>
            <v-icon
              :color="vendorStore.resturant.main_color"
              class="custom-icon"
              >mdi-image</v-icon
            >
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.email_id"
          label="Email"
          :rules="emailRules"
          variant="outlined"
          required
        >
          <template v-slot:prepend-inner>
            <v-icon
              :color="vendorStore.resturant.main_color"
              class="custom-icon"
              >mdi-email</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.phone"
          label="Phone Number"
          variant="outlined"
        >
          <template v-slot:prepend-inner>
            <v-icon
              :color="vendorStore.resturant.main_color"
              class="custom-icon"
              >mdi-phone</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <div style="float: left">
      <v-btn type="submit" :style="bgColor" :disabled="btnloading">
        <template v-if="btnloading">
          <span>Loading...</span>
          <v-progress-circular
            indeterminate
            size="24"
            color="white"
            class="ml-2"
          ></v-progress-circular>
        </template>
        <template v-else> Save </template>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { useUserStore } from "@/store/user.js";
export default {
  props: ["vendorStore", "bgColor"],
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      form: {},
      image: null,
      nameRules: [(v) => !!v || "Name is required"],
      imageRules: [(v) => !!v || "Image is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      btnloading: false,
    };
  },
  mounted() {
    this.afterReload();
  },
  methods: {
    onUpload(event) {
      let file = event.target.files[0];

      this.image = event.target.files[0];
      let reader = new FileReader();

      reader.onload = (event) => {
        this.preview = event.target.result;
        // console.log(event.target.result);
        this.$emit("onload-image", event.target.result);
      };
      reader.readAsDataURL(file);
    },
    submitForm() {
      this.btnloading = true;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("email_id", this.form.email_id);
      formData.append("phone", this.form.phone);
      if (this.image) {
        formData.append("image", this.image);
      }
      const base_url = this.vendorStore.base_url;
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      this.$axios
        .post(base_url + "apiUpdateUservuejs", formData, { headers })
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.success) {
            Notification.success();
            this.$emit("after-success-update", res.data.data);
          }
          this.btnloading = false;
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          this.btnloading = false;
          if (error.response.status === 401) {
            this.userStore.logout();
            this.$router.push("/");
          }
        });
    },
    afterReload() {
      this.form = this.userStore.user;
    },
  },
};
</script>
