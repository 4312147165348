<template>
    
    <div class="page-loader">
      <v-progress-circular
        indeterminate
        size="64"
        :color="useVendorStore.resturant.main_color"
      ></v-progress-circular>
    </div>
  </template>
  
  <script>
  import { vendorStore } from "@/store/vendor.js";
  export default {
    name: 'PageLoader',
    setup() {
    const useVendorStore = vendorStore();
    return { useVendorStore };
  },
  }
  </script>
  
  <style>
  .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.969);
    z-index: 9999;
    
  }
  </style>
  