<template>
    <!-- <page-loader v-if="pageLoader"/> -->
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
     <hero-section @click="drawer=false" v-if="showProductScreen"/>
     <div style="margin-top: 30px;" @click="bodyClick" :class="{'marginTop':!showProductScreen}">
      
       <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="8">
                  
                   <div>
                    <v-card class="MainCard lgScreenForm" id="userDetails" ref="scrollTarget">
                            <div style="display: flex; justify-content:center;align-items:center">
                                <div style="width: 320px;">
                                    <v-row>
                                    <v-col cols="12" sm="12">
                                    <v-text-field
                                        v-model="user_form.name" 
                                        label="Name"
                                        variant="outlined"
                                        @input="validateName"
                                    >
                                    <template v-slot:append-inner>
                                            <v-icon :color="useVendorStore.resturant.main_color">mdi-account</v-icon>
                                    </template>
                                </v-text-field>
                                <small v-if="!nameValidtion && error"  style="color: red;position: absolute;top: 31%;">This Field is Required</small>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                    <v-text-field
                                        v-model="user_form.phone"
                                        label="Phone Number"
                                        variant="outlined"
                                        @input="validatePhone"
                                        type="number"
                                    >
                                    <template v-slot:append-inner>
                                            <v-icon :color="useVendorStore.resturant.main_color">mdi-phone</v-icon>
                                    </template>
                                    </v-text-field>
                                    <small v-if="!phoneValidtion && error" style="color: red;position: absolute;top: 67%;">This Field is Required</small>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-btn :style="dynmicBgColor" @click="$router.push({name:'qrCode',params: {tb_id: this.$route.params.table_id},})"><v-icon>mdi-arrow-left</v-icon> Back</v-btn>
                                    </v-col>
                                </v-row>
                                </div>
                            </div>
                    </v-card>
                   </div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                   <div class="posCardComp">
                       <pos-cart-component 
                       :posCartData="posCartData" 
                       :vendorData="vendorData" 
                       :checkVendor="checkVendor" 
                       :reload="deliveryCondition" 
                       ref="posComponent"  
                       @change-product-section="handleChangeMainSection" 
                       :showProductScreen ="showProductScreen" 
                       @place-order-action="handlePlaceOrderAction" 
                       :placeOrderLoading ="placeOrderLoading"
                       :formattedPrice="formattedPrice"
                       /> 
                   </div>
                   <v-dialog
                    v-model="user_details_box"
                    width="auto"
                    max-width="400" 
                    :persistent="true"
                    >
                        <v-card class="MainCard smScreenForm" id="userDetails">
                                <div style="display: flex; justify-content:center;align-items:center">
                                    <div style="width: 320px;">
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                            <v-text-field
                                                v-model="user_form.name" 
                                                label="Name"
                                                variant="outlined"
                                                class="error-border"
                                            >
                                            <template v-slot:append-inner>
                                                    <v-icon class="custom-icon">mdi-account</v-icon>
                                            </template>
                                        </v-text-field>
                                        <small v-if="!user_form.name" style="color: red;position: absolute;top: 30%;">This Field is Required</small>
                                            </v-col>
                                            <v-col cols="12" sm="12">
                                            <v-text-field
                                                v-model="user_form.phone"
                                                label="Phone Number"
                                                variant="outlined"
                                                
                                            >
                                            <template v-slot:append-inner>
                                                    <v-icon class="custom-icon">mdi-phone</v-icon>
                                            </template>
                                            </v-text-field>
                                            <small v-if="!user_form.phone" style="color: red;position: absolute;bottom:30%">This Field is Required</small>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6"><v-btn :style="dynmicBgColor" @click="goBack()"><v-icon>mdi-arrow-left</v-icon>Back</v-btn></v-col>
                                            <v-col cols="6" style="display:flex;justify-content:end"><v-btn @click="user_details_box = false" :disabled="!checkUserData" :style="dynmicBgColor">Save</v-btn></v-col>
                                        </v-row>
                                    </div>
                            </div>
                        </v-card>
                    
                    </v-dialog>
                </v-col>
            </v-row> 
        </v-container>
        <div class="spceing-box"></div>
   </div>
     <v-dialog v-model="addToCartBox" max-width="650px">
       <selected-product-dilog :singItem="singItem" @close="addToCartBox=false" @add-to-cart-action="handleCartData" />
   </v-dialog>
   <div class="web-footer"><web-footer/></div>
   
   <div class="sm-footer">
       <small-screen-footer @open-pos-dilog-box="posDilogBox=true" :totalItems="posCartData.length"/>
       <v-dialog
         v-model="posDilogBox"
         fullscreen
         :scrim="false"
         transition="dialog-bottom-transition"
       >
       <v-card>
           <v-toolbar dark color="#d7171c">
               <v-btn icon dark @click="posDilogBox = false">
               <v-icon style="color: #fff;">mdi-close</v-icon>
               </v-btn>
               <!-- <v-toolbar-title>Dialog Title</v-toolbar-title> -->
               <v-spacer></v-spacer>
           </v-toolbar>
           <div class="posCardComp">
            <pos-cart-component 
            :posCartData="posCartData" 
            :vendorData="vendorData" 
            :checkVendor="checkVendor" 
            :reload="deliveryCondition" 
            ref="posComponent"  
            @change-product-section="handleChangeMainSection" 
            :showProductScreen ="showProductScreen" 
            @place-order-action="handlePlaceOrderAction" 
            :placeOrderLoading ="placeOrderLoading"
            :formattedPrice="formattedPrice"
            />
            </div>
       </v-card>
   <!-- Dialog content -->

   <!-- ... -->
 </v-dialog>
   </div>
     <v-dialog
      v-model="isSuccess"
      persistent
      width="350">
      <after-sccess-dilog :successResp="successResp" @closeDilog="handleDilogBox" :dynmicBgColor="dynmicBgColor" :dynmicFontColor="dynmicFontColor"/>
    </v-dialog>
</template>
<script>
import NavBar from '@/layouts/default/NavBar.vue'
import CategoryComponent from '@/components/CategoryCOmponent.vue'
import PosCartComponent from '@/components/qr_code/POSCartComponent.vue'
import selectedProductDilog from '@/components/qr_code/SelectedMenueProductModal.vue'
import heroSection from '@/layouts/default/HeroSection.vue'
import { vendorStore } from '@/store/vendor.js' 
import { posStore } from '@/store/qrCodePosStore'  
import { useUserStore } from '@/store/user.js' 
import PageLoader from '@/components/PageLoader.vue'
import smFooterComponent from '@/components/smFooterComponent.vue'
import webFooterComponent from '@/components/webFooterComponent.vue'
import successDialog from '@/components/qr_code/afterSuccessOrderDilog.vue'
export default{
   components:{  
       'page-loader':PageLoader,
       'category-scroller' :CategoryComponent,  
       'selected-product-dilog':selectedProductDilog,
       'pos-cart-component' :PosCartComponent,
       'hero-section':heroSection,
       'nav-bar-vue':NavBar,
       'small-screen-footer':smFooterComponent,
       'web-footer':webFooterComponent,
       'after-sccess-dilog':successDialog,
   },
   setup() {
        const useVendorStore = vendorStore();
        const usePosStore = posStore();    
        const userStore = useUserStore(); 
         return { useVendorStore,usePosStore,userStore};
   },
   data(){
       return{
          drawer:false,
          menuList:[],
          productCardLoad:false,  
          selectedProduct:[],
          allCategory:[], 
          addToCartBox: false,  
          singItem:{},
          posCartData:[],
          vendorData:{},  
          currentDay:'',
          currentTime:'',
          openTime:'',
          closeTime:'',
          workingDays:[],
          todayTiming:{},
          searchTerm:"",
          pageLoader: true,
          posDilogBox:false,
          searchExtend:false,
          showProductScreen:false,
          placeOrderLoading:false,
          isSuccess:false,
          user_details_box:false,
          successResp:{},
          user_form:{
            name:null,
            phone:null,
            table_no:null,
          },
          tables:[],
          allTables:[],
          error:false,
       }
   },
   created() {
    this.vendorData = this.useVendorStore.resturant;
   },
   mounted(){
       this.user_form.table_no = this.$route.params.table_id;
       this.scrollToComponent();
       this.postItemReload();
       this.checkIfMobile();
   },
   computed:{
    checkUserData(){
        return this.user_form.name && this.user_form.phone;
    },
    dynmicBgColor(){
        return {
            'backgroundColor':this.useVendorStore.resturant.main_color,
            'color' : this.useVendorStore.resturant.font_color
        }
    },
    dynmicFontColor(){
        return {
            'color':this.useVendorStore.resturant.main_color
        }
    },
    isOpen() {
       return VendorHelper.isOpen(this.currentTime,this.todayTiming)
     },
     checkVendor(){
   //    return VendorHelper.isVenderOnOrOff(this.todayTiming.status ,this.isOpen, this.vendorData.vendor_status)
        return true
     },
     filtersearch() {
           return this.allCategory.map((category) => {
           const filteredMenu = category.single_menu.filter((menu) => {
               return this.matchesSearch(menu.menu.name);
           });
           return { ...category, single_menu: filteredMenu };
           }).filter((category) => category.single_menu.length > 0);
       },
       nameValidtion(){
          return !!this.user_form.name;
       },
       phoneValidtion(){
          return !!this.user_form.phone;
       },
       tableValidtion(){
        return !!this.user_form.table_no; 
       }

 
   },
  
   methods:{ 
    goBack(){
        //  alert(this.useVendorStore.homePageURL);
         this.$router.push({name:'qrCode'});
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const check = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      )&& window.innerWidth < 760;
      if(check){
         this.user_details_box = true;
      }else{
        this.user_details_box = false;
      }
    },

     formattedPrice(price){
      return VendorHelper.formatCurrency(price);
    },
    validateName() {
        this.user_form.name = this.user_form.name.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
    },
    validatePhone() {
        this.user_form.phone = this.user_form.phone.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
        
    },
    scrollToComponent(){
            if (this.$refs.scrollTarget) {
                this.$refs.scrollTarget.$el.scrollIntoView({
                behavior: 'smooth', // Use 'auto' for instant scroll
                block: 'center',    // Scroll to the vertical center of the component
                inline: 'center',   // Scroll to the horizontal center of the component
             });
            }
        },
        handleDilogBox(){
            this.usePosStore.fleshOrders();
             this.$router.push({name:'qrCode',params: {tb_id: this.$route.params.table_id},})
        },
        handlePlaceOrderAction(data){ 
            
            if(!this.nameValidtion || !this.phoneValidtion || !this.tableValidtion){
                this.handleChangeMainSection();
                this.error =true
                Notification.tableErr('Name and Phone number Required');
                this.posDilogBox = false;
            }else{

                this.placeOrderLoading=true;
                const obj ={
                    date : data.date,
                    time : data.time,
                    amount:data.totalPay,
                    tax : data.taxRate,
                    sub_total : data.ItemtotaPrice,
                    item : JSON.stringify(data.itemsOrder), 
                    vendor_id:this.vendorData.id,
                    delivery_type:'DINING',
                    payment_type:'INCOMPLETE ORDER',
                    table_no:this.user_form.table_no,
                    order_status:'APPROVE',
                    payment_status:0,
                    delivery_charge:0,
                    notes:data.orderNote,
                    user_name:this.user_form.name,
                    mobile:this.user_form.phone,
                    discounts:0.00,
                    tyro_surcharge:0.00
    
                }
                this.$axios
                     .post(this.useVendorStore.base_url+'apiBookOrderVuejsQR',obj)
                     .then(response =>{
                        this.isSuccess = true;
                        console.log(response.data.data);
                        this.successResp = response.data.data;
                        this.placeOrderLoading=false;
                        this.usePosStore.fleshOrders();
                        Notification.successPlaceOrder("Order Placed Successfully");
                        // this.$router.push({name:'qrCode',params: {tb_id: this.$route.params.table_id},})
                     })
                     .catch(error =>{
                        console.log(error) 
                       if(error){
                           this.placeOrderLoading=false;
                           if(!error.response.data.success){
                              Notification.tableErr(error.response.data.msg)
                           }
                       }
                 })
            }
            
       },
       handleChangeMainSection(){
         
       },
  
       bodyClick(){
           this.searchExtend =false;
           this.drawer =false;
           // this.searchTerm =null;
       },
       matchesSearch(text) {
           const searchTerm = this.searchTerm.toLowerCase();
           return text.toLowerCase().includes(searchTerm);
       },
       handleCartData(data){
           const addons = this.getSelectedAddon(data);
           const sizes  = this.getSelectedSizesAddon(data);
           this.posCartData.push({
               selectdItems:data.items,
               selectedItemssizes:data.sizes_addons,
               selectedItemsAdon:addons,
               SelectedSizes:sizes
           });
           this.usePosStore.PosItemStore(this.posCartData);
           this.addToCartBox = false;  
           this.postItemReload();  
       },
       getSelectedAddon(data){
           var addons = [];
           data.items.addon.forEach(group =>{
               group.addon.forEach(item =>{
                   if(item.addon.is_checked){
                       addons.push(item)
                   }
               })
           })
           return addons;
       },
       getSelectedSizesAddon(data){
           var obj={};
           data.items.sizes.forEach(size =>{
                if(size.selected){
                    obj.id=size.id;
                    obj.display_discount_price=size.display_discount_price;
                    obj.display_price=size.display_price;
                    obj.item_size_id=size.item_size_id;
                    obj.menu_id=size.menu_id;
                    obj.price=size.price;
                    obj.size_dining_price=size.size_dining_price;
                    obj.vendor_id=size.vendor_id;
                    obj.addons=[]
                
                   size.menu_addon.forEach(item =>{
                       if(item.addon.is_checked){
                           obj.addons.push(item);
                       }
                   })
                }
           });
           return obj
       },
       isSelect(data){
          return this.posCartData.some(item => item.selectdItems.id === data.items.id);
       },
       isSelectSize(data){
           console.log(data.items.sizes.length)
       },
       postItemReload(){
           this.posCartData = this.usePosStore.posItems;
       },

       handleSingMenu(category){
           this.menuList = category;
           const element = document.getElementById('cat'+category.id);
          if (element) {
             element.scrollIntoView({ behavior: 'smooth' });               
           } 
       },
       selectProduct(data,index){
          console.log(data);
          this.addToCartBox =true;
          const sizes = [];
          data.menu.menu_size.forEach(item=>{       
             sizes.push({
                 id:item.id,
                 display_discount_price:item.display_discount_price,     
                 display_price:item.display_price,
                 group_menu_addon:item.group_menu_addon,
                 item_size:item.item_size,
                 item_size_id:item.item_size_id, 
                 menu_addon:item.menu_addon,
                 menu_id:item.menu_id,
                 price:item.price,
                 size_dining_price:item.size_dining_price,
                 vendor_id:item.vendor_id,
                 selected:false
             });
          });
              const adon_group=[];
              data.menu.group_menu_addon.forEach(group=>{
               adon_group.push({
                     addon_cat_id:group.addon_category_id,
                     adon_group:group,
                     addon:[]
                   });
              });
               adon_group.forEach(cat => {
                  data.menu.menu_addon.forEach(product => {
                    if(cat.addon_cat_id === product.addon_category_id){  
                       product.addon.is_checked = false;
                       cat.addon.push(product);
                       
                    }
                 })
              });
          this.singItem = {
                 cat_name:data.single_menu_item_category[0].item_category.name,
                 id:data.menu.id,
                 name : data.menu.name,
                 price:data.menu.price,
                 dining_price:data.menu.dining_price,
                 img:data.menu.image,
                 description:data.menu.description,
                 qty:1,
                 addon:adon_group,
                 sizes:sizes,
                 
          }
       },
       isSelected(product) {
         return this.selectedProduct.some((item) => item.id === product.menu.id);   
      },
      itemRemove(product) {
       
       const index = this.selectedProduct.findIndex( 
           (item) => item.id === product.menu.id
       );
       if( this.selectedProduct.splice(index, 1)){
           alert("yes")
       }else{
           alert("no")
       }
   },
   }
 
}


</script>



<style scoped>
.category-bar {
 position: sticky;
 top: -5px;
 z-index: 100;
 background-color: #ffffff;  
 padding: 0 !important;
 box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
 margin: 0;
 border-radius: 0 !important;
}
.menue-card-left{
   display: flex;
   /* justify-content: center; */
   align-items: center;
   padding:  20px;

}
.menue-card-left img{
   width: 75px;
   height: 75px;
   border-radius: 100%;
}
.menue-card-right{
   display: flex;
   justify-content: center;
   align-items: center;
}
.category-name{
   padding: 20px ;
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
   background-color: #F6F7F9;
}
.product-card{
   margin-bottom: 10px;
}
.product-card:hover{
   background-color: #d7171c;
   color: #fff;
}
.v-card-title{
   font-size:16px !important;   
} 
.custom-text-field  {
  color: #d7171c !important; /* Replace with your desired custom color */
  width:100%
}

.posCardComp{
   position: sticky;
   top: -10px;
}
.search-box {
 position: relative;
 display: inline-block;
 width: 100%;
}

.search-box input[type="text"] {
 height: 40px;
 padding: 15px;
 border: 1px solid #ccc;
 border-radius: 4px;
 width: 100%;


}

.search-icon {
 position: absolute;
 top: 0;
 right: 0;
 width: 30px;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 pointer-events: none;
}

.search-icon i {
 font-size: 20px;
}
.sm-footer{
   display: none;
}
.spceing-box{
   display: none;
}
.smScreenForm{
    display:none;
}
.outerFooterinf a{
    position: absolute;
    bottom: 0 !important;
}
@media(max-width:960px){
   .lgScreenForm{
    display: none !important;
   }
   .smScreenForm{
    display:  block !important;
   }
   .posCardComp{
    position: relative  !important;
   }
   .sm-footer{
      display: block !important;
   }
   /* .spceing-box{
       display: block;
       height: 100px;
   } */
}
.marginTop{
    margin-top:60px !important
}
.MainCard{
    padding: 30px 50px;
}

@media(max-width:599px){
    /* .menue-card-left{
        display: grid !important;
        text-align: center;   
    } */
    .menue-card-left{
        padding: 0 !important;
    }
    .item-img{
        display: flex;
        justify-content: center;
    }
    
    .menue-card-right .v-btn{
        position:absolute;
        bottom: 5px;
    }
    .product-card{
        padding: 20px;
    }
    .menue-card-left .product-name,.menue-card-left .product-description{
        width: 189px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .menue-card-left img{
        width: 70px !important;
        height: 70px !important;
        border-radius: 6% !important;
    }
    .menue-card-right .v-btn{
    bottom: 0 !important;
    }
    .smScreenForm{
        border-radius: 20px !important;
    }

    .error-border .v-input__control {
  border-color: red !important;
}
}

@media(min-width:959.99px){
    .web-footer{
        position: absolute !important;
        bottom: 0 !important;
        right: 0;
    }

}
</style>