<template>
    <v-card style="margin-top: 50px;display: flex;justify-content: center;align-items: center;" height="200">
        <div>
            <v-card-title>Please Wait...</v-card-title>
      
      <!-- Card Content -->
      <v-card-text>
        <!-- Loader will be shown when isLoading is true -->
        <v-progress-linear
          indeterminate
          color="#d7171c"
        ></v-progress-linear>
        
        
      </v-card-text>
        </div>
     
    </v-card>
  </template>
  
  <script>
  export default {
    props:['isLoading'],
    data() {
      return {
        
      };
    },
    mounted() {
   
    },
  };
  </script>
  