
<template>
  <page-loader v-if="pageLoader"/>
  <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
  <div style="margin-top: 50px;" @click="drawer=false">
    <v-container>
    <section class="section">
      <div class="section-header">
      </div>
  
      <div class="section-body">
      
       
          <v-card >
              <div class="crad-header" :style="dynmicBgColor">
                <img :src="driverInfo.Vedor_logo" alt="" srcset="">
                <div class="vendor-info">
                  <div class="name-with-phone">
                    <h3>{{ driverInfo.Vedor_name }}</h3>
                    <a :href="'tel:' + driverInfo.Vedor_contact"><v-icon>mdi-phone</v-icon> {{ driverInfo.Vedor_contact }}</a>
                  </div>
                  <small>Order {{' '+ driverInfo.order_id }}</small>
                  <small>Estimate Time {{' '+ estimatedTime }}</small>
                </div>
              </div>

              <router-link class="topBackBtn" :style="{'color':useVendorStore.resturant.main_color}" to="/orders"><v-icon>mdi-arrow-left</v-icon> Back</router-link>
            <v-card-text>
              <div  id="map" style="min-height: 550px;"></div>
              <!-- <div id="estimatedTime">{{ estimatedTime }}</div> -->
              <!-- <div style="width: 100%;display:flex;justify-content:center;    align-items:center;">
                 <router-link class="bottomBackBtn" to="/orders"><v-icon>mdi-arrow-left</v-icon> Back</router-link>
              </div> -->
            </v-card-text>
            <div class="footer" :style="dynmicBgColor">
              <div class="driverInfo">
                <div class="left">
                    <img :src="driverInfo.driver_image" alt="" srcset="">
                  </div>
                  <div class="right">
                    <!-- <v-rating color="yellow" v-model="rating" :hover="hoverEnabled" :readonly="readonly" dense half-increments></v-rating> <br>
                      <small>(245 Reviews)</small> -->
                      <h5 style="font-size: 18px;">{{driverInfo.Driver_name  }}</h5>
                      <span>Order No:{{driverInfo.order_id  }}</span> <br>
                    
                </div>
              </div>
              <div class="estimateTime">
                 <a :href="'tel:' + driverInfo.driver_phone">Call</a>
                  <v-btn @click="$router.push('/orders')">View Order</v-btn>
              </div>
            </div>
          </v-card>
       
      </div>
    </section>

  </v-container>
  </div>
  </template>
  
  <script>
    import NavBar from '@/layouts/default/NavBar.vue'
    import PageLoader from '@/components/PageLoader.vue'
    import { posStore } from '@/store/posStore' 
    import { vendorStore } from "@/store/vendor.js";
  export default {
    setup(){
       const usePosStore = posStore(); 
       const useVendorStore = vendorStore();
          return {useVendorStore,usePosStore};
    },
    components:{
        'nav-bar-vue':NavBar,
        'page-loader':PageLoader,
    },
    data() {
      return {
        drawer:false,
        estimatedTime: "",
        map: null,
        fromMarker: null,
        toMarker: null,
        directionsService: null,
        directionsRenderer: null,
        boundsInitialized: false,
        driverLocationRef: null,
        driverLocationListener: null,
        pageLoader:true,
        driverInfo:{},
        rating: 3, 
        hoverEnabled: true, 
      };
    },
    computed:{
       dynmicBgColor(){
        return {
          'backgroundColor':this.useVendorStore.resturant.main_color,
          'color' : this.useVendorStore.resturant.font_color,
        }
       }
    },
    created() {
        if (!User.loggedIn()) {
            this.$router.push('/');
        }
    },
    mounted() {
      this.driverInfo = this.usePosStore.selected_driver;
      this.loadFirebaseScript().then(() => {
        this.initializeFirebase().then(() => {
          this.loadGoogleMapsScript().then(() => {
            this.pageLoader =false;
          });
        });
      });
    },
    beforeUnmount() {
      this.removeFirebaseListeners();
    },
    methods: {
      afterloadData(){
       
      },
      loadFirebaseScript() {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src =
            "https://www.gstatic.com/firebasejs/8.10.0/firebase-app.js";
          script.onload = () => {
            const scriptDatabase = document.createElement("script");
            scriptDatabase.src =
              "https://www.gstatic.com/firebasejs/8.10.0/firebase-database.js";
            scriptDatabase.onload = resolve;
            document.head.appendChild(scriptDatabase);
          };
          document.head.appendChild(script);
        });
      },
      async initializeFirebase() {
        const firebaseConfig = {
            apiKey: "AIzaSyCr8iiALRjdxKxk8CGdM10C8L4Q8yS7Ed4",
            authDomain: "mealup-af29b.firebaseapp.com",
            databaseURL: "https://mealup-af29b-default-rtdb.asia-southeast1.firebasedatabase.app",
            projectId: "mealup-af29b",
            storageBucket: "mealup-af29b.appspot.com",
            messagingSenderId: "502253922422",
            appId: "1:502253922422:web:80f34da78b18bce5701757",
            measurementId: "G-77FRR1X6L3"
        };
        firebase.initializeApp(firebaseConfig);
  
        const db = firebase.database();
        this.driverLocationRef = firebase.database().ref("/drivers/"+this.$route.params.driverId);  // driver_id
        if(this.driverLocationRef){
          this.pageLoader =false;
        }
        this.driverLocationListener = firebase
          .database()
          .ref(this.driverLocationRef)
          .on("value", (snapshot) => {
            const driverLocation = snapshot.val();
  
            if (driverLocation) {
              const driverLat = parseFloat(driverLocation.driverLat);
              const driverLng = parseFloat(driverLocation.driverLang);
              const toLocation = new google.maps.LatLng(driverLat, driverLng);
  
              this.toMarker.setPosition(toLocation);
  
              if (!this.boundsInitialized) {
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(this.fromMarker.getPosition());
                bounds.extend(toLocation);
                this.map.fitBounds(bounds);
                this.boundsInitialized = true;
              }
  
              this.calculateRoute(this.fromMarker.getPosition(), toLocation);
            }
          });
      },
      loadGoogleMapsScript() {
        console.log(this.useVendorStore.resturant.map_api_key);
        return new Promise((resolve) => { 
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${this.useVendorStore.resturant.map_api_key}&callback=initMap`;
          script.async = true;
          script.defer = true;
          window.initMap = () => this.initMap(); // Bind initMap method to window
          script.onload = resolve;
          document.head.appendChild(script);
        });
      },
      initMap() {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 0, lng: 0 },
          zoom: 13,
          mapTypeId: "roadmap",
          mapTypeControl: false, 
          streetViewControl: false, 
          fullscreenControl: false, 
                                
          
        });
  
        this.map.setOptions({
            styles: [
              {
               featureType: 'poi',
               elementType: 'labels',
               stylers: [{ visibility: 'off' }]
          },
                                    {
                                        featureType: 'poi',
                                        elementType: 'geometry',
                                        stylers: [{ visibility: 'off' }]
                                    }
                                ],
    });
        this.fromMarker = new google.maps.Marker({
          position: new google.maps.LatLng(this.$route.params.lat, this.$route.params.lang),   // verndor address
          // position: new google.maps.LatLng(33.64733, 73.08864),   // verndor address
          icon: "https://v4.ozfoodz.com.au/images/user-map.png",
        });
  
        this.toMarker = new google.maps.Marker({
          position: new google.maps.LatLng(0, 0),
          icon: "https://v4.ozfoodz.com.au/images/bike-map.png",
        });
  
        this.fromMarker.setMap(this.map);
        this.toMarker.setMap(this.map);
  
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
          map: this.map,
          suppressMarkers: true,
          polylineOptions: {
        strokeColor: '#FF0000', // Set the direction line color to red
        strokeWeight: 9, // Set the direction line weight to 5 (adjust as needed)
        strokeOpacity: 0.5, // Set the direction line opacity to 0.5 (adjust as needed)
         },
        });
  
        window.addEventListener("resize", () => {
            const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.fromMarker.getPosition());
        bounds.extend(this.toMarker.getPosition());
        this.map.fitBounds(bounds);
        });
      },
      calculateRoute(origin, destination) {
        this.directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK") {
              this.directionsRenderer.setDirections(response);
  
              const route = response.routes[0];
              if (route.legs.length > 0) {
                const estimatedTime = route.legs[0].duration.text;
                this.estimatedTime =  estimatedTime;
              }
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      },
      removeFirebaseListeners() {
        if (this.driverLocationListener) {
          firebase
            .database()
            .ref(this.driverLocationRef)
            .off("value", this.driverLocationListener);
        }
      },
    },
  };
  </script>

  <style scoped>
    .topBackBtn{
      position: absolute;
       z-index: 9;
      top: 15%;
      background-color: #ffffff3b;
     
    }
    .bottomBackBtn{
      background-color: #d7171c;
      color:#fff;
      padding: 5px 50px;
      margin-top: 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 5px;
    }
    .footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .driverInfo {
      padding:10px 30px;
      display: flex;
      align-items: center;
    }
    .v-rating{
      height: 30px !important;
    }
    .driverInfo img{
      width:70px;
      height:70px;
      border-radius: 100%;
      /* position: absolute;
      bottom: 10%;
      z-index: 9; */

    }
    .driverInfo .right{
      padding-left: 20px;
    }
    .driverInfo .left {
        display: grid;
      
    }

     .estimateTime a{
      /* background-color: #fff; */
      text-align: center;
      text-decoration: none;
      color: #000 !important;
      margin-top: 10px;
      border-radius: 5px;
      padding: 5PX 10PX;
      background-color:  #fff;
      border-radius: 20px;
      width: 110px;
      /* height: 25px; */
    }
    .estimateTime .v-btn{
      text-transform: capitalize;
      border-radius: 20px;
      background-color: #000;
      color: #fff;
      margin-top: 10px;
      width: 110px;
      margin-left: 5px;
    }
    .estimateTime{
      padding-right:20px ;
      display: flex;
      align-items: center;
    }
@media(max-width:599px){
.topBackBtn{
  top: 10%;
  
}
  .name-with-phone h3{
    font-size: 13px !important;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name-with-phone a{
    font-size: 10px !important;
  }
  .v-card .crad-header img{
    width: 65px !important;
    height: 65px !important;
  }
  .estimateTime{
    padding: 0 ;
    padding-bottom: 5px !important;
    display: grid !important;
  }
  .estimateTime .v-btn, .estimateTime a{
    height: 25px;
    padding: 0px;
    margin-top: 5px;
    margin-left: 0;
  }
  .driverInfo img{
    width: 50px;
    height: 50px;
  }
  .right span{
    font-size:12px;
    margin-top:-10px;
  }
  .estimateTime h4{
    font-size: 16px !important;
  }
  .estimateTime h3{
    font-size: 14px !important;
    position: absolute;
    right: 10px;
    /* top: 62%; */
    bottom: 20%;
  }
  .driverInfo .right h5{
    font-size: 13px !important;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .estimateTime p{
    font-size: 12px !important;
  }
  .driverInfo .right a {
    position: absolute;
    right: 7px;
    top: 0
}
.driverInfo{
  padding: 5px;
}
.driverInfo .right{
  padding-left: 5px;

}
}
.v-card-text,.v-card{
  padding: 0 !important;
}
.v-card .crad-header{
  display: flex;
  align-items: center;
}
.v-card .crad-header .vendor-info{
  padding-left: 10PX;
  display: grid;
}
.name-with-phone{
  display: flex;
  align-items: center;
}
.name-with-phone a{
  text-decoration: none;
  color: #fff;
  margin-left: 15px;
  font-size: 14px;
}
.v-card .crad-header img{
   width: 100px;
   height: 100px;
} 
</style>
  