<template>
    <div>
        <v-card>
        <v-card-title @click="toggleCard" :style="dynmicFontColor" class="card-header" >
          <v-row>
            <v-col cols="10" sm="11">
                <v-icon>mdi-earth</v-icon>
                <span>Login or Signup</span>
            </v-col>
            <v-col cols="2" sm="1">
                <v-icon >mdi-chevron-down</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
      <v-expand-transition>
        <v-card-text v-if="isCardExpanded">
          <div class="expanded-button">
            <button class="login"  @click="changeAreaBtn('login')"
            @mouseover="hoverButton = 'login'"
            @mouseleave="hoverButton = null"
             :style="{
                'background-color':isActive==='login' || hoverButton ==='login' ? dynmicBgColor.backgroundColor: '',
                'color':isActive==='login'|| hoverButton ==='login' ? dynmicBgColor.color : dynmicFontColor.color,
                'border':'1px solid '+dynmicBgColor.backgroundColor
             }"
             
            >Login</button>
            <button class="siginup" @click="changeAreaBtn('signup')"
            @mouseover="hoverButton = 'signup'"
            @mouseleave="hoverButton = null"
             :style="{
                'background-color':isActive==='signup' || hoverButton === 'signup' ? dynmicBgColor.backgroundColor: '',
                'color':isActive==='signup' || hoverButton === 'signup' ? dynmicBgColor.color : dynmicFontColor.color,
                'border':'1px solid '+dynmicBgColor.backgroundColor
             }"
            >Signup</button>
          </div>
          <div class="form-field" v-if="isActive==='login'">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model="email"
                    label="Email"
                    variant="outlined"
                    :rules="emailRules"
                    >
                    <template v-slot:prepend-inner>
                        <v-icon :style="dynmicFontColor">mdi-email</v-icon>
                    </template>
                </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                        v-model="password"
                        variant="outlined"
                        label="Password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="passwordRule"
                        >
                        <template v-slot:append-inner>
                            <v-icon :style="dynmicFontColor" @click="showPassword = !showPassword">
                            {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon :style="dynmicFontColor">
                            mdi-lock
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>
                </v-row>
                <v-btn :style="dynmicBgColor" @click="userLogin" :disabled="btnloading">
                    <template v-if="btnloading">
                        <span>Loading...</span>
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="white"
                          class="ml-2"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        Login
                      </template>
                </v-btn>
          </div>
          <div class="form-field" v-if="isActive==='signup'">
             <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model="signup_form.name"
                    label="Name"
                    variant="outlined"
                    >
                    <template v-slot:prepend-inner>
                        <v-icon :style="dynmicFontColor">mdi-account</v-icon>
                    </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model="signup_form.phone"
                    label="Phone Number"
                    variant="outlined"
                    >
                    <template v-slot:prepend-inner>
                        <v-icon :style="dynmicFontColor">mdi-phone</v-icon>
                    </template>
                   </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model="signup_form.email_id"
                    label="Email"
                    variant="outlined"
                    :rules="emailRules"
                    >
                    <template v-slot:prepend-inner>
                        <v-icon :style="dynmicFontColor">mdi-email</v-icon>
                    </template>
                </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="signup_form.password"
                        variant="outlined"
                        label="Password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="passwordRule"
                        >
                        <template v-slot:append-inner>
                            <v-icon :style="dynmicFontColor" @click="showPassword = !showPassword">
                            {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon :style="dynmicFontColor">
                            mdi-lock
                            </v-icon>
                        </template>  
                    </v-text-field>
                </v-col>
               
             </v-row>
             <v-btn :style="dynmicBgColor" @click="userSignup" :disabled="btnloading">
                <template v-if="btnloading">
                        <span>Loading...</span>
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="white"
                          class="ml-2"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        Signup
                      </template>
            </v-btn>
          </div>
        </v-card-text>
      </v-expand-transition>
    </v-card>
    </div>
</template>
<script>
import { vendorStore } from '@/store/vendor.js' 
import { useUserStore } from '@/store/user.js' 
export default{
    setup() {
         const useVendorStore = vendorStore();   
         const userStore = useUserStore();   
          return { useVendorStore,userStore};
    },
 
    data(){
        return{
            isCardExpanded: true,
            isActive:"login",
            // email:'user@gmail.com',
            // password:'Ahmed33!!',
            email: null,
            password: null,
            showPassword: false,

            emailRules: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                ],
            passwordRule: [
                (v) => !!v || 'Password is required',
                (v) => v && v.length >= 6 || 'Password must be at least 6 characters',
            ],
            signup_form:{
                name:null,
                email_id:null,
                phone:null,
                password:null,

                
            },
            btnloading:false,
            hoverButton:null

        }
    },
    computed: {
        dynmicFontColor() {
            return {
                'color': this.useVendorStore.resturant.main_color
            };
        },
        dynmicBgColor(){
            return {
                'color': this.useVendorStore.resturant.font_color,
                'backgroundColor': this.useVendorStore.resturant.main_color
            };
        }
    },
   
    methods:{
        toggleCard(){
            this.isCardExpanded = !this.isCardExpanded;
        },
        changeAreaBtn(status){
            this.isActive = status;
        },
        userLogin(){
            this.btnloading=true;
            if (!this.email || !this.password) {
                Toast.fire({
                    icon: 'warning',
                    title: 'Email and Password are required',
                })
                this.btnloading=false;
            }else{

                const obj ={
                    email_id:this.email,
                    password:this.password,
                    provider : "LOCAL",
                    vendor_id:this.useVendorStore.vender_id
                }
                this.$axios
                    .post(this.useVendorStore.base_url+'user_login_vuejs',obj)
                    .then((res) => {
                        this.btnloading=false;
                      if(res.data.success){
                        this.userStore.loginUser(res.data.data,res.data.paymen_setting[0])
                        console.log(res.data);
                        Notification.loginSuccess();
                        this.$router.push('/dashboard');
                      }else{
                        Notification.loginError();
                      }
                    }).catch((error) =>{
                        this.btnloading=false;
                    })
            }
        },
        userSignup(){
            this.btnloading=true;
            const obj ={
                name:this.signup_form.name,
                email_id:this.signup_form.email_id,
                phone:this.signup_form.phone,
                password:this.signup_form.password,
                vendor_id:this.useVendorStore.vender_id
            }
            this.$axios
                .post(this.useVendorStore.base_url+'user_register_vuejs',obj)
                .then((res) => {
                    console.log(res.data.data);
                    this.btnloading=false;
                    if(res.data.success){
                        this.userStore.loginUser(res.data.data,res.data.paymen_setting[0])
                        this.$router.push('/dashboard');
                        Notification.loginSuccess();
                    }else{
                        Notification.existEmail(res.data.data.email_id)
                    }
                    
                }).catch((error)=>{})
           
        }
    }

}
</script>
<style scoped>
.card-header{
    padding:20px 10px;
    cursor:pointer;
}
.expanded-button{
    display:flex;
    justify-content:center;
    width:100%;
}
.expanded-button button{
    width:100%;
    padding:5px;
    border:1px solid #5A616A;
    color:#5A616A;
}
.expanded-button .login{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.expanded-button .siginup{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.active{
    background-color:#5A616A;
    color:#fff !important;
}
.form-field{
    padding:30px 0;
}

</style>