<template>
</template>
<script>
import { posStore } from '@/store/qrCodePosStore'  
export default{
    data(){
        return{
           
        }
    },
    setup() {
        const usePosStore = posStore();    
         return { usePosStore};
   },
    mounted(){
         this.afterLoad();
    },
    methods:{
        afterLoad(){
            this.usePosStore.fleshOrders();
            this.$router.push({
                name:'qrCode',
                params: {table_id: this.$route.params.tb_id}
            });
        }
    }
}
</script>