<template>
    <page-loader v-if="pageLoader"/>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div style="margin-top: 50px;" @click="drawer=false">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-card class="mt-5">
               
                <v-card-text>
                  <div v-html="contents"></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div class="spceing-box"></div>
        </v-container>
        <web-footer/>
        <div class="sm-footer">
             <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
        </div>
    </div>
  </template>
  
  <script>
  import PageLoader from '@/components/PageLoader.vue'
  import NavBar from '@/layouts/default/NavBar.vue'
  import smFooterComponent from '@/components/smFooterComponent.vue'
  import webFooterComponent from '@/components/webFooterComponent.vue'
  import { vendorStore } from '@/store/vendor.js' 
  export default {
    components:{
        'page-loader':PageLoader,
        'small-screen-footer':smFooterComponent,
        'nav-bar-vue':NavBar,
        'web-footer':webFooterComponent,
    },
    setup() { 
         const useVendorStore = vendorStore() 
          return {useVendorStore};
    },
    data() {
      return {
        contents: "",
        pageLoader:true,
        drawer:false,
      };
    },
    mounted() {
      this.fetchPrivacyPolicy();
      VendorHelper.updatePageTitle(this.useVendorStore.resturant.name +'- Ozpos');
    },
    methods: {
      fetchPrivacyPolicy() {
        fetch(this.useVendorStore.base_url+'VuestaticPages/'+this.useVendorStore.vender_id)
          .then(response => response.json())
          .then(data => {
            this.contents = data.staticpages.returns_refunds;
            this.pageLoader=false;
          })
          .catch(error => {
            console.error('Error fetching privacy policy:', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
   .v-container{
    min-height: 600px;
  }
  @media(max-width:959.99px){
    .spceing-box{
        height:60px;
        display: block !important;
    }
}
  </style>
  