// firebaseInit.js
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCr8iiALRjdxKxk8CGdM10C8L4Q8yS7Ed4",
    authDomain: "mealup-af29b.firebaseapp.com",
    databaseURL: "https://mealup-af29b-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "mealup-af29b",
    storageBucket: "mealup-af29b.appspot.com",
    messagingSenderId: "502253922422",
    appId: "1:502253922422:web:80f34da78b18bce5701757",
            measurementId: "G-77FRR1X6L3"
};

// Check if Firebase app is not already initialized before initializing
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
