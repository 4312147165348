<template>

  <page-loader v-if="pageLoader" />
  <nav-bar-vue
    :drawer="drawer"
    @close-drawer="drawer = !drawer"
    :vendor="useVendorStore.resturant"
    v-if="load_nav_component"
  />
  <hero-section
    :todayTiming="todayTiming"
    :currentTime="currentTime"
    :openTime="openTime"
    :currentDay="currentDay"
    :resturant="vendorData"
    :closeTime="closeTime"
    :workingDays="workingDays"
    @click="drawer = false"
    :checkVendor="checkVendor"
    :isOpen="isOpen"
  />
  <div @click="bodyClick">
    <v-row class="category-bar" :class="{'postionSticky':timePickerTrigger == 'isClose'}">
      <div class="sm-screen-searchBar" :style="themColorWithFont">
        <div class="search-box">
          <v-icon
            class="closeSearchIcon"
            :style="themColor"
            @click.stop="searchTerm = ''"
            >mdi-close</v-icon
          >
          <input
            type="text"
            :class="{ 'search-border': !searchExtend }"
            :placeholder="searchExtend ? 'Search' : ''"
            v-model="searchTerm"
          />
          <span class="search-icon">
            <v-icon class="custom-icon" :style="themColor">mdi-magnify</v-icon>
          </span>
        </div>
        <!-- menu   :class="{ activeColor: i === activeCat }"-->
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-app-bar-nav-icon
              v-bind="props"
              class="toggle-icon"
            ></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in allCategory" :key="i">
              <v-list-item-title
                v-if="item.status == 1"
                :style="{
                  color:
                    i === activeCat
                      ? useVendorStore.resturant.main_color
                      : '#000',
                }"
                @click="handleSingMenu(item, i)"
                style="cursor: pointer"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!--end menu -->
      </div>
      <v-col cols="12" md="9">
        <div>
          <category-scroller
            :activeCat="activeCat"
            :allCategory="allCategory"
            @category-menu-selected="handleSingMenu"
            :timePickerTrigger="timePickerTrigger"
          />
        </div>
      </v-col>
      <v-col cols="3" style="" class="lg-screen-searchBar">
        <div
          class="search-box lg-screen-searchBar"
          :class="{ extendSearchBox: searchExtend }"
          @click.stop="searchExtend = true"
        >
          <input type="text" placeholder="Search" v-model="searchTerm" />
          <span class="search-icon">
            <v-icon class="custom-icon" :style="themColor">mdi-magnify</v-icon>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <div class="smScreenItems" id="cat0">
            <h3
              v-if="allCategory.length > 0"
              class="category-name"
              :style="themColorWithFont"
            >
              {{ allCategory[activeCat].name }}
            </h3>
            <!-- :id="n === 0 ? 'cat' + n : null" -->
            <div  v-if="!searchLoad">
            <v-card
              v-for="(menu, n) in mbfilterSearch"
              :key="n"
              :loading="productCardLoad"
              @click="selectProduct(menu, n)"
              class="product-card"
             
              :style="{
                'background-color':
                  isHoverIndex === menu.menu.name + n
                    ? useVendorStore.resturant.main_color
                    : '#fff',
                color:
                  isHoverIndex === menu.menu.name + n
                    ? useVendorStore.resturant.font_color
                    : '#000',
              }"
              @mouseover="isHoverIndex = menu.menu.name + n"
              @mouseleave="isHoverIndex = null"
            >
              <v-row>
                <v-col cols="10" sm="10" class="menue-card-left">
                  <img :src="menu.menu.image" alt="" srcset="" />
                  <div>
                    <v-card-title class="product-name">{{
                      menu.menu.name
                    }}</v-card-title>
                    <v-card-text>
                      <p class="products-description">
                        {{ menu.menu.description }}
                      </p>
                      <!-- <p v-if="menu.menu.price">Price: {{ menu.menu.price }}</p> -->
                      <p v-if="menu.menu.price">Price: {{formattedPrice(menu.menu.price)}}</p>
                      <span v-else style="color: red; font-weight: 600"
                        >Custimizable</span
                      >
                    </v-card-text>
                  </div>
                </v-col>
                <v-col cols="2" sm="2" class="menue-card-right">
                  <v-btn>Add</v-btn>
                </v-col>
              </v-row>
            </v-card>
            </div>
            <section-loader :isLoading="searchLoad" v-else />
          </div>
          <div v-for="(category, index) in filtersearch" class="lgScreenItems" :key="index">
            <h3
              :id="'cat' + category.id"
              class="category-name"
              :style="themColorWithFont"
            >
              {{ category.name }}
            </h3>
            <v-card
              :disabled="addToCartBox"
              v-for="(menu, n) in category.single_menu"
              :loading="productCardLoad"
              @click="selectProduct(menu, n)"
              class="product-card"
              :key="n"
              :style="{
                'background-color':
                  isHoverIndex === menu.menu.name + n
                    ? useVendorStore.resturant.main_color
                    : '#fff',
                color:
                  isHoverIndex === menu.menu.name + n
                    ? useVendorStore.resturant.font_color
                    : '#000',
              }"
              @mouseover="isHoverIndex = menu.menu.name + n"
              @mouseleave="isHoverIndex = null"
            >
              <v-row>
                <v-col cols="10" sm="10" class="menue-card-left">
                  <img :src="menu.menu.image" alt="" srcset="" />
                  <div>
                    <v-card-title class="product-name">{{
                      menu.menu.name
                    }}</v-card-title>
                    <v-card-text>
                      <p class="products-description">
                        {{ menu.menu.description }}
                      </p>
                      <p v-if="menu.menu.price">Price: {{ formattedPrice(menu.menu.price) }}</p>
                      <span v-else style="color: red; font-weight: 600"
                        >Customizable</span
                      >
                    </v-card-text>
                  </div>
                </v-col>
                <v-col cols="2" sm="2" class="menue-card-right">
                  <v-btn>Add</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <div class="posCardComp" v-if="isloadMapApi">
            <pos-cart-component
              :posCartData="posCartData"
              :vendorData="vendorData"
              :checkVendor="checkVendor"
              :reload="deliveryCondition"
              ref="posComponent"
              :openTime="openTime"
              :closeTime="closeTime"
              :themColorWithFont="themColorWithFont"
              :themColor="themColor"
              :alertBoxColor="useVendorStore.resturant.main_color"
              :key="componentKey"
              :pageLoader="pageLoader"
              :currentDay="currentDay"
              @trigger-times-picker="handleTriggerTimesPicker"
              :weeklyWorkingDays="workingDays"
              :timePickerTrigger="timePickerTrigger"
              :formattedPrice="formattedPrice"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="spceing-box"></div>
  </div>
  <v-dialog v-model="addToCartBox" max-width="800px">
    <selected-product-dilog
      :singItem="singItem"
      :themColorWithFont="themColorWithFont"
      :themColor="themColor"
      :vendorResturent="useVendorStore.resturant"
      @close="addToCartBox = false"
      @add-to-cart-action="handleCartData"
      :modalButtonLoader="modalButtonLoader"
      :formattedPrice="formattedPrice"
    />
  </v-dialog>
  <web-footer />
  <div class="sm-footer">
    <small-screen-footer
      @open-pos-dilog-box="posDilogBox = true"
      :totalItems="posCartData.length"
    />
    <v-dialog
      v-model="posDilogBox"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          :color="useVendorStore.resturant.main_color"
          height="40"
        >
          <v-btn icon dark @click="handlePosCartClose">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items style="margin: 10px 20px; color: #fff">
            <v-icon>mdi-cart</v-icon>
            Cart
          </v-toolbar-items>
        </v-toolbar>
        <div class="posCardComp" style="margin: 8px 10px" v-if="isloadMapApi">
          <pos-cart-component
            :posCartData="posCartData"
            :vendorData="vendorData"
            :checkVendor="checkVendor"
            :reload="deliveryCondition"
            ref="posComponent"
            :openTime="openTime"
            :closeTime="closeTime"
            :weeklyWorkingDays="workingDays"
            :themColorWithFont="themColorWithFont"
            :themColor="themColor"
            :alertBoxColor="useVendorStore.resturant.main_color"
            :pageLoader="pageLoader"
            :currentDay="currentDay"
            :timePickerTrigger="timePickerTrigger"
            @trigger-times-picker="handleTriggerTimesPicker"
            :formattedPrice="formattedPrice"
          />
        </div>
      </v-card>
      <!-- Dialog content -->
      <!-- ... -->
    </v-dialog>
     <!-- snack bar -->
   <v-snackbar
      v-model="snackbarOpen"
       multi-line
      :timeout="300000"
    > <p style="font-size:18px">{{snackbarTxt}}</p>
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbarOpen = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
     <!-- snack bar -->
  </div>
</template>
<script>
import NavBar from "@/layouts/default/NavBar.vue";
import CategoryComponent from "@/components/CategoryCOmponent.vue";
import PosCartComponent from "@/components/POSCartComponent.vue";
import selectedProductDilog from "@/components/SelectedMenueProductModal.vue";
import heroSection from "@/layouts/default/HeroSection.vue";
import { vendorStore } from "@/store/vendor.js";
import { posStore } from "@/store/posStore";
import PageLoader from "@/components/PageLoader.vue";
import smFooterComponent from "@/components/smFooterComponent.vue";
import webFooterComponent from "@/components/webFooterComponent.vue";
import sectionLoader from "@/components/sectionLoader.vue";


export default {
  components: {
    "page-loader": PageLoader,
    "category-scroller": CategoryComponent,
    "selected-product-dilog": selectedProductDilog,
    "pos-cart-component": PosCartComponent,
    "hero-section": heroSection,
    "nav-bar-vue": NavBar,
    "small-screen-footer": smFooterComponent,
    "web-footer": webFooterComponent,
    "section-loader": sectionLoader,
   
  },
  setup() {
    const useVendorStore = vendorStore();
    const usePosStore = posStore();
    return { useVendorStore, usePosStore };
  },
  data() {
    return {
      drawer: false,
      menuList: [],
      activeCat: 0, 
      productCardLoad: false,
      selectedProduct: [],
      allCategory: [],
      addToCartBox: false,
      singItem: [],
      posCartData: [],
      vendorData: {},
      currentDay: "",
      currentTime: "",
      openTime: "",
      closeTime: "",
      workingDays: [],
      todayTiming: {},
      searchTerm: "",
      pageLoader: true,
      posDilogBox: false,
      searchExtend: false,
      mapApiLoad: false,
      searchLoad: false,
      addonIndx: 0,
      isHoverIndex: null,
      modalButtonLoader: false,
      latitude: null,
      longitude: null,
      addressText: null,
      componentKey: 0,
      timePickerTrigger:'isClose',
      isloadMapApi:true,
      isOpen:null,
      snackbarOpen:false,
      snackbarTxt:null,
      load_nav_component:false,
    };
  },
  watch: {
    isOpen(newValue, oldValue){

      if (oldValue === null) {
        this.snackbarOpen = false;
        return;
      }
       if (newValue) { 
        // this.snackbarOpen = true;
        this.snackbarTxt ="Vendor is Now Online";
        console.log('Vendor is open');
       } else {
        // this.snackbarOpen = true;
        this.snackbarTxt ="Vendor is Now Offline";
       }
    }
  },
  created() {
    this.loadPagesStatusApi();
    this.vendorLoad();
      
    this.useVendorStore.vendorType(this.$route.path);
    this.workingDays = this.useVendorStore.workingDays;
  },
  mounted() {
    this.postItemReload(); 
     setInterval(() => {
          this.checkVendorCurrent();
      }, 2000);  
  },
  computed: {
    // isOpen() {
    //   return VendorHelper.isOpen(
    //     this.currentTime,
    //     this.todayTiming
    //   );
    // },
    checkVendor() {
      return VendorHelper.isVenderOnOrOff(
        this.todayTiming, 
        this.isOpen,
        this.vendorData.vendor_status
      );
      return true;
    },
    filtersearch() {
      return this.allCategory
        .map((category) => {
          const filteredMenu = category.single_menu.filter((menu) => {
            return this.matchesSearch(menu.menu.name);
          });
          return { ...category, single_menu: filteredMenu };
        })
        .filter((category) => category.single_menu.length > 0);
    },
    mbfilterSearch() {
      if (this.searchTerm) {
        this.searchLoad = true;
        const element = document.getElementById("cat0");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          this.searchLoad = false;
        }, 1000);
        const filteredMenu = this.allCategory
          .map((category) =>
            category.single_menu.filter((menu) =>
              this.matchesSearch(menu.menu.name)
            )
          )
          .flat();
        return filteredMenu;
      } else {
        this.searchLoad = false;
        return this.menuList.filter((item) => {
          return this.matchesSearch(item.menu.name);
        });
      }
    },
    themColorWithFont() {
      return {
        "background-color": this.useVendorStore.resturant.main_color,
        color: this.useVendorStore.resturant.font_color,
      };
    },
    themColor() {
      return {
        color: this.useVendorStore.resturant.main_color,
      };
    },
  },

  methods: {
    async loadPagesStatusApi(){
      this.$axios
          .get(
            this.useVendorStore.base_url +
              "vendorPageStatusVueJs/" +
              this.useVendorStore.vender_id
          ).then(({ data }) => {
            this.useVendorStore.storePagesStatus(data.status);
            this.load_nav_component= true;
          }) 
    },
    formattedPrice(price){
      return VendorHelper.formatCurrency(price);
    },
    checkVendorCurrent(){
      const dayTime = VendorHelper.setTimeZone(this.vendorData.timezone);
      this.isOpen = VendorHelper.isOpen(
        dayTime[1],
        this.todayTiming
      );
    },
    handleTriggerTimesPicker(type){
      this.timePickerTrigger = type;
    },
    handlePosCartClose() {
      this.$refs.posComponent.loadMainFunction();
      this.posDilogBox = false;
      this.componentKey += 1;
      // posComponent
    },
    isOpenpriceSize(menueProducts) {
      menueProducts.forEach((menu, ind) => {
        menu.single_menu.forEach((singleMenue, i) => {
          if (singleMenue.menu.menu_size.length > 0) {
            singleMenue.menu.menu_size.forEach((item, n) => {
              if (item.item_size.name === "openprice") {
                this.allCategory[ind].single_menu.splice(i, 1);
              }
            });
          }
        });
      });
    },
    closExtendSearch() {
      this.searchExtend = false;
      this.searchTerm = "";
    },
    bodyClick() {
      this.searchExtend = false;
      this.drawer = false;
      // this.searchTerm =null;
    },
    matchesSearch(text) {
      const searchTerm = this.searchTerm.toLowerCase();
      return text.toLowerCase().includes(searchTerm);
    },

    handleCartData(data) {
      console.log("data");
      console.log(data);
      if(this.vendorData.is_tiffin_service === 1){
        this.$refs.posComponent.loadDefualtAddress();
      }
      this.modalButtonLoader = true;
      const sizes = this.getSelectedSizesAddon(data);
      this.posCartData.push({
        selectdItems: data.items,
        selectedItemssizes: data.sizes_addons,
        selectedItemsAdon: data.addons,
        SelectedSizes: sizes,
      });
      this.usePosStore.PosItemStore(this.posCartData);
      this.posCartData = [];
      this.postItemReload();
      setTimeout(() => {
        // this.modalButtonLoader=false;
        this.addToCartBox = false;
        setTimeout(() => {
          this.singItem = {};
        }, 500);
      }, 900);
    },

    getSelectedSizesAddon(data) {
      var obj = {};
      data.items.sizes.forEach((size) => {
        if (size.selected) {
          obj.id = size.id;
          obj.display_discount_price = size.display_discount_price;
          obj.display_price = size.display_price;
          obj.item_size_id = size.item_size_id;
          obj.menu_id = size.menu_id;
          obj.price = size.price;
          obj.size_dining_price = size.size_dining_price;
          obj.vendor_id = size.vendor_id;
          obj.addons = [];

          size.menu_addon.forEach((item) => {
            if (item.addon.is_checked) {
              obj.addons.push(item);
            }
          });
        }
      });
      return obj;
    },
    isSelect(data) {
      return this.posCartData.some(
        (item) => item.selectdItems.id === data.items.id
      );
    },
    isSelectSize(data) {
      console.log(data.items.sizes.length);
    },
    postItemReload() {
      this.posCartData = this.usePosStore.posItems;
    },
    async vendorLoad() {
      this.isloadMapApi = false;
      this.$axios
        .get(
          this.useVendorStore.base_url +
            "single_vendor/" +
            this.useVendorStore.vender_id
        )
        .then(({ data }) => {
          console.log("api data")
          console.log(data.data.vendor)
          this.useVendorStore.vednorStorage(data);
          this.allCategory = data.data.MenuCategory;
          this.isOpenpriceSize(data.data.MenuCategory);
          // this.menuList = data.data.MenuCategory[0].single_menu;
          this.menuList = data.data.MenuCategory.length > 0 ? data.data.MenuCategory[0].single_menu : [];
          this.vendorData = data.data.vendor;
          this.loadMapApi(data.data.vendor.map_api_key).then(()=>{
            this.isloadMapApi = true;
            console.log("Google Maps API loaded successfully");
          });
          this.workingDays = data.data.WorkDays;
          const dayTime = VendorHelper.setTimeZone(data.data.vendor.timezone);
          this.currentDay = dayTime[0];
          this.currentTime = dayTime[1];
          const today = data.data.WorkDays.find(
            (day) => day.day_index === this.currentDay
          );
          this.todayTiming = today;
          this.openTime = today.period_list[0].start_time;
          this.closeTime =
            today.period_list[today.period_list.length - 1].end_time;
          VendorHelper.updatePageTitle(data.data.vendor.name + "- Ozpos");
          setTimeout(() => {
             this.pageLoader = false;
          }, 1000);
          this.checkVendorCurrent();
          
          //   this.activeTopBtn = this.usePosStore.cartData.totalCulculate.delivery_type
          //   alert
        });
    },
    async loadMapApi(apiKey) {
        return new Promise((resolve, reject) => {
          if (typeof google !== "undefined") {
            resolve();
            return;
          }

          var scriptSrc =
            "https://maps.googleapis.com/maps/api/js?key=" +
            apiKey +
            "&libraries=places";
          var script = document.createElement("script");
          script.src = scriptSrc;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            resolve();
          };
          script.onerror = (error) => {
            reject(error);
          };
          document.body.appendChild(script);
        });
      },
    // async loadMapApi(apiKey) {
    //   var scriptSrc =
    //     "https://maps.googleapis.com/maps/api/js?key=" +
    //     apiKey +
    //     "&libraries=places";
    //   var script = document.createElement("script");
    //   script.src = scriptSrc;
    //   script.async = true;
    //   script.defer = true;
    //   document.body.appendChild(script);
    // },
    handleSingMenu(category, index) {
      this.activeCat = index;
      console.log(this.allCategory[this.activeCat].name);
      this.menuList = category.single_menu;
      const element = document.getElementById("cat" + category.id);
      const element2 = document.getElementById("cat0");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        element2.scrollIntoView({ behavior: "smooth" });
      }
    },
    getCategoryByItem(item) {
      return this.allCategory.find((category) =>
        category.single_menu.some((menu) => item.menu.id === menu.menu.id)
      );
    },
  
    selectProduct(data, index) {
      console.log(data);
      var categoryData = this.getCategoryByItem(data);
      console.log(categoryData);
      this.addToCartBox = true;
      this.modalButtonLoader = false;
      data.menu.menu_size.some((item) => item.item_size.name === "openprice");

      // return this.items.some(item => item.name === this.searchName);
      const sizes = [];
      data.menu.menu_size.forEach((item) => {
        if (item.item_size.name === "grams") {
          var isSelect = true;
        } else {
          var isSelect = false;
        }
        sizes.push({
          id: item.id,
          display_discount_price: item.display_discount_price,
          display_price: item.display_price,
          group_menu_addon: item.group_menu_addon,
          item_size: item.item_size,
          item_size_id: item.item_size_id,
          menu_addon: item.menu_addon,
          menu_id: item.menu_id,
          price: item.price,
          size_dining_price: item.size_dining_price,
          vendor_id: item.vendor_id,
          selected: isSelect,
        });
      });
      const adon_group = [];
      data.menu.group_menu_addon.forEach((group) => {
        const exists = adon_group.some(
          (item) => item.addon_cat_id === group.addon_category_id
        );
        if (!exists) {
          adon_group.push({
            addon_cat_id: group.addon_category_id,
            adon_group: group,
            addon: [],
          });
        }
      });
      adon_group.forEach((cat) => {
        data.menu.menu_addon.forEach((product) => {
          if (cat.addon_cat_id === product.addon_category_id) {
            product.addon.is_checked = false;
            product.addon.isSelect = false;
            product.qty = 1;
            console.log(product);
            cat.addon.push(product);
          }
        });
      });
      
      var obj = {
        cat_name: categoryData.name, 
        cat_id: categoryData.id,
        // id: data.menu.id,
        id: data.id,
        name: data.menu.name,
        tiffan_service: categoryData.tiffin_service,
        price: data.menu.price,
        img: data.menu.image,
        description: data.menu.description,
        qty: 1,
        grameSize: "1",
        addon: adon_group,
        sizes: sizes,
      };
      this.singItem = obj;
    },
    isSelected(product) {
      return this.selectedProduct.some((item) => item.id === product.menu.id);
    },
    itemRemove(product) {
      const index = this.selectedProduct.findIndex(
        (item) => item.id === product.menu.id
      );
      if (this.selectedProduct.splice(index, 1)) {
        alert("yes");
      } else {
        alert("no");
      }
    },
    //   get locations
  },
};
</script>

<style scoped>
.postionSticky{
  position: sticky;
  top: 0 !important;
  z-index: 100;
}
.category-bar {
  bottom: 50%;
  background-color: #ffffff;
  /* background-color: #ffffff;   */
  padding: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  margin: 0;
  border-radius: 0 !important;
}

.menue-card-left {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 20px;
}
.menue-card-left img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
}
.menue-card-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-name {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.product-card {
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.product-card:hover {
  /* background-color: #d7171c;
    color: #fff; */
}
.v-card-title {
  font-size: 16px !important;
}
.custom-text-field {
  color: #d7171c !important; /* Replace with your desired custom color */
  width: 100%;
}

.posCardComp {
  position: sticky;
  top: -10px;
}
.search-box {
  position: relative;
  display: inline-block;
  width: 100%;
}

.search-box input[type="text"] {
  height: 40px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 60px;
  width: 100%;
  background-color: #fff;
}

.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.search-icon i {
  font-size: 20px;
}
.sm-footer {
  display: none;
}
.spceing-box {
  display: none !important;
}
.sm-screen-searchBar {
  display: none;
}
.smScreenItems {
  display: none;
}
.activeColor {
  color: #d7171c !important;
}
@media (max-width: 768px) {
  .lgScreenItems {
    display: none;
  }
  .smScreenItems {
    display: block !important;
    padding-top: 20px;
  }
  .PaddingTop {
    padding-top: 20px;
  }
}
@media (max-width: 960px) {
  .closeSearchIcon {
    position: absolute;
    top: 4px;
    color: #d7171c;
    left: 5px;
    cursor: pointer;
  }
  .search-box input[type="text"] {
    padding: 15px 30px !important;
  }
  .extendSearchBox {
    color: #d7171c;
    position: absolute;
    right: 10px;
    width: 50%;
    background-color: #fff;
  }
  .lg-screen-searchBar {
    display: none;
  }
  .extendSearchBox {
    color: #d7171c;
    position: absolute;
    right: 10px;
    width: 93%;
  }

  .sm-screen-searchBar {
    display: flex !important;
    align-items: center;
    width: 100%;
    /* background-color: #d7171c;
      color: #fff !important; */
    padding-left: 10px;
  }
  .sm-screen-searchBar .search-box input {
    height: 30px !important;
  }

  .sm-footer {
    display: block !important;
  }
  /* .spceing-box {
    display: block;
    height: 100px;
  } */
}

@media (max-width: 599px) {
  .menue-card-left {
    padding: 0 !important;
  }
  .item-img {
    display: flex;
    justify-content: center;
  }

  .menue-card-right .v-btn {
    position: absolute;
    bottom: 0;
  }
  .product-card {
    padding: 20px;
  }
  .menue-card-left .product-name,
  .menue-card-left .products-description {
    width: 290px; /* Set the width of the div */
    white-space: nowrap; /* Prevent the text from wrapping to the next line */
    overflow: hidden; /* Hide the overflowing content */
    text-overflow: ellipsis;
  }
  .v-card-text {
    margin-top: -13px !important;
  }
  .menue-card-left img {
    width: 70px;
    height: 70px;
    border-radius: 6%;
  }
}
@media (max-width: 375px) {
  .menue-card-left .product-name,
  .menue-card-left .products-description {
    width: 240px !important;
  }
}
.locationSearch .pac-container {
  z-index: 9999 !important;
}
</style>
